import React from "react";
import { JText, Spacer } from "../../jlib";
import getWording from "utils/wording";
import useConfiguration from "../../../configuration/ConfigurationProvider";

type Props = {};

export const AlsoFollowChildrenSites: React.FC<Props> = ({}) => {
  const { configuration } = useConfiguration();
  return (
    <>
      <JText centered isBold labelKey="auth.messages" titleLevel={2} />
      <Spacer height="mediumLarge" backgroundColor="transparent" />
      <JText
        labelKey="modal.alsoFollowChildren"
        valuesKey={{ wording: getWording(configuration).singular }}
        sizeName="mediumLarge"
        centered
      />
    </>
  );
};
