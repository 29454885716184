import { Animated, View, useWindowDimensions } from "react-native";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useTheme from "theme/ThemeProvider";
import { generatedMapStyle, zoomLevel } from "./MapStyle";
import { Points } from "../../../types/Site";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF as Marker,
  MarkerClusterer,
  Libraries,
  Polygon,
} from "@react-google-maps/api";
import { GoogleAPI } from "google-maps-react";
import { useLocation } from "hooks";
import useSites from "hooks/useSites";
import useConfiguration from "../../../configuration/ConfigurationProvider";
import fnStyles from "./MapStyle";
import { SiteSearchField } from "components/sites/SiteSearchField";
import { Clickable } from "../clickable";
import { Icon } from "../icon";
import { isHorizontalMode } from "components/utils/deviceMode";
import { REACT_APP_API_ROOT } from "constants/Constants";
import { JText } from "../text";
import { ConfirmSavePolygonModal } from "components/modals/ConfirmSavePolygonModal";

type MapProps = {
  points?: Points[];
  google: GoogleAPI;
  height?: number;
  fullHeight?: boolean;
  onMarkerPress?: (e: any) => void;
  zoomLvl?: zoomLevel;
  initialRegion?: {
    id: number | string;
    lat: number;
    lng: number;
  };
  selectedPointId?: string | number;
  scrollEnabled?: boolean;
  centeredOnUser?: boolean;
  mapSearchAndDrawEnabled?: boolean;
  forUserAreaOfInterest?: boolean;
  areaOfInterest?: any;
  updateAreaOfInterest?: any;
  setParentScrollEnabled?: any;
  verticalScrollOffset?: any;
};

export type MapRef = {
  drawPolygon: () => void;
};

const RenderMarker = memo(
  ({
    point,
    selectedPointId,
    onMarkerPress,
    mapRef,
    setHighlightedSiteId,
    clusterer,
  }: any) => {
    const position = {
      lat: point?.lat,
      lng: point?.lng,
    };
    const isSelected = point?.id === selectedPointId;
    const shouldUseCustomMarker = point?.isCustomMarkerSelected;

    return (
      <Marker
        key={`${point?.id}-${isSelected}`}
        position={position}
        onClick={() => {
          mapRef?.current?.panTo(position);
          if (onMarkerPress) onMarkerPress(point?.id);
          setHighlightedSiteId && setHighlightedSiteId(point?.id);
        }}
        icon={
          shouldUseCustomMarker
            ? {
                url: `${REACT_APP_API_ROOT}${point?.customMarkerSelected?.image?.url}`,
                scaledSize: isSelected
                  ? new google.maps.Size(
                      point?.customMarkerSelected?.size + 10 ?? 40,
                      point?.customMarkerSelected?.size + 10 ?? 40
                    )
                  : new google.maps.Size(
                      point?.customMarkerSelected?.size ?? 30,
                      point?.customMarkerSelected?.size ?? 30
                    ),
                anchor: isSelected
                  ? point?.customMarkerSelected?.centeredOnCenter
                    ? point?.customMarkerSelected?.size
                      ? new google.maps.Point(
                          (point?.customMarkerSelected?.size + 10) / 2 ?? 20,
                          (point?.customMarkerSelected?.size + 10) / 2 ?? 20
                        )
                      : new google.maps.Point(20, 20)
                    : point?.customMarkerSelected?.size
                    ? new google.maps.Point(
                        (point?.customMarkerSelected?.size + 10) / 2 ?? 20,
                        point?.customMarkerSelected?.size + 10 ?? 40
                      )
                    : new google.maps.Point(20, 40)
                  : point?.customMarkerSelected?.centeredOnCenter
                  ? point?.customMarkerSelected?.size
                    ? new google.maps.Point(
                        point?.customMarkerSelected?.size / 2 ?? 15,
                        point?.customMarkerSelected?.size / 2 ?? 15
                      )
                    : new google.maps.Point(15, 15)
                  : point?.customMarkerSelected?.size
                  ? new google.maps.Point(
                      point?.customMarkerSelected?.size / 2 ?? 15,
                      point?.customMarkerSelected?.size ?? 30
                    )
                  : new google.maps.Point(15, 30),
                // anchor: isSelected
                //   ? point?.customMarkerSelected.centeredOnCenter
                //     ? new google.maps.Point(20, 20)
                //     : new google.maps.Point(20, 40)
                //   : point?.customMarkerSelected.centeredOnCenter
                //   ? new google.maps.Point(15, 15)
                //   : new google.maps.Point(15, 30),
              }
            : {
                url: isSelected
                  ? require("../../../../assets/maps/map-marker-big.png")
                  : require("../../../../assets/maps/map-marker-min.png"),
                scale: 1,
              }
        }
        zIndex={11}
        title={point?.name ?? ""}
        options={{
          anchorPoint: new google.maps.Point(100, 100),
        }}
        clusterer={clusterer}
      />
    );
  }
);

const libraries: Libraries = ["geometry", "drawing"];

const MapContainer = ({
  points = [],
  height,
  fullHeight = false,
  onMarkerPress,
  selectedPointId,
  scrollEnabled = true,
  zoomLvl,
  centeredOnUser = false,
  mapSearchAndDrawEnabled = false,
  forUserAreaOfInterest = false,
  initialRegion,
  areaOfInterest = null,
  updateAreaOfInterest,
  setParentScrollEnabled,
}: MapProps) => {
  const { theme } = useTheme();
  const mapRef = useRef<google.maps.Map | null>(null);
  const [location, setLocation] = useState<any>(null);
  const [initialMapCenter, setInitialMapCenter] = useState<any>(null);
  const [selectedPosition, setSelectedPosition] = useState<any>(null);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const { width, height: windowHeight } = useWindowDimensions();
  const isHorizontal = isHorizontalMode({ width, height: windowHeight });
  const [shouldFocusOnDestination, setShouldFocusOnDestination] =
    useState(false);
  const [isGeolocationEnabled, setIsGeolocationEnabled] =
    useState<boolean>(false);
  const { locationData } = useLocation();
  const {
    setHighlightedSiteId,
    filters,
    setMapPolygonFilter,
    highlightedSiteId,
  } = useSites();
  const { configuration } = useConfiguration();
  const [path, setPath] = useState<{ lat: any; lng: any }[]>(
    forUserAreaOfInterest ? areaOfInterest : []
  );
  const isDrawingRef = useRef<boolean>(false);
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const [showSaveZoneButton, setShowSaveZoneButton] = useState(false);
  const [isSavePolygonModalVisible, setIsSavePolygonModalVisible] =
    useState(false);

  const isExplanativeOverlayVisible = useMemo(() => {
    return (
      isDrawingMode &&
      isDrawingRef?.current === false &&
      (!path || !path.length || (forUserAreaOfInterest && !showSaveZoneButton))
    );
  }, [isDrawingMode, path, forUserAreaOfInterest, showSaveZoneButton]);

  useEffect(() => {
    if (isDrawingRef?.current === false && !forUserAreaOfInterest) {
      if (path.length > 3) {
        setMapPolygonFilter(
          path.map((p) => {
            return { lat: p.lat(), lng: p.lng() };
          })
        );
      } else {
        setMapPolygonFilter([]);
      }
    }
  }, [forUserAreaOfInterest, path, setMapPolygonFilter]);

  const styles = fnStyles(theme);

  const disableMap = useCallback(() => {
    mapRef?.current?.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false,
    });
  }, [mapRef]);

  const enableMap = useCallback(() => {
    mapRef?.current?.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
    });
  }, [mapRef]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBoabCFYUx-SQW3QtY0WmkRRuzsfhmQtCs",
    libraries: libraries,
  });

  useEffect(() => {
    if (!location) {
      if (initialRegion) {
        setInitialMapCenter(initialRegion);
      } else if (
        locationData &&
        locationData.label !== "" &&
        locationData.lat !== 0
      ) {
        setIsGeolocationEnabled(true);
        setLocation({
          coords: {
            latitude: locationData.lat,
            longitude: locationData.lng,
          },
        });
        if (
          centeredOnUser ||
          configuration.sites.map.defaultCenter === "user"
        ) {
          setInitialMapCenter({
            lat: locationData.lat || 0,
            lng: locationData.lng || 0,
          });
        } else {
          if (forUserAreaOfInterest) {
            let defaultCenter = configuration.sites.map.defaultCenterPoint ?? {
              lat: 43.6043,
              lng: 1.4437,
            };
            if (areaOfInterest?.length) {
              defaultCenter = {
                lat:
                  areaOfInterest.reduce(
                    (acc: number, val: Points) => acc + val.lat,
                    0
                  ) / areaOfInterest.length,
                lng:
                  areaOfInterest.reduce(
                    (acc: number, val: Points) => acc + val.lng,
                    0
                  ) / areaOfInterest.length,
              };
            }
            setInitialMapCenter(defaultCenter);
          } else {
            let defaultCenter = configuration.sites.map.defaultCenterPoint ?? {
              lat: 43.6043,
              lng: 1.4437,
            };
            if (points?.length) {
              defaultCenter = {
                lat:
                  points.reduce((acc, val) => acc + val.lat, 0) / points.length,
                lng:
                  points.reduce((acc, val) => acc + val.lng, 0) / points.length,
              };
            }
            setInitialMapCenter(defaultCenter);
          }
        }
      } else {
        if (forUserAreaOfInterest) {
          let defaultCenter = configuration.sites.map.defaultCenterPoint ?? {
            lat: 43.6043,
            lng: 1.4437,
          };
          if (areaOfInterest?.length) {
            defaultCenter = {
              lat:
                areaOfInterest.reduce(
                  (acc: number, val: Points) => acc + val.lat,
                  0
                ) / areaOfInterest.length,
              lng:
                areaOfInterest.reduce(
                  (acc: number, val: Points) => acc + val.lng,
                  0
                ) / areaOfInterest.length,
            };
          }
          setInitialMapCenter(defaultCenter);
        } else {
          let defaultCenter = configuration.sites.map.defaultCenterPoint ?? {
            lat: 43.6043,
            lng: 1.4437,
          };

          if (points?.length) {
            defaultCenter = {
              lat:
                points.reduce((acc, val) => acc + val.lat, 0) / points.length,
              lng:
                points.reduce((acc, val) => acc + val.lng, 0) / points.length,
            };
          }
          setInitialMapCenter(defaultCenter);
        }
      }
    }
  }, [
    centeredOnUser,
    configuration,
    location,
    locationData,
    points,
    areaOfInterest,
    forUserAreaOfInterest,
    initialRegion,
  ]);

  const animateCamera = useCallback(
    (zoomTarget: number, locationTarget: { lat: number; lng: number }) => {
      if (!mapRef) return;

      mapRef.current?.setZoom(zoomTarget); // Définit le niveau de zoom souhaité
      mapRef.current?.panTo(locationTarget);
    },
    [mapRef]
  );

  useEffect(() => {
    if (filters?.goToUser && locationData) {
      animateCamera(14, { lat: locationData?.lat, lng: locationData?.lng });
    }
  }, [animateCamera, filters, locationData]);

  const drawSelectedPosition = useCallback(() => {
    return selectedPosition ? (
      <Marker
        position={{
          lat: selectedPosition.lat,
          lng: selectedPosition.lng,
        }}
        key={"user-selected-position"}
        icon={{
          url: require("../../../../assets/maps/markers-location.png"),
          scale: 1,
        }}
        zIndex={11}
      />
    ) : null;
  }, [selectedPosition]);

  useEffect(() => {
    if (selectedPosition !== filters?.location) {
      setSelectedPosition(filters?.location ?? null);
      if (filters?.location) {
        setShouldFocusOnDestination(true);
      }
    }
  }, [filters?.location, selectedPosition]);

  useEffect(() => {
    if (selectedPosition && shouldFocusOnDestination) {
      setShouldFocusOnDestination(false);
      animateCamera(14, {
        lat: selectedPosition?.lat,
        lng: selectedPosition?.lng,
      });
    }
  }, [animateCamera, selectedPosition, shouldFocusOnDestination]);

  const { height: pageHeight } = useWindowDimensions();

  const mapContainerWebStyle = {
    height: fullHeight
      ? pageHeight - theme.layout.headerHeight + theme.normalize(30)
      : height,
    width: "100%",
  };

  const zoomAroundPoints = () => {
    if (forUserAreaOfInterest && areaOfInterest?.length > 1) {
      let minLat = areaOfInterest[0].lat;
      let maxLat = areaOfInterest[0].lat;
      let minLng = areaOfInterest[0].lng;
      let maxLng = areaOfInterest[0].lng;

      areaOfInterest.forEach((point: Points) => {
        minLat = Math.min(minLat, point.lat);
        maxLat = Math.max(maxLat, point.lat);
        minLng = Math.min(minLng, point.lng);
        maxLng = Math.max(maxLng, point.lng);
      });

      const latDelta = maxLat - minLat;
      const lngDelta = maxLng - minLng;

      return Math.min(
        Math.log2(360 / lngDelta) + 1, // Largeur du monde (360 degrés) divisée par la différence de longitudes
        Math.log2(180 / latDelta) + 1 // Hauteur du monde (180 degrés) divisée par la différence de latitudes
      );
    } else if (points?.length > 1) {
      let minLat = points[0].lat;
      let maxLat = points[0].lat;
      let minLng = points[0].lng;
      let maxLng = points[0].lng;

      points.forEach((point) => {
        minLat = Math.min(minLat, point.lat);
        maxLat = Math.max(maxLat, point.lat);
        minLng = Math.min(minLng, point.lng);
        maxLng = Math.max(maxLng, point.lng);
      });

      const latDelta = maxLat - minLat;
      const lngDelta = maxLng - minLng;

      return Math.min(
        Math.log2(360 / lngDelta) + 1, // Largeur du monde (360 degrés) divisée par la différence de longitudes
        Math.log2(180 / latDelta) + 1 // Hauteur du monde (180 degrés) divisée par la différence de latitudes
      );
    }
    return zoomLvl || 14;
  };

  const handleDrawindMode = useCallback(() => {
    if (isDrawingMode) {
      setIsDrawingMode(false);
      isDrawingRef.current = false;
      enableMap();
      if (setParentScrollEnabled) {
        setParentScrollEnabled(true);
      }
      if (!forUserAreaOfInterest) {
        setPath([]);
      }
    } else {
      setIsDrawingMode(true);
      disableMap();
      if (setParentScrollEnabled) {
        setParentScrollEnabled(false);
      }
      Animated.timing(fadeAnim, {
        toValue: 0.4, // Toggle between 0 and 0.4
        duration: 400,
        useNativeDriver: false,
      }).start();
    }
  }, [
    isDrawingMode,
    enableMap,
    setParentScrollEnabled,
    forUserAreaOfInterest,
    disableMap,
    fadeAnim,
  ]);

  const onSaveZone = useCallback(() => {
    updateAreaOfInterest(
      path.map((p) => {
        return { lat: p.lat(), lng: p.lng() };
      })
    );
    setShowSaveZoneButton(false);
    handleDrawindMode();
  }, [path, updateAreaOfInterest, handleDrawindMode]);

  const onCancel = useCallback(() => {
    setPath(areaOfInterest);
    setShowSaveZoneButton(false);
    handleDrawindMode();
  }, [areaOfInterest, handleDrawindMode]);

  const onDeleteZone = useCallback(() => {
    updateAreaOfInterest(null);
    setPath(areaOfInterest);
    setShowSaveZoneButton(false);
    handleDrawindMode();
  }, [areaOfInterest, handleDrawindMode, updateAreaOfInterest]);

  useEffect(() => {
    if (isDrawingMode) {
      const a = mapRef?.current?.addListener("mousedown", () => {
        isDrawingRef.current = true;
        setPath([]);
        Animated.timing(fadeAnim, {
          toValue: 0, // Toggle between 0 and 0.4
          duration: 200,
          useNativeDriver: false,
        }).start();
      });
      const b = mapRef?.current?.addListener("mousemove", (event: any) => {
        if (isDrawingRef.current) {
          setPath((currentPath) => [...currentPath, event.latLng]);
        }
      });
      const c = mapRef?.current?.addListener("mouseup", () => {
        if (isDrawingRef.current) {
          isDrawingRef.current = false;
          setPath((currentPath) => [...currentPath, currentPath[0]]);
          if (forUserAreaOfInterest) {
            setShowSaveZoneButton(true);
          }
        }
      });

      return () => {
        a?.remove();
        b?.remove();
        c?.remove();
      };
    }
  }, [fadeAnim, forUserAreaOfInterest, isDrawingMode, path]);

  const onLoad = useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const opacityStyle = { opacity: fadeAnim };

  return (
    <View>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapContainerWebStyle}
          center={initialMapCenter}
          zoom={
            isGeolocationEnabled
              ? zoomLvl || configuration.sites.map.defaultZoomLevel
              : zoomAroundPoints()
          }
          options={
            {
              styles: [
                ...generatedMapStyle,
                {
                  featureType: "poi",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "geometry",
                  stylers: [{ visibility: "on" }],
                },
                {
                  featureType: "transit.station.airport",
                  elementType: "geometry",
                  stylers: [{ visibility: "on" }],
                },
                {
                  featureType: "transit.station.airport",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
              streetViewControl: false,
              fullscreenControl: false,
              scrollEnabled: scrollEnabled,
              zoomControl: false,
              mapTypeControl: false,
              keyboardShortcuts: false,
              terms: false,
              clickableIcons: false,
            } as unknown as google.maps.MapOptions
          }
          onLoad={onLoad}
        >
          {drawSelectedPosition()}
          <MarkerClusterer
            options={{
              maxZoom: 15,
              imagePath: "/cluster", // the lib add a number to the file it's looking for and the extension png
            }}
          >
            {(clusterer) => (
              <div>
                {points.map((point) => {
                  return (
                    <RenderMarker
                      key={point?.id}
                      point={point}
                      selectedPointId={highlightedSiteId ?? selectedPointId}
                      onMarkerPress={onMarkerPress}
                      mapRef={mapRef}
                      setHighlightedSiteId={setHighlightedSiteId}
                      clusterer={clusterer}
                    />
                  );
                })}
              </div>
            )}
          </MarkerClusterer>

          <Polygon
            path={path}
            options={{
              fillColor: theme.colors.info,
              strokeColor: theme.colors.info,
              fillOpacity: 0.4,
              strokeWeight: 2,
              clickable: false,
              editable: false,
            }}
          />

          {isGeolocationEnabled && (
            <Marker
              key="position-user"
              position={{
                lat: location?.coords?.latitude || 0,
                lng: location?.coords?.longitude || 0,
              }}
              icon={{
                url: require("../../../../assets/maps/markers-position.png"),
                scale: 1,
              }}
              zIndex={10}
            />
          )}
        </GoogleMap>
      )}
      {isExplanativeOverlayVisible && (
        <View style={styles.explanativeOverlayContainer} pointerEvents="none">
          <Animated.View
            style={[styles.explanativeOverlay, opacityStyle]}
            pointerEvents="none"
          />
          <JText
            colorName="white"
            label="Dessiner sur la carte pour sélectionner des chantiers"
            style={styles.explanativeText}
            centered
          />
        </View>
      )}
      {mapSearchAndDrawEnabled ? (
        isHorizontal ? (
          <View style={styles.mapFields}>
            <View />
            <SiteSearchField />
            <Clickable onPress={handleDrawindMode} style={styles.mapButton}>
              <Icon
                type="FontAwesome5"
                name="draw-polygon"
                colorName={isDrawingMode ? "info" : "grey"}
              />
            </Clickable>
          </View>
        ) : (
          <Clickable onPress={handleDrawindMode} style={styles.smallMapButton}>
            <Icon
              type="FontAwesome5"
              name="draw-polygon"
              colorName={isDrawingMode ? "info" : "grey"}
            />
          </Clickable>
        )
      ) : null}
      {forUserAreaOfInterest ? (
        showSaveZoneButton ? (
          <View style={styles.userMapButtonContainer}>
            <Clickable
              onPress={() => {
                setIsSavePolygonModalVisible(true);
              }}
              style={styles.userMapButtonFixed}
            >
              <Icon type="Entypo" name="save" colorName="white" />
            </Clickable>
            <Clickable onPress={onCancel} style={styles.userMapButtonFixed}>
              <Icon type="AntDesign" name="close" colorName="white" />
            </Clickable>
          </View>
        ) : (
          <View style={styles.userMapButtonContainer}>
            {areaOfInterest && (
              <Clickable
                onPress={onDeleteZone}
                style={styles.userMapButtonFixed}
              >
                <Icon type="IonIcons" name="trash-outline" colorName="white" />
              </Clickable>
            )}

            <Clickable
              onPress={handleDrawindMode}
              style={styles.userMapButtonFixed}
            >
              <Icon
                type="Material"
                name={areaOfInterest ? "edit" : "add"}
                colorName={isDrawingRef.current ? "dark" : "white"}
              />
            </Clickable>
          </View>
        )
      ) : null}
      {path && path?.length && path?.length > 3 && (
        <ConfirmSavePolygonModal
          modalVisible={isSavePolygonModalVisible}
          setModalVisible={setIsSavePolygonModalVisible}
          zone={path?.map((p) => {
            return typeof p.lat === "number"
              ? { lat: p?.lat, lng: p?.lng }
              : { lat: p?.lat(), lng: p?.lng() };
          })}
          onSave={onSaveZone}
          isDrawing={isDrawingRef.current}
        />
      )}
    </View>
  );
};

export default MapContainer;
