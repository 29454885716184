import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
      paddingBottom: theme.normalize(150),
    },
    icon: {
      alignItems: "center",
      width: "100%",
      paddingTop: theme.normalize(80),
    },
    image: {
      width: theme.normalize(120),
      height: theme.normalize(120),
    },
    text: {
      width: "80%",
      letterSpacing: 0,
      marginTop: theme.normalize(40),
    },
  });
