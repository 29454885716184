//import * as Localization from "expo-localization";
import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "./src/translations/fr.json";

/*const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: (callback) => {
    // We will get back a string like "en-US". We
    // return a string like "en" to match our language
    // files.
    callback(Localization.locale.split("-")[0])
  },
  init: () => {},
  cacheUserLanguage: () => {},
};*/

export const defaultNS = "translation";

export const resources = {
  fr: {
    translation: translationFR,
  },
  /*en: {
    translation: translationEN,
  },*/
} as const;

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  //.use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    returnNull: false,
    compatibilityJSON: "v3",
    fallbackLng: "fr",
    debug: true,
    lng: "fr",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ["translation"],
    defaultNS,
    resources,
  });

export default i18n;

export type I18nKey = Parameters<TFunction>[0];
