import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType } from "theme/ThemeTypes";
import { Icon } from "../../jlib";
import fnStyles from "./SameDayDateStyle";
import { DateItem } from "./DateItem";
import { HourItem } from "./HourItem";

type Props = {
  startDate?: Date;
  endDate?: Date;
  backgroundColor?: ColorType;
  type: "classic" | "large";
  style?: StyleProp<ViewStyle>;
  hideHours?: boolean;
};

export const SameDayDateDisplay: React.FC<Props> = ({
  startDate,
  endDate,
  backgroundColor = "white",
  type,
  style,
  hideHours = false,
}) => {
  const { theme } = useTheme();
  const isLarge = type === "large";

  const styles = fnStyles(theme, isLarge);

  const end = endDate ?? startDate;
  const main = startDate ?? endDate;

  return main ? (
    <View
      style={[
        styles.container,
        backgroundColor && { backgroundColor: theme.colors[backgroundColor] },
        style,
      ]}
    >
      <DateItem date={main} type={type} hideHours />

      {!hideHours && (
        <>
          <View style={styles.item}>
            <HourItem date={main} type={type} />

            {end && (
              <>
                <Icon
                  type={isLarge ? "Entypo" : "AntDesign"}
                  name={isLarge ? "chevron-down" : "down"}
                  sizeName={isLarge ? "largeLow" : "smallMedium"}
                  colorName={isLarge ? "infoDark" : "transparentDark"}
                  style={{
                    marginTop: -theme.sizings.tiny * 1.5,
                    marginBottom: -theme.sizings.tiny * 0.5,
                  }}
                />
                <HourItem date={end} type={type} />
              </>
            )}
          </View>
        </>
      )}
    </View>
  ) : (
    <></>
  );
};
