import React from "react";
import styles from "./common/CatalogStyle";
import { StyleSheet, Text } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Scrollable } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 125,
    marginTop: 16,
  },
});

const ScrollableScreen = () => {
  return (
    <Scrollable additionalProps={{ contentContainerStyle: styles.container }}>
      <CatalogHeader
        title="Scrollable"
        description="This component allows to make children of it scrollable if possible."
      >
        <Text>
          This screen is the whole demo, it's made of a Scrollable component.
        </Text>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<Scrollable additionalProps={{ contentContainerStyle: styles.container }}>\n" +
            "  ...\n" +
            "</Scrollable>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="direction?"
        description="The direction of the component."
        type='"vertical" | "horizontal"'
        topDivider={false}
      />
      <CatalogProps
        propName="style?"
        description="The style of the component"
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="additionalProps?"
        description="The additional props of the component."
        type="ScrollViewProps"
      />
    </Scrollable>
  );
};

export default ScrollableScreen;
