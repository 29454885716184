import React, { useState } from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { JTextInput } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 125,
    marginTop: 16,
  },
});

const TextInputScreen = () => {
  const [text, setText] = useState("");

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="TextInput" description="Renders a text input.">
        <JTextInput
          value={text}
          onChangeValue={setText}
          placeHolder={"Type here..."}
        />
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<JTextInput\n" +
            "  value={text}\n" +
            "  onChangeValue={setText}\n" +
            '  placeHolder={"Type here..."}\n' +
            "/>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="value"
        description="The value of the component."
        type="string"
        topDivider={false}
      />
      <CatalogProps
        propName="onChangeValue"
        description="The callback called when you change the content of the input."
        type="React.Dispatch<React.SetStateAction<string>>"
      />
      <CatalogProps
        propName="placeHolder?"
        description="The placeholder of the input."
        type="string"
      />
      <CatalogProps
        propName="onBlur?"
        description="The callback called when the input blur."
        type="() => void"
      />
      <CatalogProps
        propName="type?"
        description="The type of the input. Search displays a search icon in the front and password makes a password input. (default to default)"
        type='"default" | "search" | "password"'
      />
      <CatalogProps
        propName="keyboardType?"
        description="The value type of the input. (default to string)"
        type='"string" | "number"'
      />
      <CatalogProps
        propName="error?"
        description="Whether there is an error or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="label?"
        description="The label of the input."
        type="string"
      />
      <CatalogProps
        propName="labelKey?"
        description="The I18n key of the label of the input."
        type="I18nKey"
      />
      <CatalogProps
        propName="valuesKey?"
        description="The values key of the I18n label of the input."
        type="object"
      />
      <CatalogProps
        propName="iconType?"
        description="The type of the front icon of the input."
        type="MaterialCommunity | AntDesign | Entypo | EvilIcons | Feather | FontAwesome | Fontisto | Foundation | Material | MaterialIcons | IonIcons"
      />
      <CatalogProps
        propName="icon?"
        description="The name of the front icon of the input."
        type="string"
      />
      <CatalogProps
        propName="colorName?"
        description="The name of the color of the front icon of the input. It has to be one of the color from the Theme."
        type="ColorType"
      />
      <CatalogProps
        propName="fontWeight?"
        description="The font weight of the input."
        type='Types["fontWeights]'
      />
      <CatalogProps
        propName="sizeName?"
        description="The fontsize of the text of the input."
        type="FontSizeType"
      />
      <CatalogProps
        propName="isUppercase?"
        description="Whether the text in the input is in uppercase or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="isBold?"
        description="Whether the text of the input is bold or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="isItalic?"
        description="Whether the text of the input is italic or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="centered?"
        description="Whether the text of the input is centered or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="right?"
        description="Whether the text of the input is placed to the right or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="elevation?"
        description="The elevation of the input. (default to 0)"
        type="boolean"
      />
      <CatalogProps
        propName="containerStyle?"
        description="The style of the component."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="textInputStyle?"
        description="The style of the text input."
        type="StyleProp<TextStyle>"
      />
      <CatalogProps
        propName="iconStyle?"
        description="The style of the icon."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="props?"
        description="The additional props of the input."
        type="TextInputProps"
      />
      <CatalogProps
        propName="keyboardType?"
        description="The type of the keyboard in the input."
        type='Types["keyboardTypes"]'
      />
    </ScrollView>
  );
};

export default TextInputScreen;
