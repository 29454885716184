import React, { createContext, useCallback, useState } from "react";
import { useSites } from "../hooks/useSites";
import { useUser } from "../hooks/useUser";
import { Post } from "../types/Post";
import { fetchJSON, getStoredData } from "../utils";

type PostProviderType = {
  posts?: Post[];
  post: Post | null;
  fetchPost: (id: number) => Promise<Post | null>;
  setPost: (post: Post | null) => void;
  getPosts: () => Promise<[] | null>;
  refreshPosts: () => Promise<[] | null>;
  isFetching: boolean;
  isRefreshing: boolean;
  setIsRefreshing: any;
  errorMessage: string | undefined;
  cleanError: () => void;
  onUpdateUpVoted: (post: Post) => Promise<unknown>;
};

export const PostsContext = createContext<PostProviderType>(
  {} as PostProviderType
);

type Props = {
  children: React.ReactNode;
};

export const PostsProvider = ({ children }: Props) => {
  const [posts, setPosts] = useState<Post[]>();
  const [post, setPost] = useState<Post | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const { refreshSites } = useSites();

  const { followedSites, setLocalHistoryThanksPosts, localHistoryThanksPosts } =
    useUser();

  const fetchPost = useCallback(async (id: number) => {
    try {
      const token = (await getStoredData("token")) || undefined;

      setIsFetching(true);
      const res = await fetchJSON({
        url: `posts/published?filters[post]=${id}`,
        method: "GET",
        jwt: token,
      });

      const p = res ? res[0] : null;
      setPost(p);
      return p;
    } catch {
    } finally {
      setIsFetching(false);
    }
  }, []);

  const followedSitesParams =
    followedSites && followedSites.length > 0
      ? "&" +
        followedSites
          .map(
            (siteId: number, siteIdIndex: number) =>
              `filters[site][${siteIdIndex}]=${siteId}`
          )
          .join("&")
      : "";

  const getPosts = useCallback(async () => {
    try {
      const token = (await getStoredData("token")) || undefined;

      setIsFetching(true);
      const res = await fetchJSON({
        url: `posts/followed?sort[0]=pinned:desc&sort[1]=pinnedDate:desc&sort[2]=publishedDate:desc&sort[3]=startEventDate:desc${followedSitesParams}`,
        method: "GET",
        jwt: token,
      });

      setPosts(res);
      return res;
    } catch {
      return [];
    } finally {
      setIsFetching(false);
    }
  }, [followedSitesParams]);

  const refreshPosts = useCallback(async () => {
    try {
      const token = (await getStoredData("token")) || undefined;

      setIsRefreshing(true);
      const res = await fetchJSON({
        url: `posts/followed?sort[0]=pinned:desc&sort[1]=pinnedDate:desc&sort[2]=publishedDate:desc&sort[3]=startEventDate:desc${followedSitesParams}`,
        method: "GET",
        jwt: token,
      });

      setPosts(res);
      return res;
    } catch {
      return [];
    } finally {
      setIsRefreshing(false);
    }
  }, [followedSitesParams]);

  const onUpdateUpVoted = useCallback(
    async (p: Post) => {
      const hasLiked = localHistoryThanksPosts.find(
        (postThanksId: any) => postThanksId === p?.id
      );

      try {
        await fetchJSON({
          url: "posts/thanks",
          method: "POST",
          payload: {
            data: { id: p.id, direction: hasLiked ? "down" : "up" },
          },
        });

        // root home page
        refreshPosts();

        // in site page
        refreshSites();

        setPost((currentPost) => {
          if (currentPost) {
            return {
              ...currentPost,
              thanks: hasLiked
                ? -1 + (currentPost?.thanks || 0)
                : 1 + (currentPost?.thanks || 0),
            };
          }
          return null;
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLocalHistoryThanksPosts((oldThanks: Array<number>) => {
          if (hasLiked) {
            return oldThanks.filter((ot: any) => ot !== p.id);
          } else {
            return [...oldThanks, p.id];
          }
        });
      }
    },
    [
      refreshPosts,
      setLocalHistoryThanksPosts,
      localHistoryThanksPosts,
      refreshSites,
    ]
  );

  const cleanError = useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  return (
    <PostsContext.Provider
      value={{
        posts,
        getPosts,
        fetchPost,
        setPost,
        post,
        isFetching,
        isRefreshing,
        setIsRefreshing,
        refreshPosts,
        errorMessage,
        cleanError,
        onUpdateUpVoted,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};
