import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";
import hexToRgba from "hex-to-rgba";

export default (theme: Theme, width: number, isHorizontalMode: boolean) =>
  StyleSheet.create({
    image: {
      height: isHorizontalMode ? theme.normalize(250) : width * 0.7,
      borderRadius: isHorizontalMode ? theme.radius.small : 0,
    },
    siteImageTitle: {
      flexDirection: "row",
      flex: isHorizontalMode ? 1 : undefined,
      alignSelf: "stretch",
    },
    siteLink: {
      marginRight: theme.sizings.medium,
    },
    siteImage: {
      borderRadius: theme.radius.medium,
      minWidth: theme.normalize(theme.sizings.huge * 0.85),
      minHeight: theme.normalize(theme.sizings.huge * 0.85),
      marginTop: theme.sizings.small,
    },
    content: {
      paddingHorizontal: theme.normalize(16),
    },
    richText: {},
    header: {
      flexDirection: "column",
      marginTop: theme.normalize(16),
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    title: {
      flex: 1,
    },
    date: {
      marginTop: theme.sizings.medium,
    },
    description: {
      marginTop: theme.sizings.medium,
      marginBottom: theme.sizings.large / 2,
    },
    map: {
      width: "100%",
      height: isHorizontalMode ? width * 0.4 : width * 0.8,
      backgroundColor: theme.colors.greyLight,
      borderRadius: theme.radius.medium,
      overflow: "hidden",
    },
    alertContainer: {
      flexDirection: isHorizontalMode ? "row" : "column",
      alignItems: "stretch",
      marginTop: theme.normalize(theme.sizings.smallMedium),
      gap: theme.sizings.medium,
    },
    alertType: {
      flex: 1,
      backgroundColor: hexToRgba(theme.colors.primary, 0.2),
      borderRadius: theme.normalize(15),
      paddingVertical: theme.normalize(theme.sizings.medium),
      paddingHorizontal: theme.normalize(theme.sizings.medium),
      flexDirection: "row",
      alignItems: "center",
      gap: theme.sizings.small,
    },
    alertLevel: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      gap: theme.sizings.small,
      backgroundColor: theme.colors.infoLight,
      borderRadius: theme.normalize(15),
      paddingVertical: theme.normalize(theme.sizings.medium),
      paddingHorizontal: theme.normalize(theme.sizings.medium),
    },
    alertIcon: {
      width: theme.normalize(40),
      height: theme.normalize(40),
    },
    alertInfo: {
      flex: 1,
    },
    button: {
      position: "absolute",
      bottom: theme.normalize(90),
      right: theme.normalize(16),
    },
    buttonLabel: {
      marginLeft: theme.normalize(theme.sizings.large),
      marginRight: theme.normalize(theme.sizings.mediumLarge),
    },
    pin: {
      marginLeft: theme.normalize(-5),
      marginRight: theme.sizings.tiny,
      position: "relative",
      top: theme.sizings.tiny / 2,
      width: theme.normalize(20),
    },
    actions: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    time: {
      flexDirection: "row",
      alignItems: "center",
    },
    timeLabel: {
      marginLeft: theme.normalize(4),
    },
    thanksCount: {
      marginRight: theme.normalize(4),
    },
    actionsContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    likeSpinner: {
      width: theme.normalize(30),
      height: theme.normalize(30),
      marginRight: theme.normalize(4),
    },
    upThanks: {
      marginRight: theme.normalize(4),
      backgroundColor: hexToRgba(theme.colors.info, 0.3),
      justifyContent: "center",
      alignItems: "center",
      width: theme.normalize(30),
      height: theme.normalize(30),
      borderRadius: theme.normalize(15),
    },
    downThanks: {
      marginRight: theme.normalize(4),
      backgroundColor: theme.colors.info,
      justifyContent: "center",
      alignItems: "center",
      width: theme.normalize(30),
      height: theme.normalize(30),
      borderRadius: theme.normalize(15),
    },
  });
