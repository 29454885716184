import { Stack, JImage, Column, JText, Icon } from "components/jlib";
import React from "react";
import { View } from "react-native";
import fnStyles from "./SimpleListItemContentStyle";
import useTheme from "theme/ThemeProvider";

type Props = {
  label: string;
  media?: string;
};

export const SimpleListItemContent = ({ label, media }: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Stack
          containerStyle={{
            marginRight: theme.sizings.small,
          }}
          backView={
            media ? (
              <JImage source={media} type={"Uri"} style={styles.siteImage} />
            ) : (
              <></>
            )
          }
        />
        <Column style={styles.column}>
          <JText label={label} sizeName="mediumLarge" isBold />
        </Column>

        <Icon
          name="chevron-right"
          type="Entypo"
          colorName="grey"
          style={styles.icon}
        />
      </View>
    </View>
  );
};
