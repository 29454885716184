import React from "react";
import { StyleProp, View, ViewStyle, useWindowDimensions } from "react-native";

import { isHorizontalMode } from "components/utils/deviceMode";
import fnStyles from "./BoxedLayoutStyle";
import useTheme from "theme/ThemeProvider";

type Props = {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode[] | React.ReactNode;
};

export const BoxedLayout: React.FC<Props> = ({ style, children }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));
  return (
    <View style={[styles.container, style]} role="main">
      {children}
    </View>
  );
};
