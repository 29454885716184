import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    findLocationButton: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: theme.sizings.large,
      marginBottom: theme.sizings.smallMedium,
    },
    findLocationButtonText: {
      marginLeft: theme.sizings.smallMedium,
    },
    findLocationIcon: {
      marginLeft: -theme.sizings.smallMedium,
    },
    gradientContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: theme.normalize(45),
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      paddingHorizontal: theme.normalize(theme.sizings.mediumLarge),
      borderRadius: theme.radius.small,
      paddingVertical: theme.sizings.medium * 0.6,
      flexDirection: "row",
    },
  });
