import React from "react";
import { StackScreenProps } from "@react-navigation/stack";
import { SitesParamsList } from "../../navigation";
import { SectionList } from "./sections/sectionList";

type Props = StackScreenProps<SitesParamsList, "siteList">;

export const SitesListScreen: React.FC<Props> = ({ navigation }: Props) => {
  return <SectionList navigation={navigation} />;
};
