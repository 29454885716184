import { Thread } from "../../types/ThreadType";
import React from "react";
import { View } from "react-native";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ThreadItemContentStyle";
import { Icon, JText } from "../jlib";
import { Site } from "../../types/Site";
import dayjs from "dayjs";
import { useUser } from "../../hooks/useUser";
import { JHtml } from "components/jlib/jHtml/JHtml";

type Props = {
  data: Thread | Site;
  isSite?: boolean;
};

export const ThreadItemContent: React.FC<Props> = ({
  data,
  isSite = false,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { user } = useUser();

  const item = {
    description: "",
    time: "",
    message: "",
    isRead: false,
    documents: [] as any[],
  };
  if (isSite) {
    item.description = (data as Site).description ?? "";
  } else {
    const tmp = data as Thread;
    const lastMessage = tmp.messages.find(
      (message) =>
        Number(message.id) ===
        Math.max(...tmp?.messages?.map((m) => Number(m.id)))
    );
    item.time = dayjs(lastMessage?.createdAt).locale("fr").fromNow() ?? "";
    item.message = lastMessage?.content ?? "";
    item.documents = lastMessage?.documents?.map((doc: any) => doc.url) ?? [];
    // pour savoir si le thread est lu ou non
    item.isRead =
      lastMessage?.author?.id !== tmp?.author?.id &&
      lastMessage?.author?.id !== user?.id
        ? lastMessage?.isRead ?? true
        : true;
  }

  return isSite ? (
    <View style={styles.container}>
      {(data as Site).descriptionRich ? (
        <JHtml source={(data as Site).descriptionRich} truncateLength={100} />
      ) : (
        <JText
          label={(data as Site).description}
          sizeName="mediumLarge"
          numberOfLines={3}
        />
      )}
    </View>
  ) : (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.date}>
          <Icon
            name="clockcircle"
            type="AntDesign"
            colorName="grey"
            style={styles.rowLeft}
            sizeName="medium"
          />
          <JText label={item.time} colorName={"grey"} sizeName="medium" />
        </View>

        {item.documents?.length > 0 && (
          <View style={styles.horizontal}>
            <Icon
              name="attachment"
              type="MaterialIcons"
              colorName="white"
              style={styles.attachment}
              sizeName="large"
            />
            <JText
              label={`${item.documents?.length}`}
              sizeName="large"
              colorName="white"
            />
          </View>
        )}
      </View>
      <View style={styles.row}>
        {!item.isRead && (
          <JText
            labelKey="chat.unread"
            isBold
            style={styles.rowLeft}
            sizeName="mediumLarge"
          />
        )}
        {item.message?.length > 0 && (
          <JText
            label={item.message}
            isBold={!item.isRead}
            numberOfLines={3}
            sizeName="mediumLarge"
          />
        )}
      </View>
    </View>
  );
};
