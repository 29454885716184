import React from "react";
import { View, ImageBackground } from "react-native";
import { Button, JText, Spacer } from "../jlib";
import fnStyles from "./NoSitesFollowedStyle";
import { StackNavigationProp } from "@react-navigation/stack";
import { PostsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import getWording from "../../utils/wording";
import { useTranslation } from "react-i18next";
import useConfiguration from "../../configuration/ConfigurationProvider";

type Props = {
  navigation: StackNavigationProp<PostsParamsList, "postsHome">;
};

export const NoSitesFollowed: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();

  const { configuration } = useConfiguration();
  return (
    <View style={styles.container}>
      <View style={styles.icon}>
        <ImageBackground
          source={theme.images.pictoSearch}
          style={styles.image}
        />
        <Spacer height="high" backgroundColor={"transparent"} />

        <JText
          label={t("site.noFollowed", {
            wording: getWording(configuration).plural,
          })}
          centered
          style={styles.text}
        />
      </View>
      <Spacer height="mediumLarge" backgroundColor={"transparent"} />

      <Button
        onPress={() => {
          // @ts-ignore
          navigation.navigate("sites");
        }}
        label={t("site.search", {
          wording: getWording(configuration).plural,
        })}
        type={"gradient"}
      />
    </View>
  );
};

export const NoSites = () => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.container}>
      <View style={styles.icon}>
        <ImageBackground
          source={theme.images.pictoSearch}
          style={styles.image}
        />
        <Spacer height="high" backgroundColor={"transparent"} />

        <JText labelKey="site.noSiteToContact" centered style={styles.text} />
      </View>
    </View>
  );
};
