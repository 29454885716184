interface Map {
  [key: string]: any;
}

const images: Map = {
  imageTest: require("../../../../assets/image-test.jpg"),
  markerBig: require("../../../../assets/maps/map-marker-big.png"),
  markerMin: require("../../../../assets/maps/map-marker-min.png"),
};

export { images };
