import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    cardContainer: {
      borderRadius: theme.normalize(200),
      padding: theme.normalize(10),
      marginHorizontal: theme.sizings.medium,
      marginTop: theme.sizings.medium,
    },
    image: {
      width: theme.normalize(80),
      height: theme.normalize(80),
      borderRadius: theme.normalize(40),
    },
    contentContainer: {
      justifyContent: "space-between",
      flex: 1,
    },
  });
