export const computeDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const rayonTerre = 6371; // Rayon moyen de la Terre en kilomètres

  // Conversion des degrés en radians
  const lat1Rad = degresVersRadians(lat1);
  const lon1Rad = degresVersRadians(lon1);
  const lat2Rad = degresVersRadians(lat2);
  const lon2Rad = degresVersRadians(lon2);

  // Différence de latitude et de longitude
  const deltaLat = lat2Rad - lat1Rad;
  const deltaLon = lon2Rad - lon1Rad;

  // Calcul de la distance en utilisant la formule de la haversine
  const a =
    Math.sin(deltaLat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = rayonTerre * c;

  return distance;
};

const degresVersRadians = (degres: number) => {
  return degres * (Math.PI / 180);
};
