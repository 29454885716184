import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { DifferentDayDateDisplay } from "./DifferentDayDate";
import { SameDayDateDisplay } from "./SameDayDate";

type Props = {
  startDate?: Date;
  endDate?: Date;
  type?: "classic" | "large";
  style?: StyleProp<ViewStyle>;
  hideHours?: boolean;
};

const datesAreOnSameDay = (first: Date, second: Date) => {
  const dateFirst = new Date(first);
  const dateSecond = new Date(second);
  return (
    dateFirst.getFullYear() === dateSecond.getFullYear() &&
    dateFirst.getMonth() === dateSecond.getMonth() &&
    dateFirst.getDate() === dateSecond.getDate()
  );
};

export const DateDisplay: React.FC<Props> = ({
  startDate,
  endDate,
  type = "classic",
  hideHours = false,
  style,
}) => {
  const areDatesOnSameDay =
    startDate && endDate ? datesAreOnSameDay(startDate, endDate) : true;

  const forceHoursDisplay =
    (!!startDate && new Date(startDate).getHours() !== 0) ||
    (!!endDate && new Date(endDate).getHours() !== 0);

  return areDatesOnSameDay ? (
    <SameDayDateDisplay
      startDate={startDate}
      endDate={endDate}
      type={type}
      style={style}
      hideHours={hideHours || !forceHoursDisplay}
    />
  ) : (
    <DifferentDayDateDisplay
      startDate={startDate}
      endDate={endDate}
      type={type}
      style={style}
      hideHours={hideHours || !forceHoursDisplay}
    />
  );
};
