import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { Password } from "components/common/password";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackgroundLayout,
  Button,
  Card,
  JText,
  JTextInput,
  SView,
  Spacer,
} from "../../components";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ResetPasswordScreenStyle";

type Props = StackScreenProps<ParamsParamsList, "resetPassword">;

export const ResetPasswordScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [canChangePassword, setCanChangePassword] = useState(false);
  const [error, setError] = useState(false);

  const { resetPassword } = useUser();

  const onSubmit = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const code = urlParams.get("code") || "";

    const res = await resetPassword(password, code);

    if (res) {
      navigation.navigate("paramsHome");
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (password.length > 0 && password === confirmPassword) {
      setCanChangePassword(true);
    } else {
      setCanChangePassword(false);
    }
  }, [confirmPassword, password]);

  return (
    <BackgroundLayout
      style={{
        marginTop: theme.normalize(-30),
        paddingTop: theme.normalize(30),
      }}
    >
      <SView>
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText
              labelKey="auth.reinitPassword"
              isBold
              centered
              titleLevel={1}
              titleLevelStyle={2}
            />
            <Password
              value={password}
              onChangeValue={setPassword}
              setIsPasswordValid={setIsPasswordValid}
              isPasswordValid={isPasswordValid}
              placeHolder={t("auth.password")}
              containerStyle={{
                marginTop: theme.normalize(theme.sizings.large),
              }}
            />
            <JTextInput
              value={confirmPassword}
              onChangeValue={setConfirmPassword}
              inputType={"password"}
              icon="key"
              placeHolder={t("auth.confirmPassword")}
              containerStyle={{
                marginTop: theme.normalize(theme.sizings.mediumLarge),
              }}
            />
            <Spacer height="medium" backgroundColor={"transparent"} />
            {confirmPassword !== "" && password !== confirmPassword ? (
              <JText
                labelKey="auth.confirmPasswordError"
                colorName={"danger"}
                sizeName="mediumLarge"
              />
            ) : null}
            {error ? (
              <JText labelKey="auth.reinitPasswordError" colorName="danger" />
            ) : null}
            <Button
              type={"gradient"}
              labelKey="common.validate"
              onPress={onSubmit}
              containerStyle={styles.button}
              disabled={!canChangePassword}
            />
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
