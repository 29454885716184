import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    cardContainer: {
      marginHorizontal: theme.normalize(theme.sizings.medium),
      marginVertical: theme.normalize(theme.sizings.small),
      paddingHorizontal: theme.normalize(theme.sizings.smallMedium),
      paddingVertical: theme.normalize(theme.sizings.smallMedium),
      flexDirection: "row",
    },
    cardContainerHighlighted: {
      marginHorizontal: theme.normalize(theme.sizings.medium),
      marginVertical: theme.normalize(theme.sizings.small),
      paddingHorizontal: theme.normalize(theme.sizings.smallMedium),
      paddingVertical: theme.normalize(theme.sizings.smallMedium),
      flexDirection: "row",
      backgroundColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.3,
      shadowRadius: 1.41,
      elevation: 3,
    },
    cardContainerWebFooter: {
      flexDirection: "row",
      width: "100%",
      paddingTop: theme.sizings.huge * 0.7,
      paddingBottom: theme.sizings.medium,
    },
    image: {
      borderRadius: theme.normalize(theme.radius.medium),
      width: theme.normalize(75),
      height: theme.normalize(75),
    },

    contentContainer: {
      marginLeft: theme.normalize(theme.sizings.medium),
      flex: 1,
    },
    header: {
      justifyContent: "space-between",
      alignItems: "flex-start",
      paddingBottom: theme.normalize(theme.sizings.smallMedium),
    },
    headerLeft: {
      flex: 1,
      marginRight: theme.sizings.medium,
      gap: theme.sizings.tiny,
    },
    title: {
      //letterSpacing: 0.6,
    },
    pin: {
      marginLeft: theme.normalize(-5),
      marginRight: theme.sizings.tiny,
      position: "relative",
      top: theme.sizings.tiny / 2,
      width: theme.normalize(20),
    },
    pinText: {
      width: "80%",
    },
    description: {
      width: "90%",
    },
    footer: {
      marginTop: theme.normalize(theme.sizings.smallMedium),
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    footerIcon: {
      marginTop: theme.normalize(2),
    },
    cardContainerAppFooter: {
      flexDirection: "row",
      width: "100%",
      paddingVertical: theme.sizings.medium,
    },
    badge: {
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "flex-start",
      paddingHorizontal: theme.sizings.smallMedium,
      borderRadius: theme.radius.medium,
      backgroundColor: theme.colors.greyLight,
      minHeight: theme.normalize(25),
    },
    mapIcon: {
      marginRight: theme.sizings.small,
    },
  });
