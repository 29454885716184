import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { StackScreenProps } from "@react-navigation/stack";
import { NewChatButton } from "components/chat/NewChatButton";
import { isHorizontalMode } from "components/utils/deviceMode";
import { usePosts } from "hooks";
import React, { useCallback, useEffect } from "react";
import { View, useWindowDimensions } from "react-native";
import { BackgroundLayout, TopNavigationBar } from "../../components";
import { PostsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./PostDetailsScreenStyle";
import { PostInformationScreen } from "./PostInformationScreen";
import { PostQuestionScreen } from "./PostQuestionsScreen";
import useThread from "../../hooks/useThread";
import { useTranslation } from "react-i18next";

type Props = StackScreenProps<PostsParamsList, "postDetails">;

const Tab = createMaterialTopTabNavigator<PostsParamsList>();

export const PostDetailsScreen: React.FC<Props> = ({ route, navigation }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { post, fetchPost } = usePosts();
  const { t } = useTranslation();

  const styles = fnStyles(theme, isHorizontalMode({ width, height }), width);

  const { getThreadTypes } = useThread();
  const threadTypes = getThreadTypes(post?.site);

  const onChatNav = () => {
    navigation.navigate("chatSelectType", {
      postId: post?.id,
      siteId: Number(post?.site?.id),
    });
  };

  useEffect(() => {
    if (!post || post.id !== route.params.postId) {
      fetchPost(route.params.postId);
    }
  }, [route.params.postId, post, fetchPost]);

  const tabBar = useCallback(
    (props: any) => <TopNavigationBar {...props} low />,
    []
  );

  if (!post) {
    return <View />;
  }

  return (
    <>
      <BackgroundLayout style={styles.container}>
        {Boolean(threadTypes?.length) && (
          <NewChatButton
            navigation={navigation}
            handleNav={onChatNav}
            bottomBar={false}
            isReaction
          />
        )}
        <Tab.Navigator
          initialRouteName={
            route.params.firstFaq ? "postQuestions" : "postInformation"
          }
          screenOptions={() => ({
            tabBarScrollEnabled: false,
            swipeEnabled: false,
            animationEnabled: false,
            title: `${post.title}` ?? t("post.headerDetails"),
          })}
          tabBar={tabBar}
        >
          <Tab.Screen
            name={"postInformation"}
            component={PostInformationScreen}
            initialParams={{ fromSite: route.params.fromSite ?? true }}
          />

          {Boolean(post?.qAndAs?.length) && (
            <Tab.Screen name={"postQuestions"} component={PostQuestionScreen} />
          )}
        </Tab.Navigator>
      </BackgroundLayout>
    </>
  );
};
