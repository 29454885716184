import React from "react";
import { Icon, JText, Spacer } from "../../jlib";
import { View } from "react-native";
import useTheme from "theme/ThemeProvider";
import fnStyles from "./ActiveNotificationsContentStyle";
import getWording from "utils/wording";
import useConfiguration from "../../../configuration/ConfigurationProvider";

type Props = {};

export const ActiveNotificationsContent: React.FC<Props> = ({}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { configuration } = useConfiguration();

  return (
    <>
      <View style={styles.modalTitle}>
        <Icon
          name="check"
          type="Entypo"
          colorName="white"
          style={styles.modalTitleIcon}
          sizeName="larger"
        />
        <JText
          centered
          isBold
          labelKey="modal.notifications"
          sizeName="large"
          valuesKey={{ wording: getWording(configuration).singularCapitalize }}
          titleLevel={2}
        />
      </View>
      <Spacer height="mediumLarge" backgroundColor="transparent" />
      <JText
        labelKey="modal.notificationsDescription"
        sizeName="mediumLarge"
        valuesKey={{ wording: getWording(configuration).singular }}
      />
      <Spacer height="mediumLarge" backgroundColor="transparent" />
      <JText
        labelKey="modal.notificationsMayChange"
        sizeName="mediumLarge"
        valuesKey={{ wording: getWording(configuration).singular }}
      />
    </>
  );
};
