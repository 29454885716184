import React from "react";
import { JText, Spacer } from "../../jlib";

type Props = { descriptionKey?: string };

export const SubscribeContent: React.FC<Props> = ({
  descriptionKey = "modal.userRights",
}) => {
  return (
    <>
      <JText centered isBold labelKey="auth.messages" titleLevel={2} />
      <Spacer height="mediumLarge" backgroundColor="transparent" />
      <JText labelKey={descriptionKey} sizeName="mediumLarge" />
    </>
  );
};
