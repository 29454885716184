export default {
  logo: {
    width: 200,
  },
  sites: {
    wording: {
      Sg_main: "site",
      Pl_main: "sites",
    },
    display: {
      organization: true,
      searchBar: false,
      typesSeparator: false,
    },
    features: {
      locationMandatory: true,
      suggestionForm: true,
    },
    map: {
      defaultCenter: "user",
      defaultCenterPoint: null,
      defaultZoomLevel: 10,
      defaultMobileZoomLevel: 0.5,
    },
  },
  profile: {
    email: {
      share: true,
    },
    phone: {
      share: true,
      sms: true,
    },
    features: {
      loginMandatory: false,
      hasUserType: false,
      enableAboveFifteen: false,
    },
  },
  threads: {
    features: {
      enableThreadWithoutSite: false,
      enableThreadWithLocation: false,
    },
  },
};
