import { StackNavigationProp } from "@react-navigation/stack";
import { SubscribeModal } from "components/modals/subscribeModal";
import { useUser } from "hooks";
import { PostsParamsList, SitesParamsList } from "navigation";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { View, ImageBackground } from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { Button, JText } from "../jlib";
import fnStyles from "./NoQuestionsStyle";
import useThread from "../../hooks/useThread";
import { Site } from "types/Site";

type Props = {
  handleNav: () => any;
  navigation:
    | StackNavigationProp<PostsParamsList, "postQuestions">
    | StackNavigationProp<SitesParamsList, "siteQuestions">;
  site?: Site;
};

export const NoQuestions: React.FC<Props> = ({
  handleNav,
  navigation,
  site,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const { user } = useUser();
  const [subscribeModalVisible, setSubscribeModalVisible] =
    useState<boolean>(false);

  const { getThreadTypes } = useThread();
  const threadTypes = getThreadTypes(site ? site : undefined); // espace null case

  const handleButtonPressed = useCallback(() => {
    if (!user) {
      setSubscribeModalVisible(true);
      // peut-être plus tard si c'est une feature demandée
      /*if (Platform.OS === "web") {
        setSubscribeModalVisible(true);
      } else {
        alert(
          "création du user anonyme en même temps qu'on redirige vers le chat"
        );
        onChatNav();
      }*/
    } else {
      handleNav();
    }
  }, [handleNav, user]);

  return (
    <View style={styles.container}>
      <ImageBackground source={theme.images.pictoSearch} style={styles.image} />
      <JText label={t("qAndA.noQuestions")} centered style={styles.text} />

      {Boolean(threadTypes?.length) && (
        <Button
          onPress={handleButtonPressed}
          label={t("qAndA.addQuestion")}
          type={"gradient"}
          containerStyle={styles.button}
        />
      )}
      <SubscribeModal
        modalVisible={subscribeModalVisible}
        setModalVisible={setSubscribeModalVisible}
        navigation={navigation}
      />
    </View>
  );
};
