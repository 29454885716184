import { Theme } from "theme/ThemeTypes";
import { Platform, StyleSheet } from "react-native";

export enum zoomLevel {
  SMALL = 1,
  MEDIUM = 5,
  HUGE = 10,
  ZONE = 15,
  XHUGE = 16,
}
export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      height: "100%",
    },
    customPoint: {
      width: theme.normalize(theme.sizings.large),
      height: theme.normalize(theme.sizings.large),
    },
    pointCircle: {
      width: theme.normalize(theme.sizings.large),
      height: theme.normalize(theme.sizings.large),
      borderRadius: theme.normalize(theme.radius.large),
      borderWidth: theme.normalize(theme.sizings.tiny),
      borderColor: theme.colors.secondary,
      backgroundColor: theme.colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pointCirclelabel: {
      color: theme.colors.white,
      textAlign: "center",
      width: "100%",
      height: "auto",
      fontSize: theme.normalizeFont(theme.fontSizes.larger),
    },
    pointUser: {
      width: theme.normalize(theme.sizings.large),
      height: theme.normalize(theme.sizings.large),
      borderRadius: theme.normalize(theme.radius.large),
      borderWidth: theme.normalize(theme.sizings.tiny),
      borderColor: theme.colors.tinyElementsBackground,
      backgroundColor: theme.colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mapFields: {
      position: "absolute",
      top: 50,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingHorizontal: theme.normalize(20),
      zIndex: 11,
    },
    mapButton: {
      backgroundColor: theme.colors.white,
      height: theme.normalize(40),
      paddingHorizontal: theme.normalize(10),
      borderRadius: theme.radius.medium,
      justifyContent: "center",
      alignItems: "center",
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
    },
    smallMapButton: {
      position: "absolute",
      bottom:
        Platform.OS === "web" ? 110 : Platform.OS === "android" ? 80 : 120,
      left: 10,
      backgroundColor: theme.colors.white,
      height: theme.normalize(40),
      paddingHorizontal: theme.normalize(10),
      borderRadius: theme.radius.medium,
      justifyContent: "center",
      alignItems: "center",
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
      zIndex: 11,
    },
    nativeGestureDetector: {
      backgroundColor: "transparent",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    explanativeOverlayContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 10,
      justifyContent: "center",
      alignItems: "center",
    },
    explanativeOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "black",
      zIndex: 10,
    },
    explanativeText: {
      zIndex: 11,
      alignSelf: "center",
    },
    userMapButton: {
      position: "absolute",
      bottom: 20,
      right: 10,
      width: theme.normalize(theme.sizings.larger),
      height: theme.normalize(theme.sizings.larger),
      borderRadius: theme.normalize(theme.sizings.larger / 2),
      backgroundColor: theme.colors.headerBackground,
      justifyContent: "center",
      alignItems: "center",
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
      zIndex: 11,
    },
    userMapButtonDisabled: {
      backgroundColor: theme.colors.blurBackground,
    },
    userMapButtonContainer: {
      position: "absolute",
      bottom: 20,
      right: 10,
      gap: 4,
      justifyContent: "flex-end",
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "flex-end",
    },
    userMapButtonFixed: {
      width: theme.normalize(theme.sizings.larger),
      height: theme.normalize(theme.sizings.larger),
      borderRadius: theme.normalize(theme.sizings.larger / 2),
      backgroundColor: theme.colors.headerBackground,
      justifyContent: "center",
      alignItems: "center",
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
      zIndex: 11,
    },
  });

// Easy tool to custom map : https://mapstyle.withgoogle.com
export const generatedMapStyle = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        color: "#FF9253",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f9f5ed",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aee0f4",
      },
    ],
  },
];
