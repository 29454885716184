import React from "react";
import { Site } from "../../types/Site";
import { Post } from "../../types/Post";
import { Card, JImage, JText, Row, Spacer } from "../jlib";
import { View } from "react-native";
import fnStyles from "./ReactHeaderBannerStyle";
import useTheme from "../../theme/ThemeProvider";

type Props = {
  site?: Site | null;
  post?: Post | null;
};

export const ReactHeaderBanner: React.FC<Props> = ({ site, post }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const image = post
    ? post?.mainImage?.url
    : site?.logo
    ? site?.logo.url
    : site?.mainImage?.url;

  return (
    <Card style={styles.cardContainer}>
      {image ? (
        <Row align={"center"}>
          <JImage source={image} type={"Uri"} style={styles.image} />

          <Spacer width={"medium"} />

          <View style={styles.contentContainer}>
            <JText label={post ? post.title : site?.name} isBold />
            <Spacer height={"small"} />
            <JText
              label={post ? post.site?.name : site?.location}
              colorName={"greyDark"}
              sizeName={"mediumLarge"}
              numberOfLines={2}
              isItalic
            />
          </View>
        </Row>
      ) : (
        <View>
          <JText label={post ? post.title : site?.name} isBold centered />
          <Spacer height={"small"} />
          <JText
            label={post ? post.site?.name : site?.location}
            colorName={"greyDark"}
            sizeName={"mediumLarge"}
            numberOfLines={2}
            isItalic
            centered
          />
        </View>
      )}
    </Card>
  );
};
