import { JText } from "components/jlib";
import React, { useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import useTheme from "theme/ThemeProvider";
import { useLocation } from "hooks";
import { computeDistance } from "utils/computeDistance";
import fnStyles from "./SiteCardStyle";
import useSites from "hooks/useSites";

type Props = {
  siteLocation: {
    lat: number;
    lng: number;
  };
  style?: StyleProp<ViewStyle>;
};

const displayDistance = (distanceInKm: number) => {
  if (distanceInKm >= 1 && distanceInKm < 10) {
    return `${distanceInKm.toFixed(1)} km`;
  } else if (distanceInKm >= 10) {
    return `${Math.floor(distanceInKm)} km`;
  } else {
    const distanceInMeters = distanceInKm * 1000;
    return `${Math.floor(distanceInMeters)} m`;
  }
};

export const SiteDistance: React.FC<Props> = ({ siteLocation, style = {} }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { locationData } = useLocation();
  const { filters } = useSites();

  const distance = useMemo(
    () =>
      computeDistance(
        siteLocation.lat || 0,
        siteLocation.lng || 0,
        filters?.location?.lat ?? locationData?.lat ?? 0,
        filters?.location?.lng ?? locationData.lng ?? 0
      ),
    [siteLocation, locationData, filters?.location]
  );

  return (
    <View style={[styles.badge, style]}>
      <JText
        label={displayDistance(distance)}
        sizeName="medium"
        colorName="dark"
        isBold
      />
    </View>
  );
};
