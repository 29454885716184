import React, { useCallback, useMemo } from "react";
import {
  View,
  Modal,
  ViewStyle,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { Button, Clickable, JText, Spacer } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
//import fnStyles from "./DeleteAccountModalStyle";
import { useUser } from "../../hooks/useUser";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { useTranslation } from "react-i18next";
import useSites from "hooks/useSites";
import * as turf from "@turf/turf";
import getWording from "utils/wording";
import useConfiguration from "../../configuration/ConfigurationProvider";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
  zone: any;
  onSave: any;
  isDrawing: any;
};

export const ConfirmSavePolygonModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  zone,
  onSave,
  isDrawing,
}) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const { followedSites, updateFollowedSites } = useUser();
  const { sites } = useSites();
  const { configuration } = useConfiguration();

  const layerStyle = {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000095",
    height,
  } as ViewStyle;

  const panelStyle = {
    padding: theme.sizings.large,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radius.mediumLarge,
    maxWidth: width - theme.sizings.mediumLarge * 2,
  } as ViewStyle;

  const footerStyle = {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  } as ViewStyle;

  const unfollowedSitesInZones = useMemo(() => {
    if (!isDrawing) {
      const tmpZone = [...zone];
      if (
        tmpZone[0].lng !== tmpZone[tmpZone.length - 1].lng ||
        tmpZone[0].lat !== tmpZone[tmpZone.length - 1].lat
      ) {
        tmpZone.push(tmpZone[0]);
      }
      const polygon = turf.polygon([
        tmpZone.map((point: any) => [point.lng, point.lat]),
      ]);

      return sites?.filter((s) => {
        if (s.siteType?.isGeo && s.geojson?.lat && s.geojson?.lng) {
          const pt = turf.point([s.geojson.lng, s.geojson.lat]);

          return (
            turf.booleanPointInPolygon(pt, polygon) &&
            followedSites?.find((fS) => fS === s.id) === undefined
          );
        }
      });
    }
  }, [followedSites, isDrawing, sites, zone]);

  const handleOnConfirm = useCallback(() => {
    onSave();
    setModalVisible(false);
    if (unfollowedSitesInZones) {
      updateFollowedSites(unfollowedSitesInZones);
    }
  }, [onSave, setModalVisible, unfollowedSitesInZones, updateFollowedSites]);

  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      hardwareAccelerated
      transparent
    >
      <TouchableOpacity
        onPress={() => {
          setModalVisible(false);
        }}
        style={layerStyle}
        accessibilityLabel={t("modal.deleteAccount")}
      >
        <View style={panelStyle}>
          <BoxedLayout>
            <JText
              labelKey="modal.savePolygon"
              centered
              sizeName="mediumLarge"
              isBold
              titleLevel={2}
            />
            <Spacer height="mediumLarge" backgroundColor="transparent" />
            <JText
              labelKey="modal.savePolygonDescription"
              centered
              sizeName="mediumLarge"
              titleLevel={2}
            />
            <Spacer height="mediumLarge" backgroundColor="transparent" />
            <JText
              label={
                unfollowedSitesInZones && unfollowedSitesInZones?.length
                  ? t("modal.savePolygonFollow", {
                      number: unfollowedSitesInZones?.length,
                      wording:
                        unfollowedSitesInZones?.length > 1
                          ? getWording(configuration).plural
                          : getWording(configuration).singular,
                    })
                  : t("modal.savePolygonFollowNone")
              }
              centered
              sizeName="mediumLarge"
              titleLevel={2}
            />
            <Spacer height="mediumLarge" backgroundColor="transparent" />
            <View style={footerStyle}>
              <Button
                type={"gradient"}
                labelKey="common.confirm"
                onPress={handleOnConfirm}
              />
              <Spacer
                height="mediumLarge"
                width="mediumLarge"
                backgroundColor="transparent"
              />
              <Clickable onPress={() => setModalVisible(false)}>
                <JText
                  labelKey="common.cancel"
                  colorName={"emphasisText"}
                  isBold
                  centered
                />
              </Clickable>
            </View>
          </BoxedLayout>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
