import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackgroundLayout,
  Button,
  Card,
  Clickable,
  JText,
  JTextInput,
  SView,
  Spacer,
} from "../../components";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./LogInScreenStyle";
import JKeyboardAvoidingView from "components/common/JKeyboardAvoidingView/JKeyboardAvoidingView";
import { View } from "react-native";

type Props = StackScreenProps<ParamsParamsList, "logIn">;

export const LogInScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);

  const { signin, errorMessage } = useUser();

  const onSubmit = async () => {
    const res = await signin(email, password);

    setIsError(false);

    if (typeof res !== "boolean" && res.id) {
      navigation?.reset({
        index: 0,
        routes: [
          {
            name: "news",
          },
        ],
      });
    } else {
      setIsError(true);
    }
  };

  const onSignUpButtonPressed = () => {
    navigation.navigate("signUp");
  };

  const onForgotPasswordPressed = () => {
    navigation.navigate("resetPasswordEmail");
  };

  return (
    <BackgroundLayout
      style={{
        marginTop: theme.normalize(-30),
        paddingTop: theme.normalize(30),
      }}
    >
      <JKeyboardAvoidingView offset={-280}>
        <SView>
          <BoxedLayout style={styles.contentContainer}>
            <Card>
              <JText
                labelKey="auth.goToLogin"
                isBold
                centered
                titleLevel={2}
                titleLevelStyle={2}
              />
              <JTextInput
                value={email}
                onChangeValue={setEmail}
                placeHolder={`${t("common.email")}*`}
                icon="envelope"
                keyboardType="email-address"
                containerStyle={{
                  marginTop: theme.normalize(theme.sizings.mediumLarge),
                }}
                accessibilityLabel={t("common.email")}
                autocomplete="email"
              />
              <JTextInput
                value={password}
                onChangeValue={setPassword}
                inputType={"password"}
                icon="key"
                placeHolder={`${t("auth.password")}*`}
                containerStyle={{
                  marginTop: theme.normalize(theme.sizings.mediumLarge),
                }}
                accessibilityLabel={t("auth.password")}
                autocomplete="password"
              />

              {isError ? (
                <>
                  <Spacer height="medium" backgroundColor={"transparent"} />
                  <JText
                    labelKey={
                      errorMessage === "Need to reset password"
                        ? "auth.expirePasswordError"
                        : "auth.badPasswordOrEmailError"
                    }
                    colorName={"danger"}
                    sizeName="mediumLarge"
                    centered
                  />
                  <Spacer height="small" backgroundColor={"transparent"} />

                  <JText
                    labelKey={
                      errorMessage === "Need to reset password"
                        ? "auth.expirePasswordErrorInstruction"
                        : "auth.emailErrorSmall"
                    }
                    colorName={"dark"}
                    sizeName="average"
                    isItalic
                    centered
                    role="alert"
                  />
                  <Spacer height="medium" backgroundColor={"transparent"} />
                </>
              ) : (
                <></>
              )}

              <Clickable
                onPress={onForgotPasswordPressed}
                style={{
                  marginTop: theme.normalize(theme.sizings.smallMedium),
                }}
              >
                <JText
                  labelKey="auth.forgottenPassword"
                  sizeName={"mediumLarge"}
                />
              </Clickable>

              <Button
                type={"gradient"}
                labelKey="auth.logIn"
                onPress={onSubmit}
                containerStyle={styles.button}
              />
              <Clickable onPress={onSignUpButtonPressed}>
                <JText
                  labelKey="auth.noAccount"
                  colorName={"emphasisText"}
                  isBold
                  centered
                />
              </Clickable>
              <View>
                <JText labelKey="common.requiredFields" sizeName={"medium"} />
              </View>
            </Card>
          </BoxedLayout>
        </SView>
      </JKeyboardAvoidingView>
    </BackgroundLayout>
  );
};
