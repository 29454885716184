import { StyleSheet, TouchableWithoutFeedback } from "react-native";
import React, { useEffect } from "react";
import Animated, {
  interpolateColor,
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
  useDerivedValue,
} from "react-native-reanimated";
import { Types } from "types";
import { Icon } from "../icon";
import { ColorType, FontSizeType } from "theme/ThemeTypes";
import useTheme from "theme/ThemeProvider";

type Props = {
  value: boolean;
  onToggle: (boolean: any) => void;
  activeColor?: ColorType;
  inactiveColor?: ColorType;
  circleColor?: ColorType;
  iconName?: string;
  iconType?: Types["iconTypes"];
  iconSizeName?: FontSizeType;
  iconColorName?: ColorType;
  inactiveIconColorName?: ColorType;
  disabled?: boolean;
};

export const AnimatedSwitch: React.FC<Props> = ({
  value,
  onToggle,
  activeColor,
  inactiveColor,
  iconName,
  iconType,
  iconColorName = "white",
  iconSizeName = "mediumLarge",
  inactiveIconColorName = "grey",
  circleColor = "white",
  disabled = false,
}) => {
  const { theme } = useTheme();
  // value for Switch Animation
  const switchTranslate = useSharedValue(value ? 27 : 4);
  // value for Icon Animation
  const iconTranslate = useSharedValue(value ? 8 : 32);
  // Progress Value
  const progress = useDerivedValue(() => {
    return withTiming(value ? 27 : 0);
  });

  // useEffect for change the switchTranslate Value
  useEffect(() => {
    if (value) {
      switchTranslate.value = 27;
      iconTranslate.value = 8;
    } else {
      switchTranslate.value = 4;
      iconTranslate.value = 32;
    }
  }, [value, switchTranslate, iconTranslate]);

  // Circle Animation
  const customSpringStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: withSpring(switchTranslate.value, {
            mass: 1,
            damping: 15,
            stiffness: 120,
            overshootClamping: false,
            restSpeedThreshold: 0.001,
            restDisplacementThreshold: 0.001,
          }),
        },
      ],
    };
  });

  // Background Color Animation
  const backgroundColorStyle = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      progress.value,
      [0, 27],
      [
        theme.colors[inactiveColor ?? "blurBackground"],
        theme.colors[activeColor ?? "primary"],
      ]
    );
    return {
      backgroundColor,
    };
  });

  return (
    <TouchableWithoutFeedback
      disabled={disabled}
      onPress={() => onToggle(!value)}
    >
      <Animated.View style={[styles.container, backgroundColorStyle]}>
        <Animated.View
          style={[
            styles.circle,
            customSpringStyles,
            { backgroundColor: theme.colors[circleColor] },
          ]}
        />
        {iconName && iconType && (
          <Animated.View
            style={[{ position: "absolute", left: value ? 8 : 32 }]}
          >
            <Icon
              type={iconType}
              name={iconName}
              colorName={value ? iconColorName : inactiveIconColorName}
              sizeName={iconSizeName}
            />
          </Animated.View>
        )}
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 55,
    height: 28,
    borderRadius: 30,
    justifyContent: "center",
    backgroundColor: "#F2F5F7",
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: 30,
    backgroundColor: "white",
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.5,
    elevation: 4,
  },
});
