import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useMemo } from "react";
import { BackgroundLayout, Card, Icon, JText, Spacer } from "../../components";
import useTheme from "../../theme/ThemeProvider";
import useThread from "hooks/useThread";
import { useItem } from "hooks";
import { User } from "types/User";
import { ReactParamsList } from "screens/request/ReactParamsList";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { View } from "react-native";
import { displayPhoneNumber } from "utils/displayPhoneNumber";
import fnStyles from "./ChatContactProfileStyles";

type Props = StackScreenProps<ReactParamsList, "contactProfile">;

const EmptyCard = (styles: any) => {
  return (
    <Card style={styles.emptyCard}>
      <JText centered labelKey="chat.noContact" />
    </Card>
  );
};

export const ChatContactProfileScreen: React.FC<Props> = ({ route }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { thread, fetchThread } = useThread();

  useEffect(() => {
    if (
      route.params.threadId &&
      (!thread || thread.id !== route.params.threadId)
    ) {
      fetchThread(route.params.threadId);
    }
  }, [route.params.threadId, thread, fetchThread]);

  const { item: user } = useItem<User>("users", route.params.userId ?? "");

  const adminContact = useMemo(() => {
    if (thread && thread.site && thread.site.adminContact) {
      return thread.site.adminContact;
    } else if (
      thread &&
      thread.post &&
      thread.post.site &&
      thread.post.site.adminContact
    ) {
      return thread.post.site.adminContact;
    } else if (
      user &&
      user.id &&
      thread &&
      thread.messages?.find((m) => m.author.id === user.id)
    ) {
      return user;
    } else {
      return null;
    }
  }, [thread, user]);

  const adminContactInitials = useMemo(() => {
    const name = adminContact?.name.toUpperCase().split(" ");
    let avatarName = "";
    if (name) {
      if (name.length === 1) {
        avatarName = `${name[0].charAt(0)}`;
      } else if (name?.length > 1) {
        avatarName = `${name[0].charAt(0)}${name[1].charAt(0)}`;
      } else {
        avatarName = "";
      }
    }
    return avatarName;
  }, [adminContact]);

  return (
    <BackgroundLayout style={styles.layout}>
      <BoxedLayout style={styles.container}>
        {thread ? (
          adminContact ? (
            <View style={styles.contentContainer}>
              <View style={styles.avatar}>
                <JText
                  label={adminContactInitials}
                  colorName="white"
                  isBold
                  sizeName="high"
                  centered
                  style={styles.avatarText}
                />
              </View>
              <JText label={adminContact?.name} isBold />
              <JText labelKey={"chat.modo"} sizeName="mediumLarge" />
              <Spacer height="large" />
              {adminContact.email && (
                <Card style={styles.card}>
                  <View style={styles.cardRow}>
                    <Icon type="FontAwesome" name="envelope" colorName="info" />
                    <JText label={adminContact.email} />
                  </View>
                </Card>
              )}
              {adminContact.phoneNumber && (
                <Card style={styles.card}>
                  <View style={styles.cardRow}>
                    <Icon type="FontAwesome" name="phone" colorName="info" />
                    <JText
                      label={displayPhoneNumber(adminContact.phoneNumber)}
                    />
                  </View>
                </Card>
              )}
            </View>
          ) : (
            <EmptyCard styles={styles} />
          )
        ) : (
          <EmptyCard styles={styles} />
        )}
      </BoxedLayout>
    </BackgroundLayout>
  );
};
