import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import React from "react";
import useTheme from "../../../theme/ThemeProvider";

type Props = {
  onPress: () => void;
  hitBox?: number;
  hitBoxLeft?: number;
  hitBoxRight?: number;
  hitBoxTop?: number;
  hitBoxBottom?: number;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  selectable?: boolean;
  ariaSelected?: boolean;
  disableTabulationFocus?: boolean;
  expandable?: boolean;
  ariaExpanded?: boolean;
  children: React.ReactNode[] | React.ReactNode;
  id?: string;
  ariaLabel?: string;
  role?: any;
};

export const Clickable: React.FC<Props> = ({
  onPress,
  hitBox = 0,
  hitBoxLeft = 0,
  hitBoxRight = 0,
  hitBoxBottom = 0,
  hitBoxTop = 0,
  disabled = false,
  style = {},
  selectable = false,
  ariaSelected = false,
  expandable = false,
  ariaExpanded = false,
  disableTabulationFocus = false,
  children,
  id = undefined,
  ariaLabel,
  role = "button",
}: Props) => {
  const { theme } = useTheme();
  const styleToApply = StyleSheet.flatten(style) ?? {};

  if (hitBox) {
    styleToApply.padding = theme.normalize(hitBox);
  }
  if (hitBoxLeft) {
    styleToApply.paddingLeft = theme.normalize(hitBoxLeft);
  }
  if (hitBoxTop) {
    styleToApply.paddingTop = theme.normalize(hitBoxTop);
  }
  if (hitBoxRight) {
    styleToApply.paddingRight = theme.normalize(hitBoxRight);
  }
  if (hitBoxBottom) {
    styleToApply.paddingBottom = theme.normalize(hitBoxBottom);
  }

  return (
    <TouchableOpacity
      onPress={() => {
        if (!disabled) {
          onPress();
        }
      }}
      activeOpacity={disabled ? 0 : 0.7}
      style={styleToApply}
      accessibilityRole={disableTabulationFocus ? "none" : "button"}
      aria-label={ariaLabel}
      aria-selected={selectable ? ariaSelected : undefined}
      aria-current={ariaSelected}
      aria-expanded={expandable ? ariaExpanded : undefined}
      aria-hidden={disableTabulationFocus}
      aria-disabled={disabled}
      disabled={disabled}
      id={id}
      role={role}
    >
      {children}
    </TouchableOpacity>
  );
};
