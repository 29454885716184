import React from "react";
import { View } from "react-native";
import { Column, Icon, JImage, JText, Stack } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ThreadItemHeaderStyle";
import { Thread, ThreadType } from "../../types/ThreadType";
import { Site } from "../../types/Site";
import { useTranslation } from "react-i18next";
import getWording from "utils/wording";
import useConfiguration from "../../configuration/ConfigurationProvider";
import { SiteDistance } from "components/sites/SiteDistance";
import { useLocation } from "hooks";
import useSites from "hooks/useSites";

type Props = {
  data: Thread | Site;
  isSite?: boolean;
};

export const ThreadItemHeader: React.FC<Props> = ({ data, isSite = false }) => {
  const { theme } = useTheme();
  const { configuration } = useConfiguration();
  const { t } = useTranslation();
  const { locationData } = useLocation();
  const { filters } = useSites();

  let item: {
    title: string;
    address: string;
    image: string;
    type?: ThreadType;
    geojson?: {
      lat: number;
      lng: number;
    };
    isGeo?: boolean;
  } = {
    title: "",
    address: "",
    image: "",
    type: undefined,
  };
  if (isSite) {
    const tmp = data as Site;
    item.title = tmp?.name;
    item.address = tmp.location ?? "";
    item.image = tmp.logo ? tmp.logo.url : tmp.mainImage?.url ?? "";
    item.type = undefined;
    item.geojson = tmp.geojson;
    item.isGeo = tmp.siteType?.isGeo;
  } else {
    const tmp = data as Thread;
    item.title = tmp.post
      ? `${tmp?.site?.name} - ${tmp.post?.title}`
      : tmp?.site?.name;
    item.address = tmp.post
      ? tmp.post?.location ?? ""
      : tmp.site?.location ?? "";
    item.image = tmp.post
      ? tmp.post?.mainImage?.url ??
        tmp.site?.logo?.url ??
        tmp.site?.mainImage?.url ??
        ""
      : tmp.site?.logo?.url ?? tmp.site?.mainImage?.url ?? "";
    item.type = tmp.type ?? undefined;
  }
  const styles = fnStyles(theme);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Stack
          containerStyle={{
            marginRight: theme.sizings.small,
          }}
          frontView={
            item.type?.imageDarkMode && (
              <JImage
                source={item.type?.imageDarkMode?.url}
                type={"Uri"}
                style={[styles.iconImage]}
              />
            )
          }
          frontViewStyle={[
            styles.typeIcon,
            {
              backgroundColor: item.type?.mainColor,
              position: item.image ? "absolute" : "relative",
              top: item.image ? -5 : 0,
              right: item.image ? 5 : 0,
              marginRight: item.image ? 0 : 5,
            },
          ]}
          backView={
            item.image ? (
              <JImage
                source={item.image}
                type={"Uri"}
                style={styles.siteImage}
              />
            ) : (
              <></>
            )
          }
        />
        <Column style={styles.column}>
          <JText
            label={
              item.title ??
              t("chat.noSiteSelected", {
                wording: getWording(configuration).singular,
              })
            }
            sizeName="mediumLarge"
            isBold
            titleLevel={2}
          />
          <JText label={item.address} sizeName="mediumLarge" />
        </Column>

        {isSite &&
        item?.isGeo &&
        item?.geojson?.lat &&
        (filters?.location?.lat || locationData?.lat) ? (
          <SiteDistance
            siteLocation={item?.geojson}
            style={{ marginLeft: theme.sizings.smallMedium }}
          />
        ) : null}

        <Icon
          name="chevron-right"
          type="Entypo"
          colorName="grey"
          style={styles.icon}
        />
      </View>
    </View>
  );
};
