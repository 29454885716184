import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";
import hexToRgba from "hex-to-rgba";
import { ThreadType } from "types/ThreadType";

export default (theme: Theme, type: ThreadType) =>
  StyleSheet.create({
    container: {
      width: "30%",
      marginBottom: theme.normalize(theme.sizings.mediumLarge),
    },
    contentContainer: {
      alignItems: "center",
    },
    card: {
      padding: theme.normalize(20),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.normalize(15),
      backgroundColor: theme.colors.white,
      borderColor: hexToRgba(type.mainColor, 0.2),
      borderWidth: 2,
    },
    iconImage: {
      width: theme.normalize(60),
      height: theme.normalize(60),
    },
    label: {
      marginTop: theme.normalize(theme.sizings.medium),
    },
  });
