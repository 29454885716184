import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isHorizontalMode: boolean) =>
  StyleSheet.create({
    container: {
      maxWidth: isHorizontalMode ? theme.layout.containerMaxWidth : undefined,
      marginHorizontal: isHorizontalMode ? "auto" : undefined,
      width: "100%",
    },
  });
