import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isHorizontalMode: boolean, width?: number) =>
  StyleSheet.create({
    contentContainer: {
      paddingHorizontal: theme.normalize(theme.sizings.medium),
    },
    noOrgaLabel: {
      marginBottom: theme.normalize(theme.sizings.smallMedium),
    },
    positionSectionLabel: {},
    notificationSectionLabel: {
      marginBottom: theme.normalize(theme.sizings.smallMedium),
    },
    accountSectionLabel: {
      marginBottom: theme.normalize(theme.sizings.medium),
    },
    accountDescription: {
      letterSpacing: 0.6,
      marginBottom: theme.normalize(theme.sizings.medium),
    },
    accountEmail: {
      letterSpacing: 0.6,
      marginBottom: theme.normalize(theme.sizings.small),
    },
    button: {
      position: "absolute",
      bottom: !isHorizontalMode
        ? theme.normalize(theme.layout.navBottomHeight + 16)
        : theme.normalize(16),
      right: theme.normalize(16),
    },
    buttonLabel: {
      marginLeft: theme.normalize(theme.sizings.large),
      marginRight: theme.normalize(theme.sizings.mediumLarge),
    },
    newButton: {
      marginTop: theme.normalize(theme.sizings.medium),
      alignSelf: "center",
    },
    infosButton: {
      marginTop: theme.sizings.medium,
      alignSelf: "center",
    },
    deleteButton: {
      marginTop: theme.sizings.mediumLarge,
    },
    pin: {
      marginLeft: theme.normalize(-5),
      marginRight: theme.sizings.mediumLarge,
      top: theme.sizings.tiny / 2,
      width: theme.sizings.mediumLarge,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    shouldLogin: {
      //marginTop: theme.normalize(theme.sizings.mediumLarge),
      alignItems: "center",
    },
    dropDownMenuCard: {
      zIndex: 2,
    },
    card: {
      zIndex: 1,
    },
    editMyAccount: {
      backgroundColor: theme.colors.infoLight,
      position: "absolute",
      top: -theme.sizings.small,
      right: -theme.sizings.small,
      borderRadius: theme.normalize(22),
      minWidth: theme.layout.minimalAccessible,
      height: theme.layout.minimalAccessible,
      justifyContent: "center",
      alignItems: "center",
    },
    textInfos: {
      flex: 1,
      paddingRight: theme.sizings.mediumLarge,
      width: "80%",
    },
    map: {
      width: "100%",
      height: isHorizontalMode ? (width ?? 0) * 0.4 : (width ?? 0) * 0.8,
      backgroundColor: theme.colors.greyLight,
      marginVertical: theme.normalize(theme.sizings.medium),
      borderRadius: theme.radius.medium,
      overflow: "hidden",
    },
  });
