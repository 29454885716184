import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";
import hexToRgba from "hex-to-rgba";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: hexToRgba(theme.colors.white, 0.3),
      paddingHorizontal: theme.normalize(theme.sizings.smallMedium),
      paddingBottom: theme.normalize(theme.sizings.smallMedium),
      alignItems: "center",
      width: "100%",
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      paddingBottom: theme.normalize(theme.sizings.smallMedium),
    },
    rowLeft: {
      paddingRight: theme.normalize(theme.sizings.smallMedium),
    },
    date: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
    },
    horizontal: {
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: theme.sizings.small,
      paddingVertical: theme.sizings.tiny,
      borderRadius: theme.radius.medium,
      backgroundColor: theme.colors.tinyElementsBackground,
    },
    attachment: {
      marginRight: theme.sizings.small,
    },
  });
