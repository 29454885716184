import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ClickableScreen from "./ClickableScreen";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import IconScreen from "./IconScreen";
import AnnotatedTextScreen from "./AnnotatedTextScreen";
import ButtonScreen from "./ButtonScreen";
import CheckboxScreen from "./CheckboxScreen";
import ColumnScreen from "./ColumnScreen";
import DividerScreen from "./DividerScreen";
import ExpandableScreen from "./ExpandableScreen";
import ImageScreen from "./ImageScreen";
import RepeatableScreen from "./RepeatableScreen";
import RowScreen from "./RowScreen";
import ScrollableScreen from "./ScrollableScreen";
import SpacerScreen from "./SpacerScreen";
import StackScreen from "./StackScreen";
import TextScreen from "./TextScreen";
import TextInputScreen from "./TextInputScreen";
import SwitchScreen from "./SwitchScreen";
import TileScreen from "./TileScreen";
import CardScreen from "./CardScreen";

const Tab = createMaterialTopTabNavigator();

export const CatalogScreen = () => {
  const insets = useSafeAreaInsets();

  return (
    <Tab.Navigator
      initialRouteName="AnnotatedText"
      screenOptions={{
        tabBarStyle: {
          backgroundColor: "white",
          marginTop: insets.top,
        },
        tabBarLabelStyle: {
          fontSize: 12,
        },
        tabBarScrollEnabled: true,
      }}
    >
      <Tab.Screen name="AnnotatedText" component={AnnotatedTextScreen} />
      <Tab.Screen name="Button" component={ButtonScreen} />
      <Tab.Screen name={"Card"} component={CardScreen} />
      <Tab.Screen name="Checkbox" component={CheckboxScreen} />
      <Tab.Screen name="Clickable" component={ClickableScreen} />
      <Tab.Screen name="Column" component={ColumnScreen} />
      <Tab.Screen name="Divider" component={DividerScreen} />
      <Tab.Screen name="Expandable" component={ExpandableScreen} />
      <Tab.Screen name="Icon" component={IconScreen} />
      <Tab.Screen name="Image" component={ImageScreen} />
      <Tab.Screen name="Repeatable" component={RepeatableScreen} />
      <Tab.Screen name="Row" component={RowScreen} />
      <Tab.Screen name="Scrollable" component={ScrollableScreen} />
      <Tab.Screen name="Spacer" component={SpacerScreen} />
      <Tab.Screen name="Stack" component={StackScreen} />
      <Tab.Screen name={"Switch"} component={SwitchScreen} />
      <Tab.Screen name="Text" component={TextScreen} />
      <Tab.Screen name="TextInput" component={TextInputScreen} />
      <Tab.Screen name="Tile" component={TileScreen} />
    </Tab.Navigator>
  );
};
