import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Row } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 190,
    marginTop: 16,
  },
  item: {
    backgroundColor: "red",
    color: "white",
    width: "50%",
    height: 100,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
});

const RowScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Row"
        description="Display the children of the component in a horizontal alignment."
      >
        <Row>
          <View style={innerStyles.item}>
            <Text>Item 1</Text>
          </View>
          <View style={innerStyles.item}>
            <Text>Item 2</Text>
          </View>
        </Row>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<Row>\n" +
            "  <View style={innerStyles.item}>\n" +
            "    <Text>Item 1</Text>\n" +
            "  </View>\n" +
            "  <View style={innerStyles.item}>\n" +
            "    <Text>Item 2</Text>\n" +
            "  </View>\n" +
            "</Row>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="style?"
        description="The style of the component."
        type="StyleProp<ViewStyle>"
        topDivider={false}
      />
      <CatalogProps
        propName="justify?"
        description="The alignment of the items in the x axis."
        type='"flex-start" | "flex-end" | "center" | "stretch" | "baseline"'
      />
      <CatalogProps
        propName="align?"
        description="The alignment of the items in the y axis."
        type='"left" | "right" | "top" | "bottom" | "center"'
      />
      <CatalogProps
        propName="safe?"
        description="Whether the container should be a SafeAreaView or not. (default to false)"
        type="boolean"
      />
    </ScrollView>
  );
};

export default RowScreen;
