import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";
import fnStyles from "./NotchStyle";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType } from "theme/ThemeTypes";

type Props = {
  color?: string;
  colorName?: ColorType;
  style?: StyleProp<ViewStyle>;
};

export const Notch: React.FC<Props> = ({
  color,
  colorName = "primary",
  style = {},
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: color ? color : theme.colors[colorName] },
        style,
      ]}
    />
  );
};
