import React, { useMemo } from "react";
import useTheme from "../../../theme/ThemeProvider";
import { Platform, View } from "react-native";
import Map from "../../../components/jlib/map/Map";
import useSites from "hooks/useSites";
import useConfiguration from "../../../configuration/ConfigurationProvider";
import { Points } from "types/Site";

export const SectionMap: React.FC = () => {
  const { theme } = useTheme();
  const { sites } = useSites();
  const { configuration } = useConfiguration();

  const points = useMemo(() => {
    return sites
      ?.filter((item) => item.siteType?.isGeo && item.geojson?.lat)
      .map((item) => {
        return {
          id: item.id,
          lat: item.geojson?.lat,
          lng: item.geojson?.lng,
          name: item.name,
          isCustomMarkerSelected: item?.isCustomMarkerSelected,
          customMarkerSelected: item?.customMarkerSelected,
        } as Points;
      });
  }, [sites]);

  return (
    <View style={{ marginTop: theme.normalize(-40) }}>
      <Map
        points={points}
        fullHeight
        zoomLvl={
          Platform.OS === "web"
            ? configuration.sites.map.defaultZoomLevel || 10
            : configuration.sites.map.defaultMobileZoomLevel || 10
        } // used if geolocation
        mapSearchAndDrawEnabled
      />
    </View>
  );
};
