import { ThemeConfigOverride } from "./ThemeTypes";

const specificProperties: ThemeConfigOverride = {
  colors: {
    tabActiveText: "#41404F",
    tabActiveBackground: "#FFC627",
    gradientStart: "#FFC627",
    gradientEnd: "#FFC627",
    gradientText: "#41404F",
    headerText: "#ffffff",
    headerBackground: "#1C9CD9",
    emphasisText: "#1C9CD9",
    rootMenuText: "#1C9CD9",
    tinyElementsText: "#41404F",
    tinyElementsBackground: "#FFC627",
  },
  images: {
    logo: require("assets/fulchiron/logo.png"),
    background: require("assets/fulchiron/background.jpg"),
    introStep1: require("assets/fulchiron/introStep1.png"),
    introStep2: require("assets/fulchiron/introStep2.jpg"),
    introStep3: require("assets/fulchiron/introStep3.jpg"),
    pictoBubble: require("assets/fulchiron/picto-bubbles.png"),
    pictoClipboard: require("assets/fulchiron/picto-clipboard.png"),
    pictoSearch: require("assets/fulchiron/picto-search.png"),
  },
  texts: {
    appName: "Fulchiron",
    icons: {
      introStep1Icon: "handshake-o",
      introStep2Icon: "user-circle-o",
      introStep3Icon: "comments-o",
      introStep1IconType: "FontAwesome",
      introStep2IconType: "FontAwesome",
      introStep3IconType: "FontAwesome",
    },
    fr: {
      introStep1Title:
        "Retrouvez ici la communication interne de FULCHIRON (QS, Environnement, RSE, RH…) ainsi que des actualités de vos sites",
      introStep2Title:
        "Créez votre compte avec votre email personnel puis sélectionnez les catégories d’information qui vous intéressent. Vous serez alors notifié-e en cas de nouvelles publications.",
      introStep3Title:
        "Vous avez des questions, vous souhaitez réagir à une publication, vous pouvez échanger en privé avec le service directement concerné depuis l’application.",
      paramAlertTitle: "Perturbations",
      paramEventTitle: "Événements",
      paramInfoTitle: "Informations",
      paramAlertLegend: "Communication importante",
      paramEventLegend: "Événements de l'entreprise",
      paramInfoLegend: "Informations diverses",
    },
  },
};

export default specificProperties;
