import { isHorizontalMode } from "components/utils/deviceMode";
import useSites from "hooks/useSites";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import { Clickable, DateDisplay, JImage, JText } from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "../PostInformationScreenStyle";
import { useTranslation } from "react-i18next";
import BadgeDemo from "components/common/badgeDemo.tsx/BadgeDemo";

type Props = {
  post: any;
  fromSite: boolean;
  navigation: any;
};

export const SectionIntro: React.FC<Props> = ({
  post,
  fromSite,
  navigation,
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { setSite } = useSites();
  const { t } = useTranslation();

  const styles = fnStyles(theme, width, isHorizontalMode({ width, height }));

  return (
    <>
      {post.mainImage && (
        <JImage source={post.mainImage.url} type={"Uri"} style={styles.image} />
      )}
      <View
        style={[
          styles.content,
          {
            marginTop:
              !isHorizontalMode({ width, height }) && !post.mainImage
                ? theme.normalize(30)
                : theme.normalize(5),
          },
        ]}
      >
        <View style={styles.header}>
          <View style={styles.siteImageTitle}>
            <View style={styles.siteLink}>
              {(post.site.logo || post.site.mainImage) && (
                <Clickable
                  onPress={() => {
                    setSite(post.site);
                    navigation.navigate("siteDetails", {
                      siteId: post.site.id,
                    });
                  }}
                  disabled={fromSite}
                >
                  <JImage
                    type="Uri"
                    source={
                      post.site.logo
                        ? post.site.logo.url
                        : post.site.mainImage
                        ? post.site.mainImage.url
                        : ""
                    }
                    style={styles.siteImage}
                    alt={t("titles.illustrationOf", { title: post.title })}
                  />
                </Clickable>
              )}
            </View>
            <View style={styles.title}>
              {post.site?.isDraft && <BadgeDemo />}

              <JText
                label={post.title}
                isBold
                sizeName={"largeLow"}
                role="heading"
                titleLevel={2}
                titleLevelStyle={1}
              />
              <JText label={post.site?.name} sizeName={"mediumLarge"} />
            </View>
            <View style={[styles.siteLink]}>
              <JImage
                source={post.type?.imageLightMode?.url || ""}
                type={"Uri"}
                style={styles.siteImage}
              />
            </View>
          </View>

          {Boolean(post.type.shouldHavePlanning) && (
            <DateDisplay
              startDate={new Date(post.startEventDate!)}
              endDate={new Date(post.endEventDate!)}
              type={"large"}
              style={styles.date}
            />
          )}
        </View>
      </View>
    </>
  );
};
