import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";
import fnStyles from "./StackStyle";

type OverlayContainerProps = {
  backView?: React.ReactNode | React.ReactNode[];
  frontView?: React.ReactNode | React.ReactNode[];
  backViewStyle?: StyleProp<ViewStyle>;
  frontViewStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
};

export const Stack: React.FC<OverlayContainerProps> = ({
  backView,
  frontView,
  backViewStyle,
  frontViewStyle,
  containerStyle,
}) => {
  const styles = fnStyles();

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={backViewStyle}>{backView}</View>
      <View style={[styles.front, frontViewStyle]}>{frontView}</View>
    </View>
  );
};
