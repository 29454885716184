import { Platform, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    containerFilters: {
      height: Platform.OS === "web" ? 60 : 60,
      backgroundColor: theme.colors.headerBackground,
      paddingHorizontal: theme.normalize(12),
      paddingTop: theme.normalize(4),
      paddingBottom: theme.normalize(4),
      borderBottomLeftRadius: theme.normalize(theme.radius.mediumLarge),
      borderBottomRightRadius: theme.normalize(theme.radius.mediumLarge),
      zIndex: 1,
    },
    field: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: theme.colors.blurBackground,
      borderRadius: theme.normalize(theme.radius.small),
      paddingLeft: theme.normalize(12),
      height: theme.normalize(40),
    },
    fieldMap: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: theme.colors.blurBackground,
      borderRadius: theme.normalize(theme.radius.small),
      paddingLeft: theme.normalize(12),
      height: theme.normalize(40),
      zIndex: 2,
    },
    inputButton: {
      height: theme.normalize(40),
      width: theme.normalize(40),
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.greyLight,
      borderTopRightRadius: theme.normalize(theme.radius.small),
      borderBottomRightRadius: theme.normalize(theme.radius.small),
    },
    cross: {
      alignSelf: "center",
      marginRight: theme.normalize(4),
    },
    modalCenterContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      width: "85%",
      margin: theme.normalize(20),
      backgroundColor: "white",
      borderRadius: theme.normalize(20),
      padding: theme.normalize(35),
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalRow: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: theme.normalize(20),
    },
    fieldMapWeb: {
      width: "60%",
      alignSelf: "center",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: theme.colors.white,
      borderRadius: theme.normalize(theme.radius.medium),
      paddingLeft: theme.normalize(12),
      height: theme.normalize(40),
      zIndex: 2,
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.15,
      shadowRadius: 6.27,
      elevation: 10,
    },
    inputButtonWeb: {
      height: theme.normalize(40),
      width: theme.normalize(40),
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.white,
      borderTopRightRadius: theme.normalize(theme.radius.medium),
      borderBottomRightRadius: theme.normalize(theme.radius.medium),
    },
    textInputContainer: {
      backgroundColor: theme.colors.blurBackground,
      height: theme.normalize(40),
    },
    textInputContainerWeb: {
      backgroundColor: theme.colors.blurBackground,
      height: theme.normalize(40),
      outlineStyle: "none",
    },
    textInput: {
      height: theme.normalize(40),
      backgroundColor: theme.colors.blurBackground,
      color: theme.colors.dark,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    textInputWeb: {
      height: theme.normalize(40),
      backgroundColor: theme.colors.blurBackground,
      color: theme.colors.dark,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      outlineStyle: "none",
    },
  });
