import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isHorizontalMode: boolean, width: number) =>
  StyleSheet.create({
    container: {
      flexDirection: "column",
      marginTop: isHorizontalMode ? 0 : theme.normalize(-30),
    },
    backButton: {
      marginTop: 15,
      marginBottom: 10,
      flexDirection: "row",
      alignItems: "center",
    },
    leftWeb: {
      flex: 1,
      backgroundColor: theme.colors.white,
      maxWidth: width * 0.35,
    },
    button: {
      position: "absolute",
      /*bottom: !isHorizontalMode
        ? theme.normalize(theme.layout.navBottomHeight + 16)
        : theme.normalize(16),*/
      bottom: theme.normalize(16),
      right: theme.normalize(16),
    },
    buttonLabel: {
      marginLeft: theme.normalize(theme.sizings.large),
      marginRight: theme.normalize(theme.sizings.mediumLarge),
    },
  });
