import React, { useRef, useState } from "react";
import {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
  Styles,
} from "react-native-google-places-autocomplete";
import { Clickable } from "../clickable";
import { Icon } from "../icon";
import useTheme from "theme/ThemeProvider";
import fnStyles from "./GMapLocationSearchBarStyles";
import { Linking, Modal, Platform, View } from "react-native";
import { JText } from "../text";

type Props = {
  placeholder?: string;
  requestUrl: string;
  queryKey: string;
  onPress?: (data: GooglePlaceData, detail: GooglePlaceDetail | null) => void;
  renderRightButton?: boolean;
  onPressRightButton?: () => void;
  renderRightPositionButton?: boolean;
  onPressRenderRightPositionButton?: () => void;
  styles?: Object | Partial<Styles>;
  gmapsStyles?: Object | Partial<Styles>;
};

export const GMapLocationSearchBar = ({
  placeholder = "Rechercher autour de ...",
  requestUrl,
  onPress,
  renderRightButton,
  onPressRightButton = () => {},
  renderRightPositionButton,
  onPressRenderRightPositionButton = () => {},
  queryKey,
  styles,
  gmapsStyles,
}: Props) => {
  const { theme } = useTheme();
  const jStyles = fnStyles(theme, Platform.OS);

  const ref = useRef<GooglePlacesAutocompleteRef>(null);

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

  return (
    <View style={{ ...jStyles.fieldMap, ...styles }}>
      <Icon type="IonIcons" name="map" colorName="grey" />
      <GooglePlacesAutocomplete
        placeholder={placeholder}
        // @ts-ignore
        ref={ref}
        fetchDetails
        requestUrl={{
          useOnPlatform: "all",
          url: requestUrl,
        }}
        enablePoweredByContainer={false}
        // @ts-ignore
        renderRightButton={(): React.ReactElement | null =>
          renderRightButton && ref?.current?.getAddressText()?.length ? (
            <Clickable
              style={jStyles.cross}
              onPress={() => {
                ref?.current?.setAddressText("");
                ref?.current?.clear();
                ref?.current?.focus();
                onPressRightButton();
              }}
              role="button"
              ariaLabel="Effacer"
            >
              <Icon type="Entypo" name="cross" colorName="grey" />
            </Clickable>
          ) : null
        }
        onPress={onPress}
        textInputProps={{
          placeholderTextColor: theme.colors.dark,
          selectionColor: theme.colors.dark,
          accessibilityLabel: "Rechercher autour de...",
          "aria-label": "Rechercher autour de...",
        }}
        query={{
          key: queryKey,
          language: "fr",
          components: "country:fr",
        }}
        styles={{ ...jStyles, ...gmapsStyles }}
      />
      {renderRightPositionButton && (
        <Clickable
          onPress={() => {
            if (Platform.OS !== "web") {
              setIsSettingsModalVisible(true);
            }
            onPressRenderRightPositionButton();
          }}
          style={jStyles.inputButton}
          role="button"
          ariaLabel="Me géolocaliser"
        >
          <Icon
            type="MaterialCommunity"
            name="map-marker-radius"
            colorName="infoMedium"
            sizeName="largeHigh"
          />
        </Clickable>
      )}
      <Modal
        visible={isSettingsModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setIsSettingsModalVisible(false)}
      >
        <View style={jStyles.modalCenterContainer}>
          <View style={jStyles.modalView}>
            <JText
              label="L'accès à votre position est désactivé. Voulez-vous l'activer ?"
              centered
            />
            <View style={jStyles.modalRow}>
              <Clickable onPress={() => setIsSettingsModalVisible(false)}>
                <JText label="Annuler" isBold colorName="secondary" />
              </Clickable>
              <Clickable
                onPress={() => {
                  Linking.openSettings();
                  setIsSettingsModalVisible(false);
                }}
              >
                <JText label="Activer" isBold colorName="info" />
              </Clickable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};
