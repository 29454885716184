import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    contentContainer: {
      paddingHorizontal: theme.normalize(theme.sizings.medium),
      marginTop: theme.normalize(30),
      minHeight: "100%",
    },
    button: {
      marginVertical: theme.normalize(theme.sizings.mediumLarge),
      marginTop: theme.normalize(theme.sizings.larger),
      width: "70%",
      alignSelf: "center",
    },
    bottomButton: {
      marginBottom: theme.normalize(theme.sizings.medium),
      width: "70%",
      alignSelf: "center",
    },
  });
