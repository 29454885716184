import React, { useState } from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { Clickable } from "../clickable";
import { Icon } from "../icon";
import Collapsible from "react-native-collapsible";
import { I18nKey } from "../../../../i18n";
import { FontSizeType } from "../../../theme";
import { Types } from "../../../types";
import { Row } from "../row";
import { JText } from "../text";

type Props = {
  title?: string;
  titleKey?: I18nKey;
  titleSize?: FontSizeType;
  titleFontWeight?: Types["fontWeights"];
  titleStyle?: StyleProp<TextStyle>;
  titleComponent?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  style?: StyleProp<ViewStyle>;
  rowStyle?: StyleProp<ViewStyle>;
  collapsibleStyle?: StyleProp<ViewStyle>;
  defaultCollapsedValue?: boolean;
};

export const Expandable: React.FC<Props> = ({
  title,
  titleKey,
  titleSize = "mediumLarge",
  titleFontWeight = "600",
  titleStyle = {},
  titleComponent = null,
  children,
  style = {},
  rowStyle = {},
  collapsibleStyle = {},
  defaultCollapsedValue = true,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsedValue);

  return (
    <View style={style}>
      <Clickable
        onPress={() => {
          setCollapsed(!collapsed);
        }}
      >
        <Row justify={"space-between"} align={"center"} style={[rowStyle]}>
          {titleComponent ? (
            titleComponent
          ) : (
            <JText
              label={title}
              labelKey={titleKey}
              sizeName={titleSize}
              fontWeight={titleFontWeight}
              style={titleStyle}
            />
          )}
          <Icon
            type={"AntDesign"}
            name={collapsed ? "down" : "up"}
            colorName={"grey"}
            sizeName={"mediumLarge"}
          />
        </Row>
      </Clickable>
      {/* @ts-ignore */}
      <Collapsible style={collapsibleStyle} collapsed={collapsed}>
        {children}
      </Collapsible>
    </View>
  );
};
