import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
    },
    text: {
      width: "80%",
      letterSpacing: 0,
      marginTop: theme.normalize(20),
    },
    button: {
      marginTop: theme.normalize(50),
    },
    image: {
      marginTop: theme.normalize(20),
      width: theme.normalize(100),
      height: theme.normalize(100),
    },
  });
