import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isHighlighted: boolean) =>
  StyleSheet.create({
    p: {
      fontSize: theme.fontSizes.mediumLarge,
      margin: 0,
      color: isHighlighted ? theme.colors.white : theme.colors.dark,
      backgroundColor: "transparent",
    },
    link: {
      color: isHighlighted ? theme.colors.white : theme.colors.infoDark,
    },
  });
