import React, { createContext, useCallback, useEffect, useState } from "react";
import { Organization } from "types/Organization";
import { REACT_APP_ORGANIZATION_ID } from "../constants/Constants";
import fetchJSON from "../utils/fetchJSON";
//import useFetch from "../hooks/useFetch";

type AuthOrga = {
  organization?: Organization;
  setOrganization?: any;
  refreshOrganization: (organizationId: any) => Promise<Organization>;
  isFetching: boolean;
};

export const OrganizationContext = createContext<AuthOrga>({} as AuthOrga);

type Props = {
  children: React.ReactNode;
};

export function OrganizationProvider({ children }: Props) {
  const [organization, setOrganization] = useState<Organization>();
  // To force 'parlonstravaux' on dev server, set REACT_APP_ORGANIZATION_ID = 1 in .env
  const [isFetching, setIsFetching] = useState(false);

  const refreshOrganization = useCallback(
    async (organizationId: any) => {
      const organizationIdToFetch = REACT_APP_ORGANIZATION_ID || organizationId;

      try {
        setIsFetching(true);

        if (organizationIdToFetch) {
          const res = await fetchJSON({
            url: `organizations/data/${organizationIdToFetch}`,
            method: "GET",
          });

          if (res) {
            setOrganization(res);

            return res;
          }
        } else {
          setOrganization(undefined);
        }

        return false;
      } catch (e) {
        console.error(e);
        // if users/me is not accessible then the token expired
        return false;
      } finally {
        setIsFetching(false);
      }
    },
    [setOrganization]
  ); // pushToken

  useEffect(() => {
    const firstLoad = async () => {
      if (REACT_APP_ORGANIZATION_ID) {
        await refreshOrganization(Number(REACT_APP_ORGANIZATION_ID));
      }
    };

    firstLoad();
  }, [refreshOrganization]);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        setOrganization,
        refreshOrganization,
        isFetching,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}
