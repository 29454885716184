import React from "react";
import { Text } from "react-native";
import styles from "./CatalogStyle";

type Props = {
  label: string;
};

export const CatalogItemTitle = (props: Props) => {
  return <Text style={styles.catalogItemTitle}>{props.label}</Text>;
};
