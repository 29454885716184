import React from "react";
import { StyleProp, ViewStyle, View } from "react-native";
import useTheme from "../../../theme/ThemeProvider";

type Props = {
  times?: number;
  direction?: "column" | "row" | "row-reverse" | "column-reverse";
  spacing?: number;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  children: React.ReactNode;
};

export const Repeatable: React.FC<Props> = ({
  times = 1,
  direction = "row",
  spacing = 0,
  style = {},
  containerStyle = {},
  children,
}: Props) => {
  const { theme } = useTheme();

  return (
    <View style={[{ flexDirection: direction }, containerStyle]}>
      {[...Array(times)].map((e, i) => {
        return (
          <View
            key={i}
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              direction === "row" && {
                marginRight: i === times - 1 ? 0 : theme.normalize(spacing),
              },
              // eslint-disable-next-line react-native/no-inline-styles
              direction === "column" && {
                marginBottom: i === times - 1 ? 0 : theme.normalize(spacing),
              },
              // eslint-disable-next-line react-native/no-inline-styles
              direction === "row-reverse" && {
                marginLeft: i === times - 1 ? 0 : theme.normalize(spacing),
              },
              // eslint-disable-next-line react-native/no-inline-styles
              direction === "column-reverse" && {
                marginTop: i === times - 1 ? 0 : theme.normalize(spacing),
              },
              style,
            ]}
          >
            {children}
          </View>
        );
      })}
    </View>
  );
};
