import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      height: theme.normalize(5),
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      width: theme.normalize(50),
    },
  });
