import { useState, useEffect, useCallback } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const usePersistedState = (
  initialValue: any,
  key: string,
  nullable: boolean = false
) => {
  const [storedValue, setStoredValue] = useState(
    nullable ? null : initialValue
  );

  const valueIsArray = Array.isArray(nullable ? null : initialValue);

  useEffect(() => {
    const getStoredValue = async () => {
      try {
        let value = await AsyncStorage.getItem(key);
        if (value !== null) {
          let v = JSON.parse(value);
          if (valueIsArray && !Array.isArray(JSON.parse(value))) {
            v = [];
          }
          setStoredValue(v);
        } /* else if (nullable) {
          setStoredValue(initialValue);
        } */
      } catch (error) {
        // Handle error (e.g., unable to retrieve data from storage)
        console.error("Error retrieving data from AsyncStorage:", error);
      }
    };

    getStoredValue();
  }, [/* initialValue, */ key, nullable, valueIsArray]);

  const setValue = useCallback(
    async (value: any) => {
      try {
        let v;
        setStoredValue(value);
        if (typeof value === "function") {
          v = value(storedValue);
        } else {
          v = value;
        }
        await AsyncStorage.setItem(key, JSON.stringify(v));
      } catch (error) {
        // Handle error (e.g., unable to store data in AsyncStorage)
        console.error("Error storing data in AsyncStorage:", error);
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
};

export default usePersistedState;
