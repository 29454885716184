import React, { FC /*, useMemo*/ } from "react";
import { View, useWindowDimensions } from "react-native";
import fnStyles from "./TabBarItemStyle";
import useTheme from "../../../theme/ThemeProvider";
import { Types } from "../../../types";
import { Icon, JText } from "../../jlib";
import getWording from "../../../utils/wording";
import useConfiguration from "./../../../configuration/ConfigurationProvider";
import { isHorizontalMode } from "components/utils/deviceMode";
/*import BadgeController from "components/jlib/badgeController/BadgeController";
import { useList, useUser } from "hooks";
import { Thread } from "types/ThreadType";
import { Message } from "types/Message";*/

type Props = {
  iconName: string;
  isCurrent?: boolean;
};

export const TabBarItem: FC<Props> = ({ iconName, isCurrent }: Props) => {
  const { width, height } = useWindowDimensions();
  const isHorizontal = isHorizontalMode({ width, height });
  const { theme } = useTheme();
  const { configuration } = useConfiguration();
  // const { user } = useUser();

  const styles = fnStyles(theme, isHorizontal);
  let icon = "";
  let iconType: Types["iconTypes"] = "AntDesign";
  let valuesKey = {};

  // threads
  // const { items: threads } = useList<Thread>("threads/me");

  /*const newMessages = useMemo(() => {
    let total = 0;
    threads?.forEach((t: Thread) => {
      t.messages.forEach((m: Message) => {
        if (!m.isRead) {
          total += 1;
        }
      });
    });
    return total;
  }, [threads]);*/

  switch (iconName) {
    case "sites":
      icon = "map-search-outline";
      iconType = "MaterialCommunity";
      valuesKey = { wording: getWording(configuration).pluralCapitalize };
      break;
    case "news":
      icon = "newspaper-variant-outline";
      iconType = "MaterialCommunity";
      break;
    case "followedSites":
      icon = "pin-outline";
      iconType = "MaterialCommunity";
      break;
    case "chat":
      icon = "chatbubbles-outline";
      iconType = "IonIcons";
      break;
    case "params":
      iconType = "IonIcons";
      icon = "settings-outline";
      break;
    default:
      break;
  }

  return (
    <View style={styles.container}>
      <Icon
        type={iconType}
        name={icon}
        sizeName={"largeHigh"}
        colorName={isCurrent ? "rootMenuText" : "dark"}
      />
      <JText
        labelKey={`navigation.${iconName}`}
        valuesKey={valuesKey}
        colorName={isCurrent ? "rootMenuText" : "dark"}
        sizeName={isHorizontal ? "mediumLarge" : "medium"}
      />
      {/*user && newMessages > 0 && iconName === "chat" && (
        <BadgeController count={newMessages} />
      )*/}

      {/* TODO LATER : même chose pour les nouveaux posts du feed principal */}
    </View>
  );
};
