import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, Text } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { AnnotatedText, Annotation } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 350,
    marginTop: 16,
  },
  subText: {
    fontSize: 16,
    marginTop: 16,
  },
  secondCodeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 150,
    marginTop: 16,
  },
});

const AnnotatedTextScreen = () => {
  const annotations: Annotation[] = [
    {
      label: "Pas encore inscrit ?",
    },
    {
      label: "Créer un compte",
      annotated: true,
      onPress: () => {
        // console.log("Hello");
      },
    },
  ];

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="AnnotatedText"
        description="This component renders clickable highlighted text inside basic text."
      >
        <AnnotatedText annotations={annotations} />
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "const annotations: Annotation[] = [\n" +
            "    {\n" +
            '      label: "Pas encore inscrit ?",\n' +
            "    },\n" +
            "    {\n" +
            '      label: "Créer un compte",\n' +
            "      annotated: true,\n" +
            "      onPress: () => {\n" +
            '        console.log("Hello");\n' +
            "      },\n" +
            "    },\n" +
            "  ];\n\n<AnnotatedText annotations={annotations} />"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
        <Text style={innerStyles.subText}>The Annotation type :</Text>
        <CodeEditor
          style={innerStyles.secondCodeEditor}
          initialValue={
            "export type Annotation = {\n" +
            "  label?: string;\n" +
            "  labelKey?: I18nKey;\n" +
            "  annotated?: boolean;\n" +
            "  onPress?: () => void;\n" +
            "};"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="annotations"
        description="The annotations that the component has to display."
        type="Annotation[]"
        topDivider={false}
      />
      <CatalogProps
        propName="style?"
        description="The style of the container."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="textStyle?"
        description="The style of the non annotated text."
        type="StyleProp<TextStyle>"
      />
      <CatalogProps
        propName="annotatedTextStyle?"
        description="The style of the annotated text."
        type="StyleProp<TextStyle>"
      />
    </ScrollView>
  );
};

export default AnnotatedTextScreen;
