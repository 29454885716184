import {
  View,
  ScrollView,
  ScrollViewProps,
  StyleProp,
  ViewStyle,
  useWindowDimensions,
  Platform,
} from "react-native";
import React from "react";
import { Types } from "../../../types";
import { isHorizontalMode } from "components/utils/deviceMode";
import useTheme from "theme/ThemeProvider";

type Props = {
  direction?: Types["direction"];
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  safePaddingBottom?: boolean;
  safeHeader?: boolean;
  additionalProps?: ScrollViewProps;
  keyboardShouldPersistTaps?: boolean | "always" | "never" | "handled";
};

export const Scrollable: React.FC<Props> = ({
  direction = "vertical",
  safePaddingBottom = true,
  safeHeader = true,
  style = {},
  children,
  additionalProps,
  keyboardShouldPersistTaps,
}: Props) => {
  const { width, height } = useWindowDimensions();
  const { theme } = useTheme();
  return (
    <ScrollView
      style={[
        {
          height: safeHeader ? height - theme.layout.headerHeight : height,
        },
        style,
      ]}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      {...additionalProps}
      horizontal={direction !== "vertical"}
      contentContainerStyle={[
        safePaddingBottom
          ? {
              paddingBottom: !isHorizontalMode({ width, height })
                ? theme.sizings.medium +
                  theme.layout.navBottomHeight +
                  theme.layout.headerHeight
                : theme.sizings.medium + theme.layout.headerHeight,
            }
          : {},
        additionalProps?.contentContainerStyle,
      ]}
    >
      {children}
    </ScrollView>
  );
};

export const SView: React.FC<Props> = ({
  direction = "vertical",
  safePaddingBottom = true,
  safeHeader = true,
  style = {},
  children,
  additionalProps,
}: Props) => {
  const { width, height } = useWindowDimensions();
  const { theme } = useTheme();
  //const conditionScroll = isMobileMode({ width, height }) || isTabletMode({ width, height });
  const conditionScroll = Platform.OS === "web";

  return conditionScroll ? (
    <ScrollView
      style={[
        {
          height: safeHeader ? height - theme.layout.headerHeight : height,
        },
        style,
      ]}
      {...additionalProps}
      horizontal={direction !== "vertical"}
      contentContainerStyle={[
        safePaddingBottom
          ? {
              paddingBottom: !isHorizontalMode({ width, height })
                ? theme.sizings.medium +
                  theme.layout.navBottomHeight +
                  theme.layout.headerHeight
                : theme.sizings.medium + theme.layout.headerHeight,
            }
          : {},
        additionalProps?.contentContainerStyle,
      ]}
    >
      {children}
    </ScrollView>
  ) : (
    <View
      style={[
        safePaddingBottom
          ? {
              paddingBottom: theme.sizings.medium,
            }
          : {},
        style,
      ]}
    >
      {children}
    </View>
  );
};
