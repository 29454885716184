import { StyleSheet } from "react-native";
import { ColorType, Theme } from "../../../theme";
import hexToRgba from "hex-to-rgba";

export default (theme: Theme, color: ColorType) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: hexToRgba(theme.colors[color], 0.3),
      borderRadius: theme.normalize(theme.radius.large),
      alignSelf: "flex-start",
      paddingHorizontal: theme.normalize(theme.sizings.smallMedium),
      paddingVertical: theme.normalize(theme.sizings.small),
    },
    selected: {
      backgroundColor: hexToRgba(theme.colors[color], 0.7),
    },
    row: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    textSelected: {
      color: theme.colors.white,
      fontWeight: "bold",
    },
    text: {
      color: theme.colors.dark,
    },
    icon: {
      marginRight: theme.normalize(theme.sizings.small),
    },
  });
