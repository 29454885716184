import { StyleSheet } from "react-native";
import hexToRgba from "hex-to-rgba";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, width: number, isHorizontalMode: boolean) =>
  StyleSheet.create({
    container: {
      marginBottom: 1,
      paddingHorizontal: theme.sizings.medium,
    },
    card: {
      flex: 1,
      padding: 0,
      borderRadius: theme.normalize(15),
      overflow: "hidden",
    },
    mainImage: {
      height: isHorizontalMode ? 300 : width - theme.sizings.medium * 2,
      width: "100%",
    },
    content: {
      padding: theme.normalize(10),
    },
    more: {
      textAlign: "right",
      marginVertical: theme.normalize(10),
    },
    actions: {
      paddingTop: theme.normalize(10),
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    time: {
      flexDirection: "row",
      alignItems: "center",
    },
    timeLabel: {
      marginLeft: theme.normalize(4),
    },
    thanksCount: {
      marginRight: theme.normalize(4),
    },
    actionsContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    likeSpinner: {
      width: theme.normalize(30),
      height: theme.normalize(30),
      marginRight: theme.normalize(4),
    },
    upThanks: {
      marginRight: theme.normalize(4),
      backgroundColor: hexToRgba(theme.colors.info, 0.3),
      justifyContent: "center",
      alignItems: "center",
      width: theme.normalize(30),
      height: theme.normalize(30),
      borderRadius: theme.normalize(15),
    },
    downThanks: {
      marginRight: theme.normalize(4),
      backgroundColor: theme.colors.info,
      justifyContent: "center",
      alignItems: "center",
      width: theme.normalize(30),
      height: theme.normalize(30),
      borderRadius: theme.normalize(15),
    },
    tile: {
      fontWeight: "400",
    },
  });
