import { useUser } from "hooks";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./UserTypePickerStyle";
import RNPickerSelect, { Item } from "react-native-picker-select";

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

type UserTypePickerProps = {
  userType?: string;
  setUserType: (value: string) => void;
};

export const UserTypesPicker = ({
  userType,
  setUserType,
}: UserTypePickerProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const { fetchUserTypes, userTypes } = useUser();

  useEffect(() => {
    fetchUserTypes();
  }, [fetchUserTypes]);

  const items: Item[] = useMemo(() => {
    if (userTypes && userTypes.length > 0) {
      return userTypes.map((type) => ({ label: type?.name, value: type?.id }));
    }
    return [];
  }, [userTypes]);

  return (
    <RNPickerSelect
      value={userType}
      onValueChange={(type) => {
        if (type !== undefined) {
          setUserType(type);
        }
      }}
      placeholder={{ label: t("auth.userTypeSelectPromp") }}
      items={items}
      style={{
        inputWeb: styles.select,
        inputAndroid: styles.select,
        inputIOS: styles.select,
      }}
      doneText={t("common.validate")}
      touchableWrapperProps={{
        accessibilityLabel: "Sélecteur de type d'utilisateur",
        accessible: true,
        accessibilityHint: "Cliquez pour sélectionner une option.",
        accessibilityRole: "combobox",
      }}
    />
  );
};
