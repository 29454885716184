import { t } from "i18next";
import React, { ReactNode, useCallback, useState } from "react";
import {
  Linking,
  Platform,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Icon } from "../icon";
import * as ImagePicker from "expo-image-picker";
import { ImageSourceSelectModal } from "components/modals/ImageSourceSelectModal";
import { ActionsProps } from "react-native-gifted-chat";

type propsType = ActionsProps & {
  setSelectedImages: (images: any) => void;
  style?: StyleProp<ViewStyle>;
};

const JImagePicker = (props: propsType): ReactNode => {
  const { setSelectedImages, style } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [cameraPermission, requestCameraPermission] =
    ImagePicker.useCameraPermissions();
  const [galleryPermission, requestGalleryPermission] =
    ImagePicker.useMediaLibraryPermissions();

  const handlePickImage = async () => {
    // if in web, no source selection
    if (Platform.OS === "web") {
      await handleGalleryPicker();
    } else {
      await setModalVisible(true);
    }
  };

  const handleCameraPicker = useCallback(async () => {
    let permission = cameraPermission;

    if (permission?.status === ImagePicker.PermissionStatus.DENIED) {
      if (permission.canAskAgain) {
        permission = await requestCameraPermission();
      } else if (Platform.OS !== "web") {
        await Linking.openSettings();
        permission = await requestCameraPermission();
      }
    } else if (
      permission?.status === ImagePicker.PermissionStatus.UNDETERMINED
    ) {
      permission = await requestCameraPermission();
    }

    if (permission?.status === ImagePicker.PermissionStatus.GRANTED) {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        quality: 1,
        cameraType: ImagePicker.CameraType.back,
        exif: true,
      });

      if (!result.canceled) {
        setModalVisible(false);
        const newSelectedImages = result?.assets.map((uri) => uri);
        setSelectedImages((prev: any) => [...prev, ...newSelectedImages]);
      } else {
        setModalVisible(true);
      }
    }
  }, [cameraPermission, requestCameraPermission, setSelectedImages]);

  const handleGalleryPicker = useCallback(async () => {
    let permission = galleryPermission;
    if (permission?.status === ImagePicker.PermissionStatus.DENIED) {
      if (Platform.OS !== "web") {
        await Linking.openSettings();
      }
    }
    if (permission?.status !== ImagePicker.PermissionStatus.GRANTED) {
      permission = await requestGalleryPermission();
    }
    if (permission?.status === ImagePicker.PermissionStatus.GRANTED) {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        quality: 1,
        allowsMultipleSelection: true,
        exif: true,
      });

      if (!result.canceled) {
        setModalVisible(false);
        const newSelectedImages = result?.assets.map((uri) => uri);
        setSelectedImages((prev: any) => [...prev, ...newSelectedImages]);
      } else {
        setModalVisible(true);
      }
    }
  }, [galleryPermission, requestGalleryPermission, setSelectedImages]);

  return (
    <TouchableOpacity
      onPress={handlePickImage}
      style={style}
      accessibilityRole="button"
      accessibilityLabel={t("labels.addDocument")}
    >
      <ImageSourceSelectModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handlePickCamera={handleCameraPicker}
        handlePickGallery={handleGalleryPicker}
      />
      <Icon
        name={"file-image-plus"}
        type={"MaterialCommunity"}
        colorName={"dark"}
        sizeName={"larger"}
      />
    </TouchableOpacity>
  );
};

export default JImagePicker;
