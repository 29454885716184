export default {
  sites: {
    wording: {
      Sg_main: "canal",
      Pl_main: "canaux",
    },
    display: {
      organization: false,
      searchBar: false,
      typesSeparator: true,
    },
    features: {
      locationMandatory: false,
      suggestionForm: false,
    },
    map: {
      defaultCenter: "default",
      defaultCenterPoint: {
        lat: 46.4829631,
        lng: 2.5259702,
      },
      defaultZoomLevel: 6,
      defaultMobileZoomLevel: 0.015,
    },
  },
  profile: {
    email: {
      share: true,
    },
    phone: {
      share: false,
      sms: false,
    },
    features: {
      loginMandatory: true,
      hasUserType: false,
      enableAboveFifteen: false,
    },
  },
  threads: {
    features: {
      enableThreadWithoutSite: false,
      enableThreadWithLocation: false,
    },
  },
};
