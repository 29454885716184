import { StyleSheet } from "react-native";
import hexToRgba from "hex-to-rgba";
import { ColorType, Theme } from "theme/ThemeTypes";

export default (theme: Theme, color: ColorType | string) =>
  StyleSheet.create({
    container: {
      backgroundColor: hexToRgba(
        color.startsWith("#") ? color : theme.colors[color as ColorType],
        0.3
      ),
      paddingHorizontal: theme.normalize(theme.sizings.smallMedium),
      paddingBottom: theme.normalize(theme.sizings.smallMedium),
      alignItems: "center",
    },
    rowContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      paddingVertical: theme.normalize(theme.sizings.small),
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    titleBox: {
      flex: 1,
    },
    beforeDateRow: {
      flex: 2,
      alignSelf: "flex-start",
    },
    beforeDateText: {
      width: "90%",
    },
    icon: {
      width: theme.normalize(40),
      height: theme.normalize(40),
      justifyContent: "center",
      alignItems: "center",
      marginLeft: theme.normalize(theme.sizings.smallMedium),
    },
    postIcon: {
      width: theme.normalize(30),
      height: theme.normalize(30),
      borderRadius: theme.radius.medium,
    },
    postIconInner: {
      width: "100%",
      height: "100%",
    },
    postTypeIcon: {
      alignSelf: "flex-end",
    },
    siteImage: {
      width: theme.normalize(40),
      height: theme.normalize(40),
      borderRadius: theme.normalize(theme.radius.medium),
      marginRight: theme.normalize(theme.sizings.smallMedium),
    },
    draft: {
      borderRadius: theme.radius.medium,
      backgroundColor: theme.colors.primary,
      paddingVertical: theme.sizings.tiny,
      paddingHorizontal: theme.sizings.smallMedium,
      marginLeft: theme.sizings.tiny,
      marginTop: theme.sizings.tiny * 2,
      alignItems: "center",
      justifyContent: "center",
    },
    pinnedContainer: {
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "flex-start",
    },
    pinnedText: {
      marginTop: theme.normalize(4),
      marginLeft: theme.normalize(4),
    },
  });
