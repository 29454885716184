import React from "react";
import { View } from "react-native";
import { CatalogDescription } from "./CatalogDescription";
import { CatalogDivider } from "./CatalogDivider";
import { CatalogPropsTitle } from "./CatalogPropsTitle";
import { CatalogArray } from "./CatalogArray";

type Props = {
  propName: string;
  description?: string;
  type: string;
  topDivider?: boolean;
};

export const CatalogProps = (props: Props) => {
  const divider = props.topDivider ?? true;

  return (
    <View>
      {divider && <CatalogDivider />}
      <CatalogPropsTitle label={props.propName} />
      <CatalogDivider />
      {props.description && <CatalogDescription label={props.description} />}
      <CatalogArray label={props.type} />
    </View>
  );
};
