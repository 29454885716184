import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import React from "react";
import { ScrollView, StyleSheet } from "react-native";
import { JImage } from "../../components";
import { CatalogHeader, CatalogProps } from "./common";
import styles from "./common/CatalogStyle";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 100,
    marginTop: 16,
  },
  image: {
    width: 80,
    height: 100,
  },
});

const ImageScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Image"
        description="Renders a local or remote image. If you want to display a local image, you need to require the image in the images.ts file"
      >
        <JImage source={"imageTest"} type={"Local"} style={innerStyles.image} />
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            '<JImage source={"imageTest"} type={"Local"} style={innerStyles.image} />'
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="type?"
        description="The type of the source from the image. (default to Uri)"
        type="Local | Uri"
        topDivider={false}
      />
      <CatalogProps
        propName="source"
        description="The source of the image."
        type="string"
      />
      <CatalogProps
        propName="style?"
        description="The style of the image."
        type="StyleProp<ImageStyle>"
      />
    </ScrollView>
  );
};

export default ImageScreen;
