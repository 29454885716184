import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, listPage: boolean) =>
  StyleSheet.create({
    badge: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: listPage ? "flex-start" : "flex-end",
      paddingHorizontal: theme.sizings.smallMedium,
      paddingVertical: theme.sizings.tiny,
      borderRadius: theme.radius.medium,
      backgroundColor: theme.colors.grey,
    },
    badgeIcon: {
      marginRight: theme.sizings.small,
      position: "relative",
      top: 1,
    },
  });
