import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    badge: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "flex-start",
      paddingHorizontal: theme.sizings.smallMedium,
      paddingVertical: theme.sizings.tiny,
      borderRadius: theme.radius.medium,
      backgroundColor: theme.colors.tinyElementsBackground,
    },
  });
