import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (
  theme: Theme,
  isHorizontalMode: boolean,
  width: number,
  height: number
) =>
  StyleSheet.create({
    container: {
      minHeight: "100%",
      alignItems: "center",
      paddingHorizontal: theme.normalize(10),
      marginTop: theme.sizings.medium,
      paddingTop: 190,
    },
    map: {
      width: isHorizontalMode
        ? height * 0.8
        : width - theme.sizings.mediumLarge,
      height: isHorizontalMode ? height * 0.5 : width * 0.8,
      backgroundColor: theme.colors.greyLight,
      marginVertical: theme.normalize(theme.sizings.medium),
      borderRadius: theme.radius.medium,
      overflow: "hidden",
      justifyContent: "center",
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
    },
    button: {
      alignSelf: "center",
    },
  });
