import { StatusBar, Platform } from "react-native";

// Fonction pour récupérer la hauteur de la barre d'état
const getStatusBarHeight = () => {
  if (Platform.OS === "android") {
    return StatusBar.currentHeight || 0;
  } else if (Platform.OS === "ios") {
    return 20; // Hauteur de la barre d'état standard sur iOS
  } else {
    return 0;
  }
};

export default getStatusBarHeight;
