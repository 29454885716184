import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import { Icon } from "../../components";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 70,
    marginTop: 16,
  },
});

const IconScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Icon"
        description="Renders an icon. You can choose the icon from the link in .io typing react native vector icons on Google."
      >
        <Icon type={"AntDesign"} name={"like1"} />
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={'<Icon type={"AntDesign"} name={"like1"} />'}
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="type"
        description="The type of the icon."
        type="MaterialCommunity | AntDesign | Entypo | EvilIcons | Feather | FontAwesome | Fontisto | Foundation | Material | MaterialIcons | IonIcons"
        topDivider={false}
      />
      <CatalogProps
        propName="name"
        description="The name of the icon."
        type="string"
      />
      <CatalogProps
        propName="sizeName?"
        description="The size of the icon. It has to be a value of the enum from the Theme. (default to large)"
        type="FontSizeType"
      />
      <CatalogProps
        propName="customSize?"
        description="The size of the icon. You can choose any size here and it will super the sizeName props."
        type="number"
      />
      <CatalogProps
        propName="colorName?"
        description="The color of the icon. It has to be a value of the enum from the Theme. (default to primary)"
        type="ColorType"
      />
      <CatalogProps
        propName="style?"
        description="The style of the icon."
        type="StyleProp<ViewStyle>"
      />
    </ScrollView>
  );
};

export default IconScreen;
