import React from "react";
import {
  Modal,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ModalStyle";
import { Button, JText, Spacer } from "../jlib";
//import fnStyles from "./DocumentModalStyle";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
  handlePickGallery: () => void;
  handlePickCamera: () => void;
};

export const ImageSourceSelectModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  handlePickGallery,
  handlePickCamera,
}) => {
  const { height, width } = useWindowDimensions();
  const { theme } = useTheme();
  const styles = fnStyles(theme, height, width);

  return (
    <Modal
      visible={modalVisible}
      animationType="slide"
      hardwareAccelerated
      transparent
    >
      <TouchableOpacity
        onPress={() => {
          setModalVisible(false);
        }}
        style={styles.layerStyle}
      >
        <View style={styles.panelStyle}>
          <View style={styles.panelHeaderStyle}>
            <JText labelKey="modal.selectSource" titleLevel={2} isTitleText />
          </View>
          <View
            style={{
              ...styles.panelFooterStyle,
              ...styles.noMarginY,
            }}
          >
            <Button
              type="action"
              labelKey="common.camera"
              iconType={"IonIcons"}
              iconName={"camera-outline"}
              iconSizeName="larger"
              onPress={handlePickCamera}
            />
            <Spacer height="mediumLarge" />
            <Button
              type="action"
              labelKey="common.gallery"
              iconType={"IonIcons"}
              iconName={"images-outline"}
              iconSizeName="larger"
              onPress={handlePickGallery}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
