import React from "react";
import { KeyboardAvoidingView, Platform } from "react-native";

type Props = {
  offset?: number;
  children: React.ReactNode[] | React.ReactNode;
};

const JKeyboardAvoidingView: React.FC<Props> = ({ offset = 0, children }) => {
  return (
    <KeyboardAvoidingView
      behavior={"position"}
      keyboardVerticalOffset={offset}
      enabled={Platform.OS === "ios"}
    >
      {children}
    </KeyboardAvoidingView>
  );
};

export default JKeyboardAvoidingView;
