import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (
  theme: Theme,
  isHorizontalMode: boolean,
  width: number,
  height: number
) =>
  StyleSheet.create({
    container: {
      flexDirection: isHorizontalMode ? "row" : "column",
      marginTop: isHorizontalMode ? 0 : theme.normalize(-30),
    },
    leftWeb: {
      flex: 1,
      maxWidth: width * 0.35,
      backgroundColor: theme.colors.white,
      margin: theme.sizings.huge * 0.5,
      borderRadius: theme.radius.medium,
      height: height - theme.layout.navBottomHeight - theme.sizings.huge,
    },
    button: {
      position: "absolute",
      /*bottom: !isHorizontalMode
        ? theme.normalize(theme.layout.navBottomHeight + 16)
        : theme.normalize(16),*/
      bottom: theme.normalize(16),
      right: theme.normalize(16),
    },
    buttonLabel: {
      marginLeft: theme.normalize(theme.sizings.large),
      marginRight: theme.normalize(theme.sizings.mediumLarge),
    },
  });
