import { StackScreenProps } from "@react-navigation/stack";
import React, { useState } from "react";
import { View } from "react-native";
import {
  BackgroundLayout,
  NoQuestions,
  QAndAItem,
  Scrollable,
} from "../../components";
import { PostsParamsList } from "../../navigation";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { usePosts } from "hooks";
import useSites from "hooks/useSites";
import useTheme from "../../theme/ThemeProvider";

type Props = StackScreenProps<PostsParamsList, "postQuestions">;

export const PostQuestionScreen: React.FC<Props> = ({ navigation }) => {
  const { post } = usePosts();
  const { setSite } = useSites();
  const { theme } = useTheme();

  const [answerVisible, setAnswerVisible] = useState(null);

  if (!post) {
    return <View />;
  }

  const onChatNav = () => {
    setSite(post?.site);
    navigation.navigate("chatSelectType", {
      postId: post.id,
      siteId: Number(post.site?.id),
    });
  };

  return (
    <BackgroundLayout>
      <Scrollable
        additionalProps={{
          contentContainerStyle: {
            paddingTop: theme.normalize(110),
          },
        }}
      >
        <BoxedLayout>
          {post.qAndAs?.length > 0 ? (
            post.qAndAs.map((qAndA) => {
              return (
                <View key={`qanda-${qAndA.id}`}>
                  <QAndAItem
                    qAndA={qAndA}
                    //siteName={post.site?.name ?? ""}
                    isOpen={qAndA.id === answerVisible}
                    setAnswerVisible={setAnswerVisible}
                  />
                </View>
              );
            })
          ) : (
            <View>
              <NoQuestions
                navigation={navigation}
                handleNav={onChatNav}
                site={post.site}
              />
            </View>
          )}
        </BoxedLayout>
      </Scrollable>
    </BackgroundLayout>
  );
};
