import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isLarge: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      borderRadius: 20,
      minWidth: isLarge ? "100%" : undefined,
      justifyContent: "space-between",
    },
    item: { alignItems: "center", alignSelf: "center", flex: 1 },
    date: {
      lineHeight: theme.normalize(18),
    },
    month: {
      lineHeight: theme.normalize(12),
    },
    year: {
      lineHeight: theme.normalize(8),
    },
    divider: {
      width: 2,
      backgroundColor: "rgba(0,0,0,0.15)",
      marginVertical: theme.normalize(theme.sizings.smallMedium),
    },
    hour: {
      lineHeight: theme.normalize(12),
    },
  });
