import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      marginBottom: 1,
      paddingHorizontal: theme.sizings.medium,
    },
    card: {
      flex: 1,
      padding: 0,
      borderRadius: theme.normalize(15),
      overflow: "hidden",
    },
    content: {
      padding: theme.normalize(10),
    },
    dividerBox: {
      paddingHorizontal: theme.sizings.small,
    },
  });
