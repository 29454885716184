//import { IMessage } from "react-native-gifted-chat";
import { IMessage } from "react-native-gifted-chat";
import { Message } from "../types/Message";

export const convertMessages = (messages: Message[]) => {
  if (!messages) return [];
  return messages.map((message) => {
    return {
      _id: message.id,
      text: message.content,
      createdAt: message.createdAt,
      user: {
        _id: message.author?.id,
        name: message.author?.name || "utilisateur",
      },
      image: message?.documents?.map((document) => document.url),
    } as unknown as IMessage;
  });
};

/*
  export interface IMessage {
  _id: string | number
  text: string
  createdAt: Date | number
  user: User
  image?: string
  video?: string
  audio?: string
  system?: boolean
  sent?: boolean
  received?: boolean
  pending?: boolean
  quickReplies?: QuickReplies
}
* */
