import { useList } from "hooks";
import useOrganization from "hooks/useOrganization";
import React, { createContext, useState, useCallback, useEffect } from "react";
import { Site } from "types/Site";
import { Thread, ThreadType } from "types/ThreadType";
import { fetchJSON } from "utils";

type ThreadProviderType = {
  thread: Thread | undefined;
  setThread: (thread: Thread | undefined) => void;
  fetchThread: (threadId: number) => void;
  getThreadType: (threadTypeId: number | undefined) => ThreadType | undefined;
  getThreadTypes: (currentSite?: Site) => ThreadType[] | undefined;
};

export const ThreadContext = createContext<ThreadProviderType>(
  {} as ThreadProviderType
);

type Props = {
  children: React.ReactNode;
};

export const ThreadsProvider = ({ children }: Props) => {
  const { organization: currentOrganization } = useOrganization();

  const { items: defaultTypes, fetchItems: fetchDefaultTypes } =
    useList<ThreadType>("thread-types", {
      populate: ["imageLightMode", "imageDarkMode"],
    });

  useEffect(() => {
    if (!defaultTypes?.length) {
      fetchDefaultTypes();
    }
  }, [fetchDefaultTypes, defaultTypes]);

  const getThreadTypes = useCallback(
    (currentSite?: Site) => {
      const refOrganization = currentOrganization || currentSite?.organization;

      // cas 1 : pas d'organization
      if (!refOrganization) {
        return defaultTypes || [];
      }

      // cas 2 : dans l'ordre des possibilités
      if (refOrganization) {
        if (refOrganization.disableThreads || currentSite?.disableThreads) {
          return [];
        }

        // sites overrides

        if (currentSite?.overrideThreadTypes) {
          return currentSite?.threadTypes || [];
        }

        // cas final: organization params
        return refOrganization?.threadTypes || [];
      }
    },
    [currentOrganization, defaultTypes]
  );

  // display static title of current thread
  const getThreadType = useCallback(
    (threadTypeId?: number) => {
      const types = currentOrganization
        ? currentOrganization?.threadTypes || []
        : defaultTypes;

      return (types || []).find((t) => t.id === threadTypeId);
    },
    [currentOrganization, defaultTypes]
  );

  const [thread, setThread] = useState<Thread>();

  const fetchThread = useCallback(async (threadId: number) => {
    try {
      const res = await fetchJSON({
        url: "threads/me",
        method: "GET",
      });
      const th = res ? res.find((t: Thread) => t.id === threadId) : null;
      setThread(th);
      return th;
    } catch {
      return null;
    }
  }, []);

  return (
    <ThreadContext.Provider
      value={{
        getThreadType,
        getThreadTypes,
        thread,
        setThread,
        fetchThread,
      }}
    >
      {children}
    </ThreadContext.Provider>
  );
};
