import React from "react";
import { View, Text } from "react-native";
import styles from "./CatalogStyle";

type Props = {
  label: string;
};

export const CatalogArray = (props: Props) => {
  return (
    <View style={styles.catalogArray}>
      <View style={[styles.catalogArrayRow, styles.catalogArrayHeader]}>
        <Text style={styles.catalogArrayHeaderLabel}>TYPE</Text>
      </View>
      <View style={styles.catalogArrayRow}>
        <Text>{props.label}</Text>
      </View>
    </View>
  );
};
