import { StyleSheet } from "react-native";

export default () =>
  StyleSheet.create({
    dividerHorizontal: {
      width: "100%",
      height: 1,
    },
    dividerVertical: {
      height: "100%",
      width: 1,
    },
  });
