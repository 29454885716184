import { StyleSheet } from "react-native";

export default () =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "flex-start",
    },
    front: {
      position: "absolute",
      zIndex: 1,
    },
  });
