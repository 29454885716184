import { isHorizontalMode } from "../../../components/utils/deviceMode";
import React, { useEffect, useMemo } from "react";
import { Platform, View, useWindowDimensions } from "react-native";
import { JText } from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "../ParamsScreenStyle";
import { useUser } from "hooks";
import Map from "../../../components/jlib/map/Map";
import useSites from "hooks/useSites";
import { Points } from "types/Site";
import {
  PanGestureHandler,
  PinchGestureHandler,
} from "react-native-gesture-handler";

const AccountAreaOfInterestBlock = ({
  setIsScrollEnabled,
  verticalScrollOffset,
}: any) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }), width);
  const { areaOfInterest, updateAreaOfInterest } = useUser();
  const { sites, getSites } = useSites();

  useEffect(() => {
    if (!sites) {
      getSites();
    }
  }, [getSites, sites]);

  const points = useMemo(() => {
    return sites
      ?.filter((item) => item.siteType?.isGeo && item.geojson?.lat)
      ?.map((item) => {
        return {
          id: item.id,
          lat: item.geojson?.lat,
          lng: item.geojson?.lng,
          name: item.name,
        } as Points;
      });
  }, [sites]);

  const mapInitialRegion = useMemo(() => {
    return areaOfInterest
      ? {
          lat:
            areaOfInterest.reduce(
              (acc: number, val: Points) => acc + (val?.lat || 0),
              0
            ) / areaOfInterest.length,
          lng:
            areaOfInterest.reduce(
              (acc: number, val: Points) => acc + (val?.lng || 0),
              0
            ) / areaOfInterest.length,
          id: 1,
        }
      : undefined;
  }, [areaOfInterest]);

  const mapInitialAreaOfInterest = useMemo(() => {
    return Platform.OS === "web"
      ? areaOfInterest
      : areaOfInterest?.map((point: any) => {
          return {
            latitude: point.lat,
            longitude: point.lng,
          };
        });
  }, [areaOfInterest]);

  return (
    <View>
      <JText
        labelKey="common.interestArea"
        isBold
        centered
        style={styles.notificationSectionLabel}
        titleLevel={2}
      />
      {!areaOfInterest && (
        <View style={{ marginBottom: theme.normalize(theme.sizings.medium) }}>
          <JText
            label="Vous n'avez pas de zone d'intérêt."
            centered
            sizeName="medium"
          />
          <JText
            label="Créez-en une pour être automatiquement abonné au contenu à l'intérieur !"
            centered
            sizeName="medium"
          />
        </View>
      )}
      <View style={{ flex: 1 }}>
        <PanGestureHandler
          onGestureEvent={() => setIsScrollEnabled(false)}
          onEnded={() => setIsScrollEnabled(true)}
        >
          <PinchGestureHandler>
            <Map
              height={
                isHorizontalMode({ width, height }) ? width * 0.4 : width * 0.8
              }
              key={`map-${mapInitialRegion?.lat}}`}
              points={points}
              initialRegion={mapInitialRegion}
              scrollEnabled={true}
              forUserAreaOfInterest
              areaOfInterest={mapInitialAreaOfInterest}
              updateAreaOfInterest={updateAreaOfInterest}
              setParentScrollEnabled={setIsScrollEnabled}
              verticalScrollOffset={verticalScrollOffset}
            />
          </PinchGestureHandler>
        </PanGestureHandler>
      </View>
    </View>
  );
};

export default AccountAreaOfInterestBlock;
