import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (
  theme: Theme,
  width: number,
  height: number,
  isHorizontalMode: boolean
) =>
  StyleSheet.create({
    safe: {
      flex: 1,
    },
    headerImageContainer: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    headerImage: {
      width: theme.normalize(250),
      height: theme.normalize(100),
      marginTop: isHorizontalMode ? 0 : theme.sizings.huge * 0.8,
      marginBottom: theme.sizings.mediumLarge,
    },
    button: {
      marginTop: isHorizontalMode ? theme.sizings.mediumLarge : 0,
    },
    buttonContainer: {
      position: "absolute",
      top: theme.normalize(-24),
    },
    stackFooter: {
      width: "100%",
      height: isHorizontalMode ? theme.normalize(150) : theme.normalize(300),
    },
    bottomImageContainer: {
      height: "100%",
      width: "100%",
      position: "absolute",
      bottom: 0,
      borderTopLeftRadius: theme.radius.large,
      borderTopRightRadius: theme.radius.large,
      overflow: "hidden",
    },
    bottomImage: {
      borderTopLeftRadius: theme.radius.large,
      borderTopRightRadius: theme.radius.large,
      height: "100%",
      width: "100%",
    },
    iconContainer: {
      width: "100%",
      alignItems: "center",
    },
    icon: {
      marginBottom: theme.sizings.mediumLarge,
    },
    containerWeb: {
      flexDirection: "row",
      width: "100%",
    },
    scrollable: {
      height: isHorizontalMode
        ? height - width + theme.normalize(150)
        : undefined,
      flex: isHorizontalMode ? undefined : 1,
      paddingBottom: isHorizontalMode
        ? theme.normalize(150)
        : theme.normalize(75),
    },
    left: {
      flex: 1,
    },
    right: {
      flex: 1,
      marginBottom: isHorizontalMode ? theme.sizings.mediumLarge : undefined,
    },
    leftImage: {
      height: height - theme.sizings.large * 2,
      width: "100%",
      borderRadius: theme.radius.large / 2,
      overflow: "hidden",
      margin: theme.sizings.large,
    },
    description: {
      paddingHorizontal: theme.sizings.mediumLarge,
      width: "100%",
    },
    descriptionMore: {
      marginTop: theme.sizings.mediumLarge,
      paddingHorizontal: theme.sizings.mediumLarge,
      width: "100%",
    },
    center: {
      alignItems: "center",
      justifyContent: "center",
      minHeight: height,
      paddingBottom: theme.sizings.huge,
    },
  });
