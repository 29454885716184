import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, height: number, width: number) =>
  StyleSheet.create({
    layerStyle: {
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#00000095",
      height,
    },
    panelStyle: {
      padding: theme.sizings.mediumLarge,
      backgroundColor: theme.colors.white,
      borderRadius: theme.radius.mediumLarge,
      maxWidth: width - theme.sizings.mediumLarge * 2,
      width: 500,
      maxHeight: height - theme.sizings.mediumLarge * 2 - theme.sizings.huge,
    },
    panelHeaderStyle: {
      alignItems: "center",
      justifyContent: "center",
    },
    panelFooterStyle: {
      marginTop: theme.sizings.mediumLarge,
      alignItems: "center",
    },
    noMarginY: {
      marginTop: 0,
      marginBottom: 0,
    },
  });
