import React from "react";
import { JText } from "components/jlib";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./BadgeDemoStyle";
import { View } from "react-native";

export const BadgeDemo: React.FC = () => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.badge}>
      <JText
        labelKey={"site.draft"}
        colorName="tinyElementsText"
        sizeName="average"
      />
    </View>
  );
};

export default BadgeDemo;
