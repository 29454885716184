import React, { useState } from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, View } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Checkbox } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 225,
    marginTop: 16,
  },
});

const CheckboxScreen = () => {
  const [check, setCheck] = useState(false);

  const onCheckBoxPressed = () => {
    setCheck(!check);
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Checkbox" description="Renders a checkbox.">
        <View>
          <Checkbox value={check} onPress={onCheckBoxPressed} />
          <Checkbox
            value={check}
            onPress={onCheckBoxPressed}
            label={"Same as above but with a label"}
          />
        </View>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<View>\n" +
            "  <Checkbox value={check} onPress={onCheckBoxPressed} />\n" +
            "  <Checkbox\n" +
            "    value={check}\n" +
            "    onPress={onCheckBoxPressed}\n" +
            '    label={"Same as above but with a label"}\n' +
            "  />\n" +
            "</View>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="value"
        description="The value of the component."
        type="boolean"
        topDivider={false}
      />
      <CatalogProps
        propName="onPress"
        description="The callback called when you press the component."
        type="() => void"
      />
      <CatalogProps
        propName="onColor?"
        description="The color when the checkbox is checked. (default to primary)"
        type="string"
      />
      <CatalogProps
        propName="offColor?"
        description="The color when the checkbox isn't checked. (default to white)"
        type="string"
      />
      <CatalogProps
        propName="label?"
        description="A label set next to the checkbox. (default to null)"
        type="string"
      />
      <CatalogProps
        propName="labelKey?"
        description="A I18n key for a label set next to the checkbox. (default to null)"
        type="I18nKey"
      />
      <CatalogProps
        propName="labelStyle?"
        description="The style of the label."
        type="StyleProp<TextStyle>"
      />
      <CatalogProps
        propName="disabled?"
        description="Whether the checkbox is disabled or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="size?"
        description="The size of the checkbox. (default to medium)"
        type='"small" | "medium" | "large"'
      />
      <CatalogProps
        propName="shape?"
        description="The shape of the checkbox. (default to rounded)"
        type='"squared" | "rounded" | "circle"'
      />
      <CatalogProps
        propName="adaptToPlatform?"
        description="Whether the checkbox takes the common shape of the platform the app is launched on. (default to true)"
        type="boolean"
      />
      <CatalogProps
        propName="style?"
        description="The style of the component container."
        type="StyleProp<ViewStyle>"
      />
    </ScrollView>
  );
};

export default CheckboxScreen;
