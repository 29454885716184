import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    layout: {
      marginTop: theme.normalize(-30),
      paddingTop: theme.normalize(30),
    },
    container: {
      minHeight: "100%",
      justifyContent: "center",
      paddingHorizontal: theme.normalize(10),
    },
    contentContainer: {
      alignItems: "center",
    },
    avatar: {
      width: theme.normalize(100),
      height: theme.normalize(100),
      borderRadius: theme.normalize(50),
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.colors.info,
      marginBottom: theme.normalize(10),
    },
    avatarText: {
      marginTop: theme.normalize(10),
    },
    card: {
      marginVertical: theme.normalize(8),
      width: "100%",
    },
    cardRow: {
      flexDirection: "row",
      gap: 12,
      alignSelf: "center",
    },
    emptyCard: {
      alignSelf: "center",
    },
  });
