import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Divider } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 135,
    marginTop: 16,
  },
});

const DividerScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Divider" description="Renders a divider.">
        <View>
          <Text>Above divider</Text>
          <Divider />
          <Text>Below divider</Text>
        </View>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<View>\n" +
            "  <Text>Above divider</Text>\n" +
            "  <Divider />\n" +
            "  <Text>Below divider</Text>\n" +
            "</View>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="colorName?"
        description="The color of the divider. It has to be one of the color of the Theme. (default to rgba(0,0,0,0.2)"
        type="ColorType"
        topDivider={false}
      />
      <CatalogProps
        propName="vertical?"
        description="Whether the divider is vertical or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="margin?"
        description="The spacing between the divider and the others component. It has to be one of the size from the Theme. (default to 0)"
        type="SizingsType"
      />
      <CatalogProps
        propName="padding?"
        description="The spacing between the divider and his limits. It has to be one of the size from the Theme. (default to 0)"
        type="SizingsType"
      />
      <CatalogProps
        propName="style?"
        description="The style of the component."
        type="StyleProp<ViewStyle>"
      />
    </ScrollView>
  );
};

export default DividerScreen;
