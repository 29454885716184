import React from "react";
import { ActivityIndicator, View } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./LoadingScreenStyle";
import { JText } from "../../jlib";

export const LoadingScreen: React.FC = () => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.container}>
      <JText
        labelKey="common.loading"
        colorName={"emphasisText"}
        isBold
        style={styles.text}
      />
      <ActivityIndicator color={theme.colors.greyDark} size={"large"} />
    </View>
  );
};
