import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React from "react";
import useTheme from "theme/ThemeProvider";
import {
  BackgroundLayout,
  Button,
  Card,
  JText,
  SView,
  Spacer,
} from "../../components";
import { ParamsParamsList } from "../../navigation";
import fnStyles from "./ValidateAccountScreenStyle";

type Props = StackScreenProps<ParamsParamsList, "validateAccount">;

export const ValidateAccountScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();

  const styles = fnStyles(theme);
  return (
    <BackgroundLayout>
      <SView>
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText centered labelKey="auth.welcome" />
            <JText
              centered
              labelKey="auth.pleaseConfirmAccount"
              titleLevel={2}
              titleLevelStyle={2}
            />
            <Spacer height="medium" backgroundColor={"transparent"} />

            <Button
              type={"gradient"}
              labelKey="auth.goToLogin"
              onPress={() => navigation.navigate("logIn")}
              containerStyle={styles.button}
            />
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
