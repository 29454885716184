import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      marginVertical: theme.normalize(10),
      marginHorizontal: theme.normalize(16),
      padding: 0,
      backgroundColor: theme.colors.white,
    },
    questionCard: {
      width: "100%",
      paddingVertical: theme.normalize(12),
      paddingHorizontal: theme.normalize(12),
      marginBottom: theme.normalize(4),
      flexDirection: "row",
      alignItems: "flex-end",
    },
    siteName: {
      marginVertical: theme.normalize(10),
    },
    answerCard: {
      width: "100%",
      backgroundColor: theme.colors.infoLight,
      paddingVertical: theme.normalize(16),
      paddingHorizontal: theme.normalize(12),
      justifyContent: "center",
      alignItems: "center",
      borderBottomLeftRadius: theme.radius.medium,
      borderBottomRightRadius: theme.radius.medium,
    },
    text: {
      width: "100%",
      flex: 1,
    },
    arrowIcon: {
      marginLeft: theme.sizings.medium,
    },
    richText: {
      width: "100%",
    },
  });
