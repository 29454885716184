import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      flexDirection: "column",
      flex: 1,
    },
    stepContainer: {
      flexDirection: "row",
      flex: 1,
    },
    limitTextContainer: {
      paddingTop: theme.normalize(theme.sizings.small),
    },
    textContainer: {
      flex: 1,
      marginBottom: theme.normalize(theme.sizings.medium),
      marginLeft: theme.normalize(theme.sizings.smallMedium),
      width: "100%",
    },
    description: {},
    leftContainer: {
      flexDirection: "column",
      alignItems: "center",
      width: theme.normalize(34),
    },
    dot: {
      width: theme.normalize(20),
      height: theme.normalize(20),
      borderRadius: theme.normalize(10),
      backgroundColor: theme.colors.grey,
      borderWidth: theme.normalize(2),
      borderColor: theme.colors.white,
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
      elevation: 1,
    },
    limitDot: {
      width: theme.normalize(34),
      height: theme.normalize(34),
      borderRadius: theme.normalize(17),
    },
    validatedDot: {
      width: theme.normalize(25),
      height: theme.normalize(25),
      borderRadius: theme.normalize(12.5),
      backgroundColor: theme.colors.tinyElementsBackground,
      justifyContent: "center",
      alignItems: "center",
    },
    endDot: {
      backgroundColor: theme.colors.primary,
    },
    lineDot: {
      width: theme.normalize(4),
      height: theme.normalize(4),
      borderRadius: theme.normalize(2),
      backgroundColor: theme.colors.grey,
      marginBottom: theme.normalize(theme.sizings.smallMedium),
    },
    lineContainer: {
      width: theme.normalize(4),
      overflow: "hidden",
      minHeight: theme.normalize(90),
    },
    line: {
      borderStyle: "dotted",
      borderWidth: theme.normalize(4),
      borderColor: theme.colors.grey,
      height: "100%",
    },
  });
