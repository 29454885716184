import { useCallback, useEffect } from "react";
import qs from "qs";
import { CancellablePromise, parseStrapiFormat } from "../utils";
import useFetch, { UseFetchOptions } from "./useFetch";

// const postExecute = (data: { results: any }): any => data.results;

export type UseItemOptions = {
  populate?: string | object;
  fields?: string | object;
} & UseFetchOptions;

export type UseItemType<T> = {
  isFetching: boolean;
  item: T | null;
  error: string | null;
  isPreviousItem: boolean;
  fetch: () => CancellablePromise<unknown>;
  fetchItem: (id: any) => CancellablePromise<unknown>;
  saveItem: (payload: any) => CancellablePromise<unknown> | void;
  updateItem: (id: any, payload: any) => CancellablePromise<unknown>;
  removeItem: (id: any) => CancellablePromise<unknown>;
};

const useItem = <T>(
  url: string,
  itemId: number | string,
  options: UseItemOptions = {}
): UseItemType<T> => {
  const { populate, fields, ...opts } = options;

  const getUrl = useCallback(
    (urlOrig: string) => {
      const query = {} as {
        populate?: string | object;
        fields?: string | object;
      };

      if (populate) {
        query.populate = populate;
      }
      if (fields) {
        query.fields = fields;
      }

      const queryParams = qs.stringify(query, {
        encodeValuesOnly: true,
      });

      return `${urlOrig}${queryParams ? "?" : ""}${queryParams}`;
    },
    [fields, populate]
  );

  const getDetailUrl = getUrl(`${url}/${itemId}`);
  const getSimpleUrl = getUrl(url);

  opts.postExecute = parseStrapiFormat;

  const { fetchData, data, error, isFetching, isPreviousData, setData } =
    useFetch<T>(getSimpleUrl, {
      sharePromise: false,
      manual: true,
      // postExecute,
      ...opts,
    });

  const fetch = useCallback(
    () => fetchData({ url: getSimpleUrl, method: "GET" }),
    [fetchData, getSimpleUrl]
  );

  const fetchItem = useCallback(
    () => fetchData({ url: getDetailUrl, method: "GET" }),
    [fetchData, getDetailUrl]
  );

  const saveItem = useCallback(
    (payload: any) =>
      fetchData({
        url: options.isBaseUrl ? `${getSimpleUrl}/create` : url,
        method: "POST",
        payload,
      }),
    [fetchData, getSimpleUrl, options.isBaseUrl, url]
  );

  const removeItem = useCallback(
    (id: any) =>
      fetchData({
        url: itemId === undefined ? getUrl(`${url}/${id}`) : getDetailUrl,
        method: "DELETE",
      }),
    [fetchData, getDetailUrl, getUrl, itemId, url]
  );

  const updateItem = useCallback(
    (id: any, payload: any) =>
      fetchData({
        url: itemId === undefined ? getUrl(`${url}/${id}`) : getDetailUrl,
        method: "PUT",
        payload,
      }),
    [fetchData, getDetailUrl, getUrl, itemId, url]
  );

  useEffect(() => {
    setData(null);
    if (itemId) {
      fetchItem();
    }
  }, [fetchItem, setData, itemId]);

  return {
    isFetching,
    item: data,
    error,
    isPreviousItem: isPreviousData,
    fetch,
    fetchItem,
    saveItem,
    updateItem,
    removeItem,
  };
};

export default useItem;
