import { ThemeConfigOverride } from "./ThemeTypes";
import { SPECIFIC_THEME } from "../constants/Constants";

const specificProperties: ThemeConfigOverride =
  /* Mettre ici tous les thèmes embarqués dans le code */
  SPECIFIC_THEME === "fulchiron"
    ? require("./fulchiron.theme.ts")?.default
    : SPECIFIC_THEME === "parlonstravaux"
    ? require("./parlonstravaux.theme.ts")?.default
    : {};

export default specificProperties;
