import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackgroundLayout,
  Button,
  Card,
  JText,
  JTextInput,
  SView,
} from "../../components";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./SiteSuggestionScreenStyle";
import { SitesParamsList } from "navigation";
import getWording from "utils/wording";
import useConfiguration from "../../configuration/ConfigurationProvider";
import { fetchJSON } from "utils";
import { GenericModal } from "components/modals/GenericModal";

type Props = StackScreenProps<SitesParamsList, "siteSuggestion">;

export const SiteSuggestionScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);
  const { configuration } = useConfiguration();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [suggestion, setSuggestion] = useState("");

  const onSubmit = async () => {
    fetchJSON({
      url: "sites/suggest",
      method: "POST",
      payload: { content: suggestion },
    });
    setIsModalOpen(true);
  };

  return (
    <BackgroundLayout
      style={{
        marginTop: theme.normalize(-30),
        paddingTop: theme.normalize(30),
      }}
      navigation={navigation}
    >
      <SView>
        <GenericModal
          modalVisible={isModalOpen}
          setModalVisible={setIsModalOpen}
          confirmLabelKey={"modal.continue"}
          messageKey={"modal.suggestDone"}
          titleKey={"modal.thanks"}
          handleConfirm={() => {
            navigation.goBack();
          }}
        />
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText
              labelKey="auth.siteSuggest"
              valuesKey={{ wording: getWording(configuration).singular }}
              isBold
              centered
              titleLevel={2}
              titleLevelStyle={1}
              sizeName="large"
            />
            <JTextInput
              inputType="textarea"
              value={suggestion}
              onChangeValue={setSuggestion}
              placeHolder={t("common.suggestionField", {
                wording: getWording(configuration).singular,
              })}
              icon="edit"
              containerStyle={{
                marginTop: theme.normalize(theme.sizings.mediumLarge),
              }}
            />

            <Button
              type={"gradient"}
              labelKey="common.validate"
              onPress={onSubmit}
              containerStyle={styles.button}
            />
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
