import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { isHorizontalMode } from "components/utils/deviceMode";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "react-native";
import { ResetPasswordEmailCheckScreen } from "screens/profile/ResetPasswordEmailCheckScreen";
import { Clickable, Icon } from "../components";
import {
  ChangeInfosScreen,
  ChangePasswordScreen,
  LogInScreen,
  ParamsScreen,
  ResetPasswordEmailScreen,
  ResetPasswordScreen,
  SignUpScreen,
  ValidateAccountScreen,
} from "../screens";
import useTheme from "../theme/ThemeProvider";
import fnStyles from "./HeaderStyle";
import { ParamsParamsList } from "./ParamsParamsList";
import { useUser } from "hooks";

const ParamsStack = createStackNavigator<ParamsParamsList>();

export const ParamsStackNavigator: React.FC = () => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));
  const { t } = useTranslation();

  const headerLeftStyle = useMemo(
    () => ({
      marginLeft: isHorizontalMode({ width, height })
        ? 85
        : theme.normalize(theme.sizings.small),
    }),
    [height, theme, width]
  );

  const { user } = useUser();

  const headerLeft = useCallback(
    (props: any) => {
      const handleGoBack = () => {
        if (props.canGoBack) {
          props.onPress()!;
        } else {
          props.navigation.reset({
            index: 0,
            routes: [{ name: "paramsHome" }],
          });
        }
      };
      return (
        <Clickable
          onPress={handleGoBack}
          style={headerLeftStyle}
          ariaLabel="Retour à la page précédente"
        >
          <Icon
            name={"chevron-small-left"}
            type={"Entypo"}
            colorName={"white"}
            sizeName={"high"}
          />
        </Clickable>
      );
    },
    [headerLeftStyle]
  );

  return (
    <ParamsStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
        headerTitleAlign: "center",
      }}
    >
      <ParamsStack.Screen
        name={"paramsHome"}
        component={ParamsScreen}
        options={() => ({
          title: user ? user?.name ?? "" : t("header.params") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
        })}
      />
      <ParamsStack.Screen
        name={"logIn"}
        component={LogInScreen}
        options={({ navigation }) => ({
          headerStyle: styles.container,
          title: t("header.connection") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: (props) => headerLeft({ ...props, navigation }),
        })}
      />
      <ParamsStack.Screen
        name={"signUp"}
        component={SignUpScreen}
        options={({ navigation }) => ({
          headerStyle: styles.container,
          title: t("header.signUp") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: (props) => headerLeft({ ...props, navigation }),
        })}
      />
      <ParamsStack.Screen
        name={"resetPassword"}
        component={ResetPasswordScreen}
        options={({ navigation }) => ({
          headerStyle: styles.container,
          title: t("header.resetPassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: (props) => headerLeft({ ...props, navigation }),
        })}
      />
      <ParamsStack.Screen
        name={"resetPasswordEmail"}
        component={ResetPasswordEmailScreen}
        options={({ navigation }) => ({
          headerStyle: styles.container,
          title: t("header.resetPassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: (props) => headerLeft({ ...props, navigation }),
        })}
      />
      <ParamsStack.Screen
        name={"resetPasswordEmailCheck"}
        component={ResetPasswordEmailCheckScreen}
        options={({ navigation }) => ({
          headerStyle: styles.container,
          title: t("header.resetPassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: (props) => headerLeft({ ...props, navigation }),
        })}
      />
      <ParamsStack.Screen
        name={"changePassword"}
        component={ChangePasswordScreen}
        options={({ navigation }) => ({
          headerStyle: styles.container,
          title: t("header.changePassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: (props) => headerLeft({ ...props, navigation }),
        })}
      />
      <ParamsStack.Screen
        name={"changeInfos"}
        component={ChangeInfosScreen}
        options={({ navigation }) => ({
          headerStyle: styles.container,
          title: t("header.changeInfos") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: (props) => headerLeft({ ...props, navigation }),
        })}
      />
      <ParamsStack.Screen
        name={"validateAccount"}
        component={ValidateAccountScreen}
        options={{
          title: t("header.validateAccount") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
        }}
      />
    </ParamsStack.Navigator>
  );
};
