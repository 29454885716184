import useTheme from "../../../theme/ThemeProvider";
import { ColorType, FontSizeType } from "theme/ThemeTypes";
import { JText } from "../text";
import { Icon } from "../icon";
import React, { useState } from "react";
import fnStyles from "./TileStyle";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { Types } from "../../../types";
import { Clickable } from "../clickable";
import { I18nKey } from "../../../../i18n";

type Props = {
  iconType?: Types["iconTypes"];
  iconName?: string;
  iconSizeName?: FontSizeType;
  iconColorName?: ColorType;
  label?: string;
  labelKey?: I18nKey;
  color?: ColorType;
  onPress?: () => void;
  selectable?: boolean;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ViewStyle>;
};

export const Tile: React.FC<Props> = ({
  iconType = "FontAwesome",
  iconName,
  iconSizeName,
  iconColorName,
  label,
  labelKey,
  color = "primary",
  onPress,
  selectable = false,
  style,
  labelStyle,
  iconStyle,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme, color);
  const [selected, setSelected] = useState<boolean>();

  const handleClick = () => {
    if (selectable) setSelected((prevState) => !prevState);
    if (onPress) onPress();
  };

  return (
    <Clickable
      style={[styles.container, selected && styles.selected, style]}
      onPress={handleClick}
    >
      <View style={styles.row}>
        {iconName && (
          <Icon
            name={iconName ?? ""}
            type={iconType}
            sizeName={iconSizeName}
            style={[styles.icon, iconStyle]}
            colorName={selected ? "white" : iconColorName}
          />
        )}
        {(label || labelKey) && (
          <JText
            label={label}
            labelKey={labelKey}
            sizeName={"mediumLarge"}
            fontWeight={"500"}
            style={[styles.text, selected && styles.textSelected, labelStyle]}
          />
        )}
      </View>
    </Clickable>
  );
};
