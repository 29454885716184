import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, noShadow: boolean) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.white,
      padding: theme.normalize(theme.sizings.medium),
      borderRadius: theme.normalize(theme.radius.medium),
      shadowColor: theme.colors.dark,
      shadowOffset: noShadow
        ? undefined
        : {
            width: 0,
            height: 1,
          },
      shadowOpacity: noShadow ? undefined : 0.2,
      shadowRadius: noShadow ? undefined : 1.41,
      elevation: noShadow ? undefined : 2,
    },
  });
