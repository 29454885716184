import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    modalTitle: {
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "center",
    },
    modalTitleIcon: {
      marginRight: theme.sizings.small,
      color:theme.colors.dark
    },
  });
