import { useCallback, useState } from "react";
import moment, { MomentInput } from "moment";
import qs from "qs";
import { CancellablePromise, parseStrapiFormat } from "../utils";
import useFetch, { UseFetchOptions } from "./useFetch";

interface Map {
  [key: string]: string | number | boolean | object;
}

export type UseListOptions = {
  defaultFilters?: Map;
  defaultSort?: string;
  defaultGroupBy?: string;
  defaultLimit?: number;
  populate?: string | object;
  fields?: string | object;
} & UseFetchOptions;

export type UseListType<T> = {
  items: T[] | null;
  setItems: (items: T[]) => void;
  allItemsFetched: boolean;
  isPreviousItems: boolean;
  isItemsValid: boolean;
  error: string | null;
  isFetching: boolean;
  fetchItems: (...args: any[]) => CancellablePromise<unknown>;

  pageCount: number;
  pageIndex: number;
  itemsCount: number;
  setPageIndex: (value: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;

  sort: string | undefined;
  setSort: ((value: string | undefined) => void) | undefined;

  groupBy: string | undefined;
  setGroupBy: ((value: string | undefined) => void) | undefined;

  filters: Map;
  setFilter: (key: string, value: number | boolean | string) => void;
  setFilters: (value: Map) => void;

  removeListItem: (id: number | string) => void;
  addListItem: (item: T) => void;

  fetchNextItems: () => void;
};

const useList = <T>(
  url: string,
  options: UseListOptions = {}
): UseListType<T> => {
  const {
    defaultFilters = {},
    defaultSort,
    defaultGroupBy,
    defaultLimit = 20,
    populate = null,
    fields = null,
    ...opts
  } = options;

  opts.sharePromise = false;

  opts.cachePrefix = opts.cachePrefix ?? `list_${url.replace(/[/?&]/g, "_")}`;

  const [itemsCount, setItemsCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(defaultLimit);
  const [filters, setFilters] = useState(defaultFilters);
  const [sort, setSort] = useState(defaultSort);
  const [groupBy, setGroupBy] = useState(defaultGroupBy);
  const [items, setItems] = useState<T[]>([]);

  opts.postExecute = useCallback(
    (data: any) => {
      let newItems: any = null;
      if (Array.isArray(data)) {
        setItemsCount(data.length);
        newItems = data;
      } else {
        setItemsCount(data.meta.pagination.total);
        newItems = parseStrapiFormat(data);
      }
      // le concept de page index === 1 pour les filtres ne fonctionnent pas, on se retrouve tout le temps dans l'autre cas
      setItems((oldItems) => {
        if (newItems) {
          if (pageIndex === 0) {
            return newItems;
          }
          // const mergedItems = [...oldItems, ...newItems];
          // return mergedItems;
          return newItems;
        }
        return oldItems;
      });

      return newItems;
    },
    [pageIndex]
  );

  const getUrl = () => {
    const query = {
      sort: [],
      filters: {},
      pagination: {
        page: pageIndex + 1,
        pageSize: itemsPerPage as number,
      },
    } as {
      sort: string[];
      filters: {
        [key: string]:
          | {
              [key: string]: string | number | boolean;
            }
          | object;
      };
      pagination?: {
        page: number;
        pageSize: number;
      };
      populate?: string | object;
      fields?: string | object;
    };

    if (populate) {
      query.populate = populate;
    }
    if (fields) {
      query.fields = fields;
    }

    if (groupBy) {
      query.sort.push(groupBy);
    }
    if (sort) {
      query.sort.push(sort);
    }

    Object.keys(filters)
      .filter((f: string) => !f.startsWith("__"))
      .forEach((filter) => {
        if (typeof filters[filter] === "string") {
          const [fieldName, operator = "eq"] = filter.split("$");
          query.filters[fieldName] = { [`$${operator}`]: filters[filter] };
        } else if (
          Object.prototype.toString.call(filters[filter]) === "[object Date]" &&
          !filter.includes("$gte") &&
          !filter.includes("$lte")
        ) {
          const startDay = moment(filters[filter] as MomentInput)
            .startOf("day")
            .set("hour", 12)
            .toDate();
          startDay.setUTCHours(0, 0, 0, 0);
          const endDay = moment(filters[filter] as MomentInput)
            .startOf("day")
            .set("hour", 12)
            .toDate();
          endDay.setUTCHours(23, 59, 59, 999);
          query.filters[filter] = {
            $gte: startDay,
            $lte: endDay,
          };
        } else if (
          Object.prototype.toString.call(filters[filter]) === "[object Date]" &&
          (filter.includes("$gte") || filter.includes("$lte"))
        ) {
          const date = moment(filters[filter] as MomentInput)
            .startOf("day")
            .set("hour", 12)
            .toDate();
          if (filter.includes("$gte")) {
            date.setUTCHours(0, 0, 0, 0);
          } else {
            date.setUTCHours(23, 59, 59, 999);
          }
          query.filters[filter] = date;
        } else {
          query.filters[filter] = filters[filter] as object;
        }
      });

    const queryParams = qs.stringify(query, {
      encodeValuesOnly: true,
    });

    return `${url}${queryParams ? "?" : ""}${queryParams}`;
  };

  const { isDataValid, error, setData, isFetching, isPreviousData, fetchData } =
    useFetch<T[]>(getUrl(), opts);

  const setFilter = useCallback(
    (key: any, value: any) => {
      setFilters((prevFilters: Map) => {
        if (value) {
          return { ...prevFilters, [key]: value };
        }
        const newFilters = { ...prevFilters };

        delete newFilters[key];
        return newFilters;
      });
      setPageIndex(0);
    },
    [setFilters, setPageIndex]
  );

  const removeListItem = useCallback(
    (id: any) => {
      setData((oldData: T[]) =>
        oldData.filter((d) => (d as T & { id: string | number }).id !== id)
      );
    },
    [setData]
  );
  const addListItem = useCallback(
    (item: T) => {
      setData((oldData: T[]) => (oldData ?? []).concat(item));
    },
    [setData]
  );

  const allItemsFetched = itemsCount - pageIndex * itemsPerPage <= 0;

  const fetchNextItems = useCallback(() => {
    setPageIndex((prev) => prev + 1);
  }, [setPageIndex]);

  return {
    items,
    setItems,
    isItemsValid: isDataValid,
    isPreviousItems: isPreviousData,
    error,
    isFetching,
    fetchItems: fetchData,

    pageCount: Math.ceil(itemsCount / itemsPerPage),
    pageIndex,
    setPageIndex,
    itemsPerPage,
    itemsCount,
    setItemsPerPage,
    allItemsFetched,

    sort,
    setSort,

    groupBy,
    setGroupBy,

    filters,
    setFilter,
    setFilters,

    removeListItem,
    addListItem,

    fetchNextItems,
  };
};

export default useList;
