import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { NewChatButton } from "components/chat/NewChatButton";
import React, { useCallback, useState } from "react";
import { Message } from "types/Message";
import {
  BackgroundLayout,
  LoadingScreen,
  NoThread,
  ThreadList,
} from "../../components";
import { useList } from "../../hooks";
import useThread from "../../hooks/useThread";
import { ChatParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import { Thread } from "../../types/ThreadType";
import { fetchJSON } from "../../utils";

type Props = StackScreenProps<ChatParamsList, "chatHome">;

export const ChatScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();

  const [refreshing, setRefreshing] = useState(false);

  const { setThread } = useThread();

  const {
    items: threads,
    fetchItems: fetchThreads,
    isFetching,
  } = useList<Thread>("threads/me");

  useFocusEffect(
    useCallback(() => {
      fetchThreads();
    }, [fetchThreads])
  );

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchThreads().then(() => {
      setRefreshing(false);
    });
  }, [fetchThreads]);

  const onCardPressed = async (thread: Thread) => {
    if (!thread.isRead || thread.messages?.find((m: Message) => !m.isRead)) {
      await fetchJSON({
        url: "threads/acknowledge",
        method: "POST",
        payload: { data: { id: thread.id } },
      });
    }
    setThread(thread);
    navigation.navigate("reactMessage", {
      threadId: thread.id,
    });
  };

  const onChatNav = () => {
    navigation.navigate("chatSelectSiteOrPlace");
  };

  return (
    <>
      <BackgroundLayout
        style={{
          marginTop: theme.normalize(-30),
          paddingTop: theme.normalize(30),
        }}
      >
        <NewChatButton
          handleNav={onChatNav}
          navigation={navigation}
          bottomBar={true}
        />

        {isFetching && !refreshing && <LoadingScreen />}
        {!isFetching && threads && threads.length <= 0 && <NoThread />}
        {!isFetching && threads && threads.length > 0 && (
          <ThreadList
            data={threads}
            onPress={onCardPressed}
            refreshing={refreshing}
            onRefresh={onRefresh}
            contentContainerStyle={{
              marginTop: theme.normalize(30),
              paddingBottom: theme.normalize(200),
            }}
          />
        )}
      </BackgroundLayout>
    </>
  );
};
