import React from "react";
import { View, ImageBackground } from "react-native";
import { JText, Spacer } from "../jlib";
import fnStyles from "./NoThreadStyle";
import useTheme from "../../theme/ThemeProvider";

type Props = {
  //navigation: StackNavigationProp<ChatParamsList, "chatHome">;
};

export const NoThread: React.FC<Props> = () => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={theme.images.pictoSearch}
        style={{
          marginTop: theme.normalize(100),
          height: theme.normalize(120),
          width: theme.normalize(120),
        }}
      />

      <Spacer height="high" backgroundColor={"transparent"} />

      <JText labelKey="chat.noThread" centered style={styles.text} />
    </View>
  );
};
