import { SPECIFIC_THEME } from "../constants/Constants";

const specificConfiguration =
  /* Mettre ici tous les thèmes embarqués dans le code */
  SPECIFIC_THEME === "fulchiron"
    ? require("./fulchiron.configuration.ts")?.default
    : SPECIFIC_THEME === "parlonstravaux"
    ? require("./parlonstravaux.configuration.ts")?.default
    : {};

export default specificConfiguration;
