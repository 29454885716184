import React from "react";
import { View } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import { JText } from "../../jlib";
import fnStyles from "./DateStyle";
import dayjs from "dayjs";

const monthNames = [
  "jan",
  "fév",
  "mar",
  "avr",
  "mai",
  "juin",
  "juil",
  "août",
  "sep",
  "oct",
  "nov",
  "déc",
];

type Props = {
  date: Date;
  type: "classic" | "large";
  hideHours?: boolean;
};

export const DateItem: React.FC<Props> = ({
  date,
  type = "classic",
  hideHours = false,
}) => {
  const { theme } = useTheme();
  const isLarge = type === "large";
  const styles = fnStyles(theme, isLarge);

  return (
    <View style={styles.item}>
      <JText
        label={new Date(date).getDate().toString()}
        isBold
        sizeName={isLarge ? "large" : "largeLow"}
        colorName={"dark"}
        style={styles.day}
      />

      <View style={styles.monthAndYear}>
        <JText
          label={monthNames[new Date(date).getMonth()]}
          isBold
          sizeName={isLarge ? "mediumLarge" : "medium"}
          colorName="greyDark"
          style={styles.month}
        />
        <JText
          label={new Date(date).getFullYear().toString()}
          isBold
          sizeName={isLarge ? "mediumLarge" : "smallMedium"}
          colorName="greyDark"
          style={styles.year}
        />
      </View>

      {!hideHours && (
        <View style={styles.monthAndYear}>
          <JText
            label={dayjs(date).format("HH:mm")}
            isBold
            sizeName={isLarge ? "mediumLarge" : "medium"}
            colorName="greyDark"
            style={styles.month}
          />
        </View>
      )}
    </View>
  );
};
