export function displayPhoneNumber(input: string) {
  let sanitizedInput = input.replace(/\s+/g, "");

  let formattedString = "";
  for (let i = 0; i < sanitizedInput.length; i += 2) {
    if (i + 2 <= sanitizedInput.length) {
      formattedString += sanitizedInput.substring(i, i + 2) + " ";
    } else {
      formattedString += sanitizedInput.substring(i, i + 2);
    }
  }

  return formattedString.trim();
}
