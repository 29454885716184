import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, Text } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Icon, Stack } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 120,
    marginTop: 16,
  },
});

const StackScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Stack" description="Renders a stack.">
        <Stack
          frontView={<Text>Star</Text>}
          backView={<Icon name={"star"} type={"AntDesign"} />}
        />
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<Stack\n" +
            "  frontView={<Text>Star</Text>}\n" +
            '  backView={<Icon name={"star"} type={"AntDesign"} />}\n' +
            "/>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="backView?"
        description="The component displayed in the back of the stack."
        type="React.ReactNode | React.ReactNode[]"
        topDivider={false}
      />
      <CatalogProps
        propName="frontView?"
        description="The component displayed in the front of the stack."
        type="React.ReactNode | React.ReactNode[]"
      />
      <CatalogProps
        propName="backViewStyle?"
        description="The style of component displayed in the back of the stack."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="frontViewStyle?"
        description="The style of component displayed in the front of the stack."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="containerStyle?"
        description="The style of component."
        type="StyleProp<ViewStyle>"
      />
    </ScrollView>
  );
};

export default StackScreen;
