import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import fnStyles from "./DividerStyle";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType, SizingsType } from "theme/ThemeTypes";

type Props = {
  colorName?: ColorType;
  vertical?: boolean;
  margin?: SizingsType;
  padding?: SizingsType;
  style?: StyleProp<ViewStyle>;
};

export const Divider = ({
  colorName,
  vertical = false,
  margin,
  padding,
  style,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles();
  const color = colorName ? theme.colors[colorName] : "rgba(0,0,0,0.15)";
  const marginValue = margin ? theme.sizings[margin] : 0;
  const paddingValue = padding ? theme.sizings[padding] : 0;

  return (
    <View
      style={[
        vertical ? styles.dividerVertical : styles.dividerHorizontal,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor: color,
          marginVertical: !vertical ? theme.normalize(marginValue) : 0,
          marginHorizontal: vertical ? theme.normalize(marginValue) : 0,
          paddingVertical: vertical ? theme.normalize(paddingValue) : 0,
          paddingHorizontal: !vertical ? theme.normalize(paddingValue) : 0,
        },
        style,
      ]}
    />
  );
};
