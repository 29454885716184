import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isLarge: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: isLarge ? "100%" : undefined,
    },
    item: {
      backgroundColor: isLarge ? theme.colors.infoLight : theme.colors.white,
      borderRadius: 15,
      paddingVertical: theme.normalize(theme.sizings.smallMedium),
      paddingHorizontal: theme.normalize(theme.sizings.smallMedium),
      alignItems: "center",
      flex: isLarge ? 1 : undefined,
    },
    day: {
      lineHeight: isLarge
        ? theme.fontSizes.large * 1.2
        : theme.fontSizes.largeLow * 1.2,
    },
    month: {
      lineHeight: isLarge
        ? theme.fontSizes.mediumLarge * 1.2
        : theme.fontSizes.medium * 1.2,
    },
    year: {
      lineHeight: isLarge
        ? theme.fontSizes.mediumLarge * 1.2
        : theme.fontSizes.smallMedium * 1.2,
    },
    monthAndYear: {
      flexDirection: isLarge ? "row" : "column",
      alignItems: isLarge ? "center" : "center",
      gap: isLarge ? theme.sizings.small : theme.sizings.tiny / 2,
      marginTop: isLarge ? theme.sizings.small : -theme.sizings.tiny,
    },
  });
