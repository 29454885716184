import { Platform } from "react-native";
import { REACT_APP_API_ENDPOINT } from "../constants";
import { getStoredData } from "./fnAsyncStorage";
import ExifReader from "../../node_modules/exifreader/src/exif-reader";

const blobToArrayBuffer = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
};

const getExifData = async (uri: any, id: string) => {
  try {
    let arrayBuffer;
    if (Platform.OS !== "web") {
      const response = await fetch(uri);
      const blob = await response.blob();
      arrayBuffer = await blobToArrayBuffer(blob);
    } else {
      const response = await fetch(uri);
      arrayBuffer = await response.arrayBuffer();
    }

    const tags = await ExifReader.load(arrayBuffer, { expanded: true });

    if (tags && tags?.gps) {
      return {
        id,
        lat: tags.gps.Latitude,
        lng: tags.gps.Longitude,
        gmapsUrl: `https://www.google.com/maps?q=${tags.gps.Latitude},${tags.gps.Longitude}`,
      };
    }

    return false;
  } catch (error) {
    console.log("Erreur lors de la récupération des données EXIF", error);
  }
};

// const toDecimal = (
//   degrees: number,
//   minutes: number,
//   seconds: number,
//   direction: string
// ) => {
//   let decimal = degrees + minutes / 60 + seconds / 3600;
//   if (direction === "S" || direction === "W") {
//     decimal *= -1;
//   }
//   return decimal;
// };

export const uploadImage = async (imageResult: any) => {
  let file: any = null;
  try {
    if (Platform.OS !== "web") {
      const localUri = imageResult.uri;
      const filename = localUri.split("/").pop() ?? "";
      // Infer the type of the image
      const match = /\.(\w+)$/.exec(filename);
      const type = match ? `image/${match[1]}` : "image";

      file = {
        uri: localUri,
        name: filename,
        type,
      };
    } else {
      const response = await fetch(imageResult.uri);
      file = await response.blob();
      console.log(file);
    }
    const formData = new FormData();
    formData.append("files", file);

    const apiUrl = REACT_APP_API_ENDPOINT;
    const token = await getStoredData("token");

    const headers = new Headers();

    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }

    const res = await fetch(`${apiUrl}/upload`, {
      method: "POST",
      headers,
      body: formData,
    });

    const json = await res.json();
    return json?.[0];
  } catch (e) {
    console.error(e);
  }
};

export const uploadImages = async (imageResults: any[]) => {
  const formData = new FormData();

  console.log({ imageResults });
  for (const imageResult of imageResults) {
    console.log({ imageResult });
    let file: any = null;
    try {
      if (Platform.OS !== "web") {
        const localUri = imageResult.uri;
        const filename = localUri.split("/").pop() ?? "";
        const match = /\.(\w+)$/.exec(filename);
        const type = match ? `image/${match[1]}` : "image";

        file = {
          uri: localUri,
          name: filename,
          type,
        };
      } else {
        const response = await fetch(imageResult.uri);
        file = await response.blob();
      }

      formData.append("files", file);
    } catch (e) {
      console.error(e);
    }
  }

  try {
    const apiUrl = REACT_APP_API_ENDPOINT;
    const token = await getStoredData("token");

    const headers = new Headers();

    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }

    const res = await fetch(`${apiUrl}/upload`, {
      method: "POST",
      headers,
      body: formData,
    });

    const json = await res.json();
    let exifData = [];
    if (json && json.length > 0) {
      for await (const [idx, img] of json.entries()) {
        if (imageResults[idx].exif) {
          const lat = imageResults[idx].exif.GPSLatitude;
          const lng = imageResults[idx].exif.GPSLongitude;
          if (lat && lng) {
            exifData.push({
              id: img.id,
              lat,
              lng,
              gmapsUrl: `https://www.google.com/maps?q=${lat},${lng}`,
            });
          }
        } else {
          const imgExifData = await getExifData(imageResults[idx].uri, img.id);
          if (imgExifData) exifData.push(imgExifData);
        }
      }
    }
    return { images: json, exif: exifData };
  } catch (e) {
    console.error(e);
    return { images: undefined, exif: undefined };
  }
};
