import React from "react";
import { TextStyle, StyleProp, ViewStyle } from "react-native";
import { I18nKey } from "../../../../i18n";
import { Clickable } from "../clickable";
import { useTranslation } from "react-i18next";
import { Row } from "../row";
import { JText } from "../text";

export type Annotation = {
  label?: string;
  labelKey?: I18nKey;
  annotated?: boolean;
  onPress?: () => void;
};

type Props = {
  annotations: Annotation[];
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  annotatedTextStyle?: StyleProp<TextStyle>;
};

export const AnnotatedText: React.FC<Props> = ({
  annotations,
  style = {},
  textStyle = {},
  annotatedTextStyle = {},
}: Props) => {
  const { t } = useTranslation();

  return (
    <Row style={style}>
      {annotations.map((annotation: Annotation, index: number) => {
        const isLast = index === annotations.length - 1;
        if (annotation.annotated) {
          return (
            <Clickable onPress={annotation.onPress ?? (() => {})} key={index}>
              <JText
                label={
                  (annotation.labelKey
                    ? t(annotation.labelKey)
                    : annotation.label) + (isLast ? "" : " ")
                }
                colorName={"emphasisText"}
                style={[
                  // eslint-disable-next-line react-native/no-inline-styles
                  { textDecorationLine: "underline" },
                  annotatedTextStyle,
                ]}
              />
            </Clickable>
          );
        } else {
          return (
            <JText
              label={
                (annotation.labelKey
                  ? t(annotation.labelKey)
                  : annotation.label) + (isLast ? "" : " ")
              }
              style={textStyle}
              key={index}
            />
          );
        }
      })}
    </Row>
  );
};
