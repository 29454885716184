import React, { ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Controller } from "react-hook-form";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType } from "theme/ThemeTypes";
import fnStyles from "./FormControlStyle";
// import { TileSelect } from "./TileSelect";
import { Types } from "../../../types";
import { JTextInput } from "../textinput";
import { JText } from "../text";
// import { AutocompleteSelect } from "./AutocompleteSelect";
// import { GenericSelect } from "./GenericSelect";
// import { SimpleList } from "./SimpleList";

export type SelectOptions = {
  value: string | number | boolean;
  label: string;
  icon?: {
    color: ColorType;
    type: Types["iconTypes"];
    name: string;
  };
};

type Props = {
  control: any;
  errors: any;
  formStep?: number;
  nextFormStep?: () => void;
  type: String;
  children?: ReactNode;
  label?: string;
  fieldName: string;
  required?: boolean;
  inLine?: boolean;
  options?: SelectOptions[] | any[];
  keyboardType?: Types["keyboardTypes"];
  inputType?: Types["inputTypes"];
  multiple?: boolean;
  register?: any;
  placeHolder?: string;
  style?: StyleProp<ViewStyle>;
};

export const FormControl: React.FC<Props> = ({
  control,
  errors,
  type,
  children,
  label,
  fieldName,
  required = false,
  inLine = false,
  // options,
  keyboardType = "default",
  inputType = "default",
  // multiple = false,
  // register,
  placeHolder = "",
  style,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  /*const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });*/

  const fieldByType = (onChange: any, onBlur: any, value: any) => {
    switch (type) {
      /* case "tile-select":
        return (
          <TileSelect
            options={options || []}
            value={value}
            onPress={onChange}
          />
        );
      case "autocomplete-select":
        return (
          <AutocompleteSelect
            multiple={multiple}
            options={options || []}
            onPress={onChange}
            append={append}
            remove={remove}
            fieldName={fieldName}
            control={control}
            register={register}
            fields={fields}
            errors={errors}
          />
        );
      case "generic-autocomplete":
        return (
          <GenericSelect
            onChange={onChange}
            options={options || []}
            value={value}
          />
        );
      case "simple-list":
        return (
          <SimpleList
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            keyboardType={keyboardType}
            control={control}
            errors={errors}
            fieldName={fieldName}
            fields={fields || []}
            append={append}
            remove={remove}
            register={register}
          />
        );*/
      case "text-input":
        return (
          <JTextInput
            onBlur={onBlur}
            onChangeValue={onChange}
            value={value}
            /*containerStyle={[
              styles.textInputContainer,
              inLine && styles.inLineTextInput,
            ]}
            textInputStyle={styles.textInput}*/
            keyboardType={keyboardType}
            placeHolder={placeHolder}
            inputType={inputType}
          />
        );
      default:
        return (
          <JTextInput
            onBlur={onBlur}
            onChangeValue={onChange}
            value={value}
            containerStyle={[
              styles.textInputContainer,
              inLine && styles.inLineTextInput,
            ]}
            textInputStyle={styles.textInput}
            keyboardType={keyboardType}
            placeHolder={placeHolder}
          />
        );
    }
  };

  return (
    <View style={[styles.field, style]}>
      <Controller
        control={control}
        rules={{
          required: required ? "Ce champ est requis." : false,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={inLine ? styles.inLineField : undefined}>
            {label && (
              <JText
                label={label}
                style={styles.fieldTitle}
                fontWeight={"500"}
              />
            )}
            {fieldByType(onChange, onBlur, value)}
            {children ?? null}
          </View>
        )}
        name={fieldName}
      />
      {errors && errors.message && (
        <JText label={errors.message} style={styles.fieldError} />
      )}
    </View>
  );
};
