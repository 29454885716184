import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Icon, Repeatable } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 100,
    marginTop: 16,
  },
});

const RepeatableScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Repeatable"
        description="This component allows to repeat the children of the component x number of times."
      >
        <Repeatable times={5} spacing={1}>
          <Icon name={"star"} type={"AntDesign"} />
        </Repeatable>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<Repeatable times={5} spacing={1}>\n" +
            '  <Icon name={"star"} type={"AntDesign"} />\n' +
            "</Repeatable>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="times"
        description="The number of times the component will repeat the children. (default to 1)"
        type="number"
        topDivider={false}
      />
      <CatalogProps
        propName="direction?"
        description="The direction used to repeat the component's children."
        type='"column" | "row" | "row-reverse" | "column-reverse"'
      />
      <CatalogProps
        propName="spacing?"
        description="The space between repeated children. (default to 0)"
        type="number"
      />
      <CatalogProps
        propName="style?"
        description="The style of the component."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="containerStyle?"
        description="The style of the container."
        type="StyleProp<ViewStyle>"
      />
    </ScrollView>
  );
};

export default RepeatableScreen;
