import { StackScreenProps } from "@react-navigation/stack";
import React, { useEffect, useState } from "react";
import {
  BackgroundLayout,
  Button,
  Card,
  Checkbox,
  Clickable,
  JText,
  JTextInput,
  Row,
  SView,
  Spacer,
} from "../../components";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./SignUpScreenStyle";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { useTranslation } from "react-i18next";
import { Password } from "../../components/common/password";
import JKeyboardAvoidingView from "components/common/JKeyboardAvoidingView/JKeyboardAvoidingView";
import { UserTypesPicker } from "components/common/userTypesPicker";
import useConfiguration from "../../configuration/ConfigurationProvider";
import * as Linking from "expo-linking";
import { REACT_APP_FRONTEND } from "../../constants/Constants";
import { Platform, View } from "react-native";

type Props = StackScreenProps<ParamsParamsList, "signUp">;

export const SignUpScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const { configuration } = useConfiguration();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [canCreateAccount, setCanCreateAccount] = useState(false);
  const [userType, setUserType] = useState<string | undefined>(undefined);
  const [isAboveFifteen, setIsAboveFifteen] = useState<boolean>(
    configuration?.profile?.features?.enableAboveFifteen ? false : true
  );
  const [hasAcceptedCGU, setHasAcceptedCGU] = useState<boolean>(false);

  const { register, errorMessage, cleanError } = useUser();

  useEffect(() => {
    cleanError();
  }, [cleanError]);

  const onSubmit = async () => {
    const formatedData: any = {};

    formatedData.email = email;
    formatedData.username = email; // unic username as unic email
    formatedData.password = password;
    formatedData.name = name;
    formatedData.isAboveFifteen = isAboveFifteen;
    formatedData.hasAcceptedCGU = hasAcceptedCGU;

    if (userType !== undefined) {
      formatedData.userType = userType;
    }

    try {
      const res = await register(formatedData);

      if (res.user) {
        navigation.navigate("validateAccount");
      }
    } catch (e: any) {
      console.error({ e });
    }
  };

  const onLogInButtonPressed = () => {
    navigation.navigate("logIn");
  };

  useEffect(() => {
    if (
      email.length > 0 &&
      name.length > 0 &&
      password.length > 0 &&
      password === confirmPassword &&
      isPasswordValid &&
      hasAcceptedCGU &&
      isAboveFifteen
    ) {
      setCanCreateAccount(true);
    } else {
      setCanCreateAccount(false);
    }
  }, [
    confirmPassword,
    email,
    name,
    password,
    isPasswordValid,
    isAboveFifteen,
    hasAcceptedCGU,
  ]);

  return (
    <BackgroundLayout
      style={{
        marginTop: theme.normalize(-30),
        paddingTop: theme.normalize(30),
      }}
    >
      <JKeyboardAvoidingView offset={-220}>
        <SView>
          <BoxedLayout style={styles.contentContainer}>
            <Card>
              <JText
                labelKey="auth.createAccount"
                isBold
                centered
                titleLevel={2}
                titleLevelStyle={2}
              />
              <JTextInput
                icon="user"
                value={name}
                onChangeValue={setName}
                placeHolder={`${t("common.username")}*`}
                containerStyle={{
                  marginTop: theme.normalize(theme.sizings.mediumLarge),
                }}
              />

              <Spacer height="medium" backgroundColor={"transparent"} />

              <JText
                labelKey={
                  configuration?.privacy
                    ? undefined
                    : "phrases.privateDataDisclaimer"
                }
                label={configuration?.privacy?.dataDisclamer}
                sizeName="mediumLarge"
              />

              <JTextInput
                icon="envelope"
                value={email}
                onChangeValue={setEmail}
                placeHolder={`${t("common.email")}*`}
                keyboardType="email-address"
                containerStyle={{
                  marginTop: theme.normalize(theme.sizings.mediumLarge),
                }}
                autocomplete="email"
              />

              <Password
                value={password}
                onChangeValue={setPassword}
                setIsPasswordValid={setIsPasswordValid}
                isPasswordValid={isPasswordValid}
                placeHolder={`${t("auth.password")}*`}
                containerStyle={{
                  marginTop: theme.normalize(theme.sizings.medium),
                }}
                autocomplete="new-password"
              />

              <JTextInput
                icon="key"
                value={confirmPassword}
                onChangeValue={setConfirmPassword}
                inputType={"password"}
                placeHolder={`${t("auth.confirmPassword")}*`}
                containerStyle={{
                  marginTop: theme.normalize(theme.sizings.medium),
                }}
              />

              {confirmPassword !== "" && password !== confirmPassword && (
                <JText
                  labelKey="auth.confirmPasswordError"
                  colorName={"danger"}
                  sizeName="mediumLarge"
                />
              )}

              {configuration &&
                configuration?.profile?.features?.hasUserType && (
                  <UserTypesPicker
                    userType={userType}
                    setUserType={setUserType}
                  />
                )}
              <Spacer height="medium" backgroundColor={"transparent"} />

              <Row align="center">
                <Checkbox
                  value={hasAcceptedCGU}
                  onPress={() => setHasAcceptedCGU((prev) => !prev)}
                  onColor={theme.colors.gradientEnd}
                  adaptToPlatform
                />
                <JText labelKey={"auth.confirmCGU"} />
                <Clickable
                  style={{
                    alignItems: "center",
                  }}
                  onPress={() => {
                    if (Platform.OS === "web") {
                      window.open(
                        `${REACT_APP_FRONTEND}/cgu${theme.texts.appName}.html`,
                        "_blank"
                      );
                    } else {
                      Linking.openURL(
                        `${REACT_APP_FRONTEND}/cgu${theme.texts.appName}.html`
                      );
                    }
                  }}
                >
                  <JText
                    label="CGU"
                    style={{
                      color: theme.colors.gradientEnd,
                      fontSize: theme.fontSizes.large,
                      fontWeight: "400",
                    }}
                  />
                </Clickable>
              </Row>

              {configuration &&
                configuration?.profile?.features?.enableAboveFifteen && (
                  <Checkbox
                    value={isAboveFifteen}
                    onPress={() => setIsAboveFifteen((prev) => !prev)}
                    labelKey={"auth.confirmAboveFifteen"}
                    onColor={theme.colors.gradientEnd}
                    adaptToPlatform
                    style={{ marginBottom: theme.sizings.larger }}
                  />
                )}

              {/* <Spacer height="medium" backgroundColor={"transparent"} /> */}

              {errorMessage && (
                <>
                  <JText
                    label={errorMessage}
                    colorName={"danger"}
                    sizeName="mediumLarge"
                    centered
                    role="alert"
                  />
                  <JText
                    labelKey="common.persistingError"
                    colorName={"danger"}
                    sizeName="mediumLarge"
                    centered
                    role="alert"
                  />
                  <Spacer height="medium" backgroundColor={"transparent"} />
                </>
              )}
              <Button
                type={"gradient"}
                labelKey="auth.signUp"
                onPress={onSubmit}
                containerStyle={styles.button}
                disabled={!canCreateAccount}
              />
              <Clickable onPress={onLogInButtonPressed}>
                <JText
                  labelKey="auth.alreadyAnAccount"
                  colorName="emphasisText"
                  centered
                />
              </Clickable>
              <View>
                <JText labelKey="common.requiredFields" sizeName={"medium"} />
              </View>
            </Card>
          </BoxedLayout>
        </SView>
      </JKeyboardAvoidingView>
    </BackgroundLayout>
  );
};
