import { StyleSheet } from "react-native";

export default () =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column",
    },
    background: {
      flex: 1,
      justifyContent: "center",
    },
  });
