export const isDesktopMode = (windowDimension: any) =>
  windowDimension.width > 1023;

export const isMobileMode = (windowDimension: any) =>
  windowDimension.width < 768;

export const isTabletMode = (windowDimension: any) =>
  windowDimension.width > 767 && windowDimension.width < 1023;

export const isHorizontalMode = (windowDimension: any) =>
  windowDimension.width > windowDimension.height;
//|| isTabletMode(windowDimension) ||
//isDesktopMode(windowDimension);

export default isDesktopMode;
