import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, Text } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Expandable } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 100,
    marginTop: 16,
  },
});

const ExpandableScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Expandable"
        description="This component renders an expandable section with a title."
      >
        <Expandable title={"An expandable section"}>
          <Text>Hidden Text</Text>
        </Expandable>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            '<Expandable title={"An expandable section"}>\n' +
            "  <Text>Hidden Text</Text>\n" +
            "</Expandable>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="title?"
        description="The title of the component."
        type="string"
        topDivider={false}
      />
      <CatalogProps
        propName="titleKey?"
        description="The I18n key of the label from of title of the component."
        type="I18nKey"
      />
      <CatalogProps
        propName="titleSize?"
        description="The fontsize of the title. It has to be one of the fontsize from the Theme. (default to mediumLarge)"
        type="FontSizeType"
      />
      <CatalogProps
        propName="titleFontWeight?"
        description="The font weight of the title. It has to be one the font weight defined in the Types. (default to 600)"
        type='Types["fontWeights"]'
      />
      <CatalogProps
        propName="titleStyle?"
        description="The style of the title."
        type="StyleProp<TextStyle>"
      />
      <CatalogProps
        propName="titleComponent?"
        description="A component replacing the title of the component."
        type="React.ReactNode"
      />
      <CatalogProps
        propName="style?"
        description="The style of the component."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="rowStyle?"
        description="The style of the title's row."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="collapsibleStyle?"
        description="The style of the collapsible section."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="defaultCollapsedValue?"
        description="The default state of the collapsed section. (default to true)"
        type="boolean"
      />
    </ScrollView>
  );
};

export default ExpandableScreen;
