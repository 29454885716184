import React, { forwardRef, useImperativeHandle } from "react";
import { View } from "react-native";
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import useTheme from "theme/ThemeProvider";
import fnStyles from "./TopSheetStyles";

type Props = {
  children: React.ReactNode[] | React.ReactNode;
  onClose: () => void;
  onOpen: () => void;
};

export type TopSheetProps = {
  show: () => void;
  hide: () => void;
};

/**
 * Scrolls to a specific destination
 * @param {number} destination - The destination to scroll to
 */
const scrollTo = (tValue: any, destination: any) => {
  "worklet";
  tValue.value = withSpring(destination, {
    damping: destination === 0 ? 12 : 20,
  });
};

export const TopSheet = forwardRef<TopSheetProps, Props>(
  ({ children, onClose, onOpen }, ref) => {
    const { theme } = useTheme();
    const styles = fnStyles(theme);
    const MAX_TRANSLATE_Y = 69;
    const MIN_TRANSLATE_Y = 10;
    const translateY = useSharedValue(-MAX_TRANSLATE_Y);
    const context = useSharedValue({ y: 0 });

    useImperativeHandle(
      ref,
      () => {
        return {
          show: () => {
            scrollTo(translateY, 0);
          },
          hide: () => {
            scrollTo(translateY, -MAX_TRANSLATE_Y);
          },
        };
      },
      [MAX_TRANSLATE_Y, translateY]
    );

    const gesture = Gesture.Pan()
      .onStart(() => {
        context.value = { y: translateY.value };
      })
      .onUpdate((e) => {
        if (e.translationY > 0) {
          // Empêcher la translation vers le bas
          translateY.value = e.translationY + context.value.y;
          translateY.value = Math.min(translateY.value, MIN_TRANSLATE_Y);
        } else {
          translateY.value = e.translationY + context.value.y;
          translateY.value = Math.max(translateY.value, -MAX_TRANSLATE_Y);
        }
      })
      .onEnd(() => {
        if (translateY.value - context.value.y > 0) {
          translateY.value = withSpring(0);
          runOnJS(onOpen)();
        } else {
          if (translateY.value - context.value.y < -50) {
            translateY.value = withSpring(-MAX_TRANSLATE_Y, { damping: 15 });
            runOnJS(onClose)();
          } else {
            translateY.value = withSpring(0);
            runOnJS(onOpen)();
          }
        }
      });

    /**
     * Animated style for the bottom sheet
     */
    const reanimatedBottomStyle = useAnimatedStyle(() => {
      return {
        transform: [{ translateY: translateY.value }],
      };
    });

    return (
      <GestureDetector gesture={gesture}>
        <Animated.View
          style={[styles.bottomsheet_container, reanimatedBottomStyle]}
        >
          {children}
          <View style={styles.line} />
        </Animated.View>
      </GestureDetector>
    );
  }
);
