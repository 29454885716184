import React from "react";
import {
  Keyboard,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";
import { FlexAlign, FlexJustifyContent } from "../../../types";
import { SafeAreaView } from "react-native-safe-area-context";
import fnStyles from "./RowStyle";

type Props = {
  style?: StyleProp<ViewStyle>;
  justify?: FlexJustifyContent;
  align?: FlexAlign;
  safe?: boolean;
  keyboardDismiss?: boolean;
  children: React.ReactNode[] | React.ReactNode;
};

export const Row: React.FC<Props> = ({
  style = {},
  children,
  justify,
  safe = false,
  keyboardDismiss = false,
  align,
}: Props) => {
  const styles = fnStyles();
  const styleToApply = StyleSheet.flatten(style) ?? {};
  if (justify) {
    styleToApply.justifyContent = justify;
  }
  if (align === "top") {
    styleToApply.alignItems = "flex-start";
  } else if (align === "bottom") {
    styleToApply.alignItems = "flex-end";
  } else if (align === "center") {
    styleToApply.alignItems = "center";
  }

  const content = safe ? (
    <SafeAreaView style={[styles.container, styleToApply]}>
      {children}
    </SafeAreaView>
  ) : (
    <View style={[styles.container, styleToApply]}>{children}</View>
  );

  return keyboardDismiss ? (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      {content}
    </TouchableWithoutFeedback>
  ) : (
    content
  );
};
