import React, { createContext, useContext, useMemo } from "react";
import defaultConfiguration from "./default.configuration";
import specificConfiguration from "./specific.configuration";

export type Configuration = {
  logo: {
    width: number;
  };
  sites: {
    wording: {
      Sg_main: string;
      Pl_main: string;
    };
    display: {
      organization: boolean;
      searchBar: boolean;
      typesSeparator: boolean;
    };
    features: {
      locationMandatory: boolean;
      suggestionForm: boolean;
    };
    map: {
      defaultCenter: "user" | "points" | "default";
      defaultCenterPoint: {
        lat: number;
        lng: number;
      } | null;
      defaultZoomLevel: number;
      defaultMobileZoomLevel: number;
    };
  };
  profile: {
    email: {
      share: boolean;
    };
    phone: {
      share: boolean;
      sms: boolean;
    };
    features: {
      loginMandatory: boolean;
      hasUserType: boolean;
      enableAboveFifteen: boolean;
    };
  };
  threads: {
    features: {
      enableThreadWithoutSite: boolean;
      enableThreadWithLocation: boolean;
    };
  };
};
const getConfiguration = () => ({
  ...defaultConfiguration,
  ...specificConfiguration,
});

export const ConfigurationContext = createContext({
  configuration: getConfiguration(),
});

type Props = {
  children: React.ReactNode;
};

export const ConfigurationProvider: React.FC<Props> = ({ children }: Props) => {
  const context = useMemo(
    () => ({
      configuration: getConfiguration(),
    }),
    []
  );

  return (
    <ConfigurationContext.Provider value={context}>
      {children}
    </ConfigurationContext.Provider>
  );
};

const useConfiguration = () => useContext(ConfigurationContext);

export default useConfiguration;
