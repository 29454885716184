/* eslint-disable react-native/no-inline-styles */
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Row, Tile } from "../../components";
import { CatalogHeader, CatalogProps } from "./common";
import styles from "./common/CatalogStyle";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 450,
    marginTop: 16,
  },
});

const TileScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Tile" description="This component renders a tile.">
        <View>
          <Row>
            <Tile label={"Simple Tile"} onPress={() => {}} />
            <Tile
              label={"Selectable Tile"}
              onPress={() => {}}
              style={{ marginLeft: 16 }}
              selectable
              color="info"
            />
          </Row>
          <Row style={{ marginTop: 16 }}>
            <Tile label={"Tile with icon"} onPress={() => {}} iconName="euro" />
            <Tile
              label={"Selectable Tile"}
              onPress={() => {}}
              style={{ marginLeft: 16 }}
              iconName="euro"
              selectable
              color="info"
            />
          </Row>
        </View>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<View>\n" +
            "  <Row>\n" +
            '    <Tile label={"Simple Tile"} onPress={() => {}} />\n' +
            "    <Tile\n" +
            '      label={"Selectable Tile"}\n' +
            "      onPress={() => {}}\n" +
            "      style={{ marginLeft: 16 }}\n" +
            "      selectable\n" +
            '      color="info"\n' +
            "    />\n" +
            "  </Row>\n" +
            "  <Row style={{ marginTop: 16 }}>\n" +
            '    <Tile label={"Tile with icon"} onPress={() => {}} iconName="euro" />\n' +
            "    <Tile\n" +
            '      label={"Selectable Tile"}\n' +
            "      onPress={() => {}}\n" +
            "      style={{ marginLeft: 16 }}\n" +
            '      iconName="euro"\n' +
            "      selectable\n" +
            '      color="info"\n' +
            "    />\n" +
            "  </Row>\n" +
            "</View>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="label?"
        description="The label of the tile."
        type="string"
        topDivider={false}
      />
      <CatalogProps
        propName="labelKey?"
        description="The i18n key for the label of the tile."
        type="I18nKey"
      />
      <CatalogProps
        propName="onPress"
        description="The callback called when you press the component."
        type="I18nKey"
      />
      <CatalogProps
        propName="iconType?"
        description="The type of the icon."
        type="MaterialCommunity | AntDesign | Entypo | EvilIcons | Feather | FontAwesome | Fontisto | Foundation | Material | MaterialIcons | IonIcons"
      />
      <CatalogProps
        propName="iconName?"
        description="The name of the icon. (Only when type is action)"
        type="string"
      />
      <CatalogProps
        propName="color?"
        description="The color of the icon and the background. (default to primary)"
        type="ColorType"
      />
      <CatalogProps
        propName="style?"
        description="The style of the button."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="selectable?"
        description="Whether the tile is selectable or not."
        type="boolean"
      />
    </ScrollView>
  );
};

export default TileScreen;
