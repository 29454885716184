import React, { useState } from "react";
import { StrapiFile } from "../../../types/StrapiFile";
import useTheme from "../../../theme/ThemeProvider";
import { Clickable, Icon, JImage, JText, Row } from "../../jlib";
import { View } from "react-native";
import fnStyles from "./DocumentItemStyle";
import { DocumentModal } from "components/modals/documentModal";
import {
  downloadFile,
  getDocumentType,
  isDocumentImage,
} from "../../../utils/fileUtilities";

type Props = {
  document: StrapiFile;
  subject?: string;
};

export const DocumentItem: React.FC<Props> = ({ document, subject }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const [modalVisible, setModalVisible] = useState(false);

  const openFile = async (doc: any) => {
    if (getDocumentType(doc.ext) === "Image") {
      setModalVisible(true);
    } else {
      downloadFile(doc);
    }
  };

  /*const downloadFile = () => {
    const url = `${REACT_APP_API_ROOT}/${document.url}`;
    let fileUri = FileSystem.documentDirectory + document.name;
    FileSystem.downloadAsync(url, fileUri)
      .then(({ uri }) => {
        saveFile(uri);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const saveFile = async (fileUri: string) => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status === "granted") {
      const asset = await MediaLibrary.createAssetAsync(fileUri);
      await MediaLibrary.createAlbumAsync("Download", asset, false);
    }
  };*/

  return (
    <>
      <Row key={document.id} align={"center"} style={styles.container}>
        <Clickable onPress={() => openFile(document)} style={styles.clickable}>
          {isDocumentImage(document.ext) ? (
            <JImage
              source={document.url}
              style={styles.imageInChat}
              resizeMode={"contain"}
              alt={"image"}
            />
          ) : (
            <View style={styles.icon}>
              <Icon
                name={"document-sharp"}
                type={"IonIcons"}
                colorName={"info"}
                sizeName={"larger"}
                style={styles.iconSpacing}
              />
            </View>
          )}

          <View style={styles.main}>
            <JText
              label={
                document.caption ||
                document.name.substring(0, document.name.lastIndexOf("."))
              }
              isBold
              sizeName={"mediumLarge"}
            />
            <Row align={"center"}>
              <JText
                label={getDocumentType(document.ext)}
                sizeName={"mediumLarge"}
                style={styles.textSpacing}
              />
            </Row>
          </View>
        </Clickable>
        <Clickable onPress={() => downloadFile(document)}>
          <Icon name={"download"} type={"Feather"} colorName={"infoMedium"} />
        </Clickable>
      </Row>

      <DocumentModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        imageUrl={document.url}
        onDownload={() => downloadFile(document)}
        subject={subject}
      />
    </>
  );
};
