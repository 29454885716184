import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { usePosts } from "hooks";
import useSites from "hooks/useSites";
import React, { useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import {
  BackgroundLayout,
  JText,
  ReactHeaderBanner,
  Scrollable,
  Spacer,
  ThreadTypeItem,
} from "../../components";
import useThread from "../../hooks/useThread";
import useTheme from "../../theme/ThemeProvider";
import { ThreadType } from "../../types/ThreadType";
import fnStyles from "../request/ReactScreenStyle";
import { ChatParamsList } from "navigation";
import useConfiguration from "../../configuration/ConfigurationProvider";

type Props = StackScreenProps<ChatParamsList, "chatSelectType">;

export const ChatSelectType: React.FC<Props> = ({ route, navigation }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const { site, fetchSite } = useSites();
  const { post, fetchPost } = usePosts();

  const { configuration } = useConfiguration();

  useEffect(() => {
    if (route?.params?.siteId && (!site || site?.id !== route.params?.siteId)) {
      fetchSite(route.params?.siteId);
    }
  }, [route.params?.siteId, site, fetchSite]);

  useEffect(() => {
    if (route.params?.postId && (!post || post.id !== route.params?.postId)) {
      fetchPost(route.params?.postId);
    }
  }, [route.params?.postId, post, fetchPost]);

  const onTypePressed = (type: ThreadType) => {
    if (configuration && configuration?.profile.features?.hasUserType)
      navigation.navigate("chatSelectUserType", {
        siteId: route.params?.siteId,
        postId: route.params?.postId,
        lat: route.params?.lat,
        lng: route.params?.lng,
        address: route.params?.address,
        threadTypeId: type.id,
        threadType: type,
      });
    else
      navigation.navigate("reactMessage", {
        siteId: route.params?.siteId,
        postId: route.params?.postId,
        lat: route.params?.lat,
        lng: route.params?.lng,
        threadTypeId: type.id,
        threadType: type,
      });
  };

  const { getThreadTypes } = useThread();

  const types = getThreadTypes(site ? site : undefined)?.sort((a, b) => {
    return Number(a.rank ?? 0) - Number(b.rank ?? 0);
  });
  // "site ? site : undefined" escape "null" type

  return (
    <>
      <BackgroundLayout
        style={{
          marginTop: theme.normalize(-30),
          paddingTop: theme.normalize(30),
        }}
      >
        <Scrollable>
          <BoxedLayout style={{ minHeight: "100%" }}>
            <Spacer height={"small"} backgroundColor={"transparent"} />

            {site || types ? (
              <>
                {site && <ReactHeaderBanner site={site} post={post} />}
                <View>
                  <Spacer
                    height={"mediumLarge"}
                    backgroundColor={"transparent"}
                  />
                  <JText
                    labelKey={"chat.reason"}
                    isBold
                    centered
                    titleLevel={2}
                  />
                  <Spacer height={"large"} backgroundColor={"transparent"} />
                  <View style={styles.react} role="list">
                    {types?.map((t, index) => {
                      return (
                        <ThreadTypeItem
                          type={t}
                          onPressed={onTypePressed}
                          key={index}
                        />
                      );
                    })}
                  </View>
                </View>
              </>
            ) : (
              <>
                <Spacer height={"huge"} backgroundColor={"transparent"} />
                <ActivityIndicator color={theme.colors.greyDark} size="large" />
              </>
            )}
          </BoxedLayout>
        </Scrollable>
      </BackgroundLayout>
    </>
  );
};
