import { StyleSheet } from "react-native";
import { Theme } from "../../theme";
import hexToRgba from "hex-to-rgba";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: hexToRgba(theme.colors.white, 0.3),
      paddingHorizontal: theme.normalize(theme.sizings.smallMedium),
      paddingVertical: theme.normalize(theme.sizings.smallMedium),
      alignItems: "center",
    },
    siteImage: {
      width: theme.normalize(55),
      height: theme.normalize(50),
      borderRadius: theme.normalize(20),
      marginRight: theme.normalize(10),
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    iconImage: {
      width: theme.normalize(20),
      height: theme.normalize(20),
    },
    icon: {
      marginRight: theme.normalize(theme.sizings.smallMedium),
    },
    typeIcon: {
      width: theme.normalize(30),
      height: theme.normalize(30),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.normalize(15),
    },
    column: {
      flex: 1,
    },
  });
