import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { JText } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 40,
    marginTop: 16,
  },
});

const TextScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Text" description="Renders a text.">
        <JText label={"A text"} />
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={'<JText label={"A text"} />'}
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="label?"
        description="The label of the text."
        type="string"
      />
      <CatalogProps
        propName="labelKey?"
        description="The I18n key of the label of the text."
        type="I18nKey"
      />
      <CatalogProps
        propName="valuesKey?"
        description="The values key of the I18n label of the text."
        type="object"
      />
      <CatalogProps
        propName="colorName?"
        description="The name of the color of the text. It has to be one of the color from the Theme."
        type="ColorType"
      />
      <CatalogProps
        propName="fontWeight?"
        description="The font weight of the text."
        type='Types["fontWeights]'
      />
      <CatalogProps
        propName="sizeName?"
        description="The fontsize of the text."
        type="FontSizeType"
      />
      <CatalogProps
        propName="isUppercase?"
        description="Whether the text is in uppercase or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="isBold?"
        description="Whether the text is bold or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="isItalic?"
        description="Whether the text is italic or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="centered?"
        description="Whether the text is centered or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="right?"
        description="Whether the text is placed to the right or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="isTitleText?"
        description="Whether the text is a title or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="isButtonText?"
        description="Whether the text is from a button or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="numberOfLines?"
        description="The number of lines of the text. (default to 0)"
        type="number"
      />
      <CatalogProps
        propName="textInputStyle?"
        description="The style of the text input."
        type="StyleProp<TextStyle>"
      />
    </ScrollView>
  );
};

export default TextScreen;
