import React, { useState } from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, View } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { SwitchWithIcon } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 225,
    marginTop: 16,
  },
});

const SwitchScreen = () => {
  const [check, setCheck] = useState(false);

  const onCheckBoxPressed = () => {
    setCheck(!check);
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="SwitchWithIcon"
        description="Renders a switch button."
      >
        <View>
          <SwitchWithIcon value={check} onToggle={onCheckBoxPressed} />
        </View>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<View>\n" +
            "  <SwitchWithIcon value={check} onToggle={onCheckBoxPressed} />\n" +
            "  <SwitchWithIcon\n" +
            "    value={check}\n" +
            "    onToggle={onCheckBoxPressed}\n" +
            '    label={"Same as above but with a label"}\n' +
            "  />\n" +
            "</View>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="value"
        description="The value of the component."
        type="boolean"
        topDivider={false}
      />
      <CatalogProps
        propName="onToggle"
        description="The callback called when you press the component."
        type="() => void"
      />
      <CatalogProps
        propName="onColor?"
        description="The color when the switch is on. (default to info)"
        type="string"
      />
      <CatalogProps
        propName="offColor?"
        description="The color when the switch is off. (default to greyLight)"
        type="string"
      />
      <CatalogProps
        propName="label?"
        description="A label set next to the switch. (default to null)"
        type="string"
      />
      <CatalogProps
        propName="labelKey?"
        description="A I18n key for a label set next to the switch. (default to null)"
        type="I18nKey"
      />
      <CatalogProps
        propName="labelStyle?"
        description="The style of the label."
        type="StyleProp<TextStyle>"
      />
      <CatalogProps
        propName="disabled?"
        description="Whether the switch is disabled or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="thumbOnStyle?"
        description="The style of thumb of the switch when it's on."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="thumbOffStyle?"
        description="The style of thumb of the switch when it's off."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="trackOnStyle?"
        description="The style of track of the switch when it's on."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="trackOffStyle?"
        description="The style of track of the switch when it's off."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="size?"
        description="The size of the switch. (default to medium)"
        type='"small" | "medium" | "large"'
      />
      <CatalogProps
        propName="animationSpeed?"
        description="The speed in ms of the transition between states of the switch. (default to 300)"
        type="number"
      />
      <CatalogProps
        propName="style?"
        description="The style of the component container."
        type="StyleProp<ViewStyle>"
      />
    </ScrollView>
  );
};

export default SwitchScreen;
