import React from "react";
import useTheme from "../../../theme/ThemeProvider";
import { JText } from "../../jlib";

type Props = {
  date: Date;
  type: "classic" | "large";
};

export const HourItem: React.FC<Props> = ({ date, type = "classic" }) => {
  const { theme } = useTheme();

  const isLarge = type === "large";
  return (
    <JText
      label={`${new Date(date).getHours()}:${
        new Date(date).getMinutes() < 10 ? "0" : ""
      }${new Date(date).getMinutes()}`}
      isBold
      sizeName={isLarge ? "mediumLarge" : "medium"}
      style={{
        lineHeight: isLarge
          ? theme.fontSizes.mediumLarge * 1.2
          : theme.fontSizes.medium * 1.2,
      }}
    />
  );
};
