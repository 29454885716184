import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import { Spacer } from "../../components";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 125,
    marginTop: 16,
  },
});

const SpacerScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader title="Spacer" description="Renders an empty space.">
        <View>
          <Text>Above spacer</Text>
          <Spacer height={"medium"} />
          <Text>Below spacer</Text>
        </View>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<View>\n" +
            "  <Text>Above spacer</Text>\n" +
            '  <Spacer height={"medium"} />\n' +
            "  <Text>Below spacer</Text>\n" +
            "</View>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="height"
        description="The height of the component. It has to be one of the size from the Theme."
        type="SizingsType"
        topDivider={false}
      />
      <CatalogProps
        propName="beackgroundColor?"
        description="The color of the component. It has to be one of the color from the Theme. (default to white)"
        type="ColorType"
      />
    </ScrollView>
  );
};

export default SpacerScreen;
