import { Configuration } from "./../configuration/ConfigurationProvider";
import { defaultVocabulary } from "./defaultVocabulary";

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const getWording = (configuration?: Configuration) => {
  return {
    plural:
      configuration?.sites.wording.Pl_main || defaultVocabulary.site.Pl_main,
    pluralCapitalize: capitalizeFirstLetter(
      configuration?.sites.wording.Pl_main || defaultVocabulary.site.Pl_main
    ),
    singular:
      configuration?.sites.wording.Sg_main || defaultVocabulary.site.Sg_main,
    singularCapitalize: capitalizeFirstLetter(
      configuration?.sites.wording.Sg_main || defaultVocabulary.site.Sg_main
    ),
  };
};

export default getWording;
