import React, { createContext, useCallback, useState } from "react";
import * as Location from "expo-location";
import usePersistedState from "components/utils/usePersistedState";
import { useTranslation } from "react-i18next";

type LocationProviderType = {
  userLocation: any;
  askCoordinate: () => Promise<boolean>;
  userLocationAllowed: any;
  isLocatedOnce: boolean;
  setIsLocatedOnce: (value: boolean | ((value: boolean) => void)) => void;
  locationData: {
    label: string;
    lat: number;
    lng: number;
  };
  setLocationData: (
    value:
      | {
          label: string;
          lat: number;
          lng: number;
        }
      | ((value: { label: string; lat: number; lng: number }) => void)
  ) => void;
};

export const LocationContext = createContext<LocationProviderType>(
  {} as LocationProviderType
);

type Props = {
  children: React.ReactNode;
};

export const LocationProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const [userLocation, setUserLocation] = useState<any>(null);
  const [isLocatedOnce, setIsLocatedOnce] = usePersistedState(
    false,
    "local--is-located-once"
  );
  const [locationData, setLocationData] = usePersistedState(
    { label: "", lat: 0, lng: 0 },
    "local--location-data"
  );

  const userLocationAllowed = useCallback(async () => {
    const r = await Location.getForegroundPermissionsAsync();

    return r.granted;
  }, []);

  const askCoordinate = useCallback(async () => {
    const watchLocation = async () => {
      if ((await userLocationAllowed()) && !userLocation) {
        let location = await Location.getCurrentPositionAsync({});
        setUserLocation({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
        setLocationData({
          label: t("labels.yourCurrentLocation"),
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
        setIsLocatedOnce(true);

        return true;
      } else {
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status === "granted") {
          let location = await Location.getCurrentPositionAsync({});
          setUserLocation({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          });
          setLocationData({
            label: "Votre position",
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          });
          setIsLocatedOnce(true);
          return true;
        }
        return false;
      }
    };

    return await watchLocation();
  }, [setIsLocatedOnce, setLocationData, t, userLocation, userLocationAllowed]);

  return (
    <LocationContext.Provider
      value={{
        userLocation,
        userLocationAllowed,
        askCoordinate,
        isLocatedOnce,
        setIsLocatedOnce,
        locationData,
        setLocationData,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
