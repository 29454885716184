import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isHorizontalMode: boolean) =>
  StyleSheet.create({
    container: {
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.colors.transparent,
      position: "relative",
      flexDirection: isHorizontalMode ? "row" : "column",
      gap: isHorizontalMode
        ? theme.normalize(theme.sizings.default)
        : undefined,
    },
    logo: {
      width: theme.normalize(theme.sizings.large),
      height: theme.normalize(theme.sizings.large),
    },
  });
