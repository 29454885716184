import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.sizings.huge,
      paddingTop: theme.sizings.huge,
    },
    text: {
      marginBottom: theme.normalize(theme.sizings.smallMedium),
    },
  });
