import { ThemeConfigOverride } from "./ThemeTypes";

const specificProperties: ThemeConfigOverride = {
  colors: {
    tabActiveText: "#FFFFFF",
    tabActiveBackground: "#C55336", //"#DB5D3D",
    gradientStart: "#5B73A9", //"#91A5C9",
    gradientEnd: "#013473",
    gradientText: "#FFFFFF",
    headerText: "#ffffff",
    headerBackground: "#013473",
    emphasisText: "#013473",
    rootMenuText: "#013473",
    tinyElementsText: "#FFFFFF",
    tinyElementsBackground: "#1C8730", //"#28BB45",
  },
  images: {
    logo: require("assets/parlonstravaux/logo.png"),
    background: require("assets/parlonstravaux/background.jpg"),
    introStep1: require("assets/parlonstravaux/introStep1.png"),
    introStep2: require("assets/parlonstravaux/introStep2.png"),
    introStep3: require("assets/parlonstravaux/introStep3.png"),
    pictoBubble: require("assets/parlonstravaux/picto-bubbles.png"),
    pictoClipboard: require("assets/parlonstravaux/picto-clipboard.png"),
    pictoSearch: require("assets/parlonstravaux/picto-search.png"),
  },
  texts: {
    appName: "ParlonsTravaux",
    icons: {
      introStep1Icon: "handshake-o",
      introStep2Icon: "user-circle-o",
      introStep3Icon: "comments-o",
      introStep1IconType: "FontAwesome",
      introStep2IconType: "FontAwesome",
      introStep3IconType: "FontAwesome",
    },
    fr: {
      introStep1Title:
        "ParlonsTravaux est un service proposé par Toulouse Métropole et Tisseo afin de dialoguer avec vous dans le cadre de leurs activités, qu'il s'agisse d'un projet d'aménagement, d'un chantier, d'un site d'exploitation.",
      introStep2Title:
        "Choisissez la zone d'informations souhaitée et recevez toutes les informations qui vous concernent sur les chantiers, les projets autour de vous.",
      introStep3Title:
        "Dialoguez en direct avec les responsables des chantiers et faites part de vos questions, de vos observations.",
      paramAlertTitle: "Perturbations",
      paramEventTitle: "Événements",
      paramInfoTitle: "Informations",
      paramAlertLegend: "Communication importante",
      paramEventLegend: "Événements de l'entreprise",
      paramInfoLegend: "Informations diverses",
    },
  },
};

export default specificProperties;
