import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    inLineField: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    textInput: {
      borderWidth: 1,
      borderColor: theme.colors.greyLight,
      borderRadius: 5,
      flex: 1,
      elevation: 0,
      color: theme.colors.dark,
      fontSize: theme.normalizeFont(theme.fontSizes.mediumLarge),
    },
    inLineTextInput: {
      width: theme.normalize(theme.sizings.high * 3),
    },
    textInputContainer: {
      backgroundColor: theme.colors.blurBackground,
    },
    field: {
      paddingVertical: theme.normalize(theme.sizings.smallMedium),
    },
    fieldTitle: {
      paddingBottom: theme.normalize(theme.sizings.medium),
    },
    fieldError: {
      paddingTop: theme.normalize(theme.sizings.small),
      color: theme.colors.danger,
      fontSize: theme.normalizeFont(theme.fontSizes.mediumLarge),
    },
  });
