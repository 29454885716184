import { SubscribeContent } from "../../../components/modals/contents/SubscribeContent";
import { isHorizontalMode } from "../../../components/utils/deviceMode";
import React, { useCallback, useMemo, useState } from "react";
import { Linking, Platform, View, useWindowDimensions } from "react-native";
import { JText, ParamsItem } from "../../../components";
import { GenericModal } from "../../../components/modals/GenericModal";
import useUser from "../../../hooks/useUser";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "../ParamsScreenStyle";
import * as Notifications from "expo-notifications";
import { useTranslation } from "react-i18next";
import { PostType } from "types/Post";
import { useList } from "hooks";

const AccountNotificationsBlock = ({ navigation }: any) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const {
    user,
    notificationSettings,
    updateNotificationSettings,
    enableSmsNotifications,
  } = useUser();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));
  const { t } = useTranslation();

  const { items: postTypes, isFetching: isFetchingPostTypes } =
    useList<PostType>("post-types");

  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const onNotificationSettingsUpdate = useCallback(
    async (postTypeId: string) => {
      if (Platform.OS !== "web") {
        const settings = await Notifications.getPermissionsAsync();
        if (!settings.granted) {
          Linking.openSettings();
          return;
        }
      }

      updateNotificationSettings(
        {
          ...notificationSettings,
          [postTypeId]: !notificationSettings?.[postTypeId] ?? true,
        },
        postTypeId
      );
    },
    [notificationSettings, updateNotificationSettings]
  );

  const onSubscribeConfirm = () => {
    navigation.navigate("signUp");
    setIsNotificationsModalOpen(false);
  };

  const isWeb = Platform.OS === "web";
  const cantBeNotified = !user && isWeb;

  const handleNotificationSwitchClick = useCallback(
    (id: string) => async () => {
      if (cantBeNotified) {
        setIsNotificationsModalOpen(true);
      } else {
        await onNotificationSettingsUpdate(id);
      }
    },
    [cantBeNotified, onNotificationSettingsUpdate]
  );

  const smsNotifications = useMemo(
    () =>
      enableSmsNotifications
        ? [
            {
              id: "sms",
              name: t("auth.paramSms"),
              description: t("auth.paramSmsLegende"),
              onPress: handleNotificationSwitchClick("sms"),
              value: notificationSettings?.sms || false,
            },
          ]
        : [],
    [
      enableSmsNotifications,
      handleNotificationSwitchClick,
      notificationSettings,
      t,
    ]
  );

  const notificationsList = useMemo(
    () =>
      !isFetchingPostTypes
        ? postTypes?.map((type: any) => ({
            id: type.id,
            name: type.name,
            description: type.subtitle,
            onPress: handleNotificationSwitchClick(type.id),
            value: notificationSettings?.[type.id] || false,
          }))
        : [],
    [
      handleNotificationSwitchClick,
      isFetchingPostTypes,
      notificationSettings,
      postTypes,
    ]
  );
  [];

  const notificationList = [
    ...(notificationsList || []),
    ...(smsNotifications || []),
  ];

  return (
    <View role="list">
      <JText
        labelKey="auth.notifications"
        isBold
        centered
        style={styles.notificationSectionLabel}
        titleLevel={2}
      />
      {notificationList?.map((paramsItem: any, key: number) => {
        return (
          <View key={`params-item-${key}`} role="listitem">
            <ParamsItem
              id={paramsItem.id}
              name={paramsItem.name}
              description={paramsItem.description}
              onPress={paramsItem.onPress}
              value={paramsItem.value}
              displayDivider={
                notificationList && notificationList.length !== key + 1
              }
            />
          </View>
        );
      })}

      <GenericModal
        modalVisible={isNotificationsModalOpen}
        setModalVisible={setIsNotificationsModalOpen}
        ContentComponent={SubscribeContent}
        confirmLabelKey={"modal.subscribe"}
        cancelLabelKey={"modal.skipSubscribeLabel"}
        handleCancel={() => setIsNotificationsModalOpen(false)}
        handleConfirm={() => onSubscribeConfirm()}
      />
    </View>
  );
};

export default AccountNotificationsBlock;
