import React, { useMemo } from "react";
import { View } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType, SizingsType } from "theme/ThemeTypes";

type Props = {
  height?: SizingsType;
  width?: SizingsType;
  backgroundColor?: ColorType;
};

export const Spacer: React.FC<Props> = ({
  height,
  width,
  backgroundColor = "transparent",
}) => {
  const { theme } = useTheme();

  const spacerStyle = useMemo(
    () => ({
      height: height ? theme.normalize(theme.sizings[height]) : 1,
      width: width ? theme.normalize(theme.sizings[width]) : 1,
      backgroundColor: theme.colors[backgroundColor],
    }),
    [backgroundColor, height, theme, width]
  );
  return <View style={spacerStyle} />;
};
