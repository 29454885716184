import React from "react";
import styles from "./common/CatalogStyle";
import { ScrollView, StyleSheet, Text } from "react-native";
import { CatalogHeader, CatalogProps } from "./common";
import { Clickable } from "../../components";
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 100,
    marginTop: 16,
  },
});

const ClickableScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Clickable"
        description="This component allows to make children of it clickable."
      >
        <Clickable onPress={() => {}}>
          <Text style={innerStyles.exampleText}>A Clickable Component</Text>
        </Clickable>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<Clickable onPress={() => {}}>\n" +
            "  <Text>A Clickable Component</Text>\n" +
            "</Clickable>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="onPress"
        description="The callback called when you press the component."
        type="() => void"
        topDivider={false}
      />
      <CatalogProps
        propName="hitBox?"
        description="The hit box of the component. (default to 0)"
        type="number"
      />
      <CatalogProps
        propName="hitBoxLeft?"
        description="The left hit box of the component. (default to 0)"
        type="number"
      />
      <CatalogProps
        propName="hitBoxRight?"
        description="The right hit box of the component. (default to 0)"
        type="number"
      />
      <CatalogProps
        propName="hitBoxTop?"
        description="The top hit box of the component. (default to 0)"
        type="number"
      />
      <CatalogProps
        propName="hitBoxBottom?"
        description="The bottom hit box of the component. (default to 0)"
        type="number"
      />
      <CatalogProps
        propName="disabled?"
        description="Whether the component is disabled or not. (default to false)"
        type="boolean"
      />
      <CatalogProps
        propName="style?"
        description="The style of the component."
        type="StyleProp<ViewStyle>"
      />
    </ScrollView>
  );
};

export default ClickableScreen;
