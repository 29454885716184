import React, { ComponentType } from "react";
import { FlatList, ListRenderItem, ViewStyle } from "react-native";

type SimpleListProps<T> = {
  data: T[];
  renderItem: ListRenderItem<T>;
  keyExtractor: (item: T) => string;
  ItemSeparatorComponent?: ComponentType<any> | null | undefined;
  contentContainerStyle?: ViewStyle | ViewStyle[];
};

export const SimpleList = <T extends {}>({
  data,
  renderItem,
  keyExtractor,
  ItemSeparatorComponent,
  contentContainerStyle,
}: SimpleListProps<T>) => {
  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={ItemSeparatorComponent}
      contentContainerStyle={contentContainerStyle}
      role="list"
    />
  );
};
