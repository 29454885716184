import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      maxHeight: "100%",
      paddingTop: 190,
    },
  });
