import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React from "react";
import { View } from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { Site } from "../../types/Site";
import { Thread } from "../../types/ThreadType";
import { Card, Divider } from "../jlib";
import { ThreadItemContent } from "./ThreadItemContent";
import { ThreadItemHeader } from "./ThreadItemHeader";
import fnStyles from "./ThreadItemStyle";

type Props = {
  //item: Thread | Site;
  item: any;
  onPress: ((thread: Thread) => void) | ((site: Site) => void);
  isSite?: boolean;
};

export const ThreadItem: React.FC<Props> = ({
  item,
  onPress,
  isSite = false,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <BoxedLayout>
      <View style={styles.container} role="listitem">
        <Card style={styles.card} onPress={() => onPress(item)}>
          <View style={styles.content}>
            <ThreadItemHeader data={item} isSite={isSite} />

            {Boolean(!isSite) && (
              <>
                <View style={styles.dividerBox}>
                  <Divider margin={"smallMedium"} />
                </View>
                <ThreadItemContent data={item} isSite={isSite} />
              </>
            )}
          </View>
        </Card>
      </View>
    </BoxedLayout>
  );
};
