import React from "react";
import {
  View,
  Modal,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { Button, Clickable, JText, Spacer } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ModalStyle";
import { useTranslation } from "react-i18next";

type Props = {
  modalVisible: boolean;
  setModalVisible: any;
  handleCancel?: () => void;
  handleConfirm?: () => void;
  title?: string;
  titleKey?: string;
  message?: string;
  messageKey?: string;
  confirmLabel?: string;
  confirmLabelKey?: string;
  cancelLabel?: string;
  cancelLabelKey?: string;
  ContentComponent?: any;
};

export const GenericModal: React.FC<Props> = ({
  modalVisible,
  setModalVisible,
  title,
  titleKey,
  message,
  messageKey,
  confirmLabel,
  confirmLabelKey,
  cancelLabel,
  cancelLabelKey,
  handleCancel,
  handleConfirm,
  ContentComponent,
}) => {
  const { theme } = useTheme();
  const { height, width } = useWindowDimensions();
  const styles = fnStyles(theme, height, width);
  const { t } = useTranslation();

  return (
    <Modal
      visible={modalVisible}
      animationType="fade"
      hardwareAccelerated
      transparent
    >
      <TouchableOpacity
        onPress={() => setModalVisible(false)}
        style={styles.layerStyle}
        accessibilityRole="button"
        accessibilityLabel={t("labels.closeModal")}
      >
        <View style={styles.panelStyle}>
          {ContentComponent ? (
            <ContentComponent />
          ) : (
            <>
              <View style={styles.panelHeaderStyle}>
                <JText
                  label={title || t("modal.warn")}
                  labelKey={titleKey}
                  isBold
                  centered
                  titleLevel={2}
                />
              </View>
              <Spacer height="mediumLarge" />
              <JText label={message} labelKey={messageKey} centered />
              <Spacer height="mediumLarge" />
            </>
          )}
          <View style={styles.panelFooterStyle}>
            {handleConfirm && (
              <Button
                onPress={handleConfirm}
                label={confirmLabel}
                labelKey={confirmLabelKey}
                type={"gradient"}
              />
            )}
            <Spacer height="mediumLarge" />
            {handleCancel && (
              <Clickable onPress={handleCancel}>
                <JText
                  label={cancelLabel}
                  labelKey={cancelLabelKey}
                  colorName={"emphasisText"}
                  isBold
                  centered
                  sizeName="mediumLarge"
                />
              </Clickable>
            )}
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
