const formatValue = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map((v: any) => formatValue(v));
  }
  if (value && typeof value === "object") {
    let newValue = { ...value };

    if ("data" in newValue) {
      if (Array.isArray(newValue.data)) {
        if (newValue.data.length === 0) {
          newValue = [];
        } else if (
          typeof newValue.data[0] === "object" &&
          "attributes" in newValue.data[0]
        ) {
          const list = newValue.data.map((d: any) => {
            const obj = {} as { [key: string]: any };
            Object.keys(d.attributes).forEach((attrName) => {
              const attribute = d.attributes[attrName];
              if (Array.isArray(attribute)) {
                obj[attrName] = attribute.map((d2: any) => formatValue(d2));
              } else if (typeof attribute === "object") {
                const t = formatValue(attribute);
                obj[attrName] = t;
              } else {
                obj[attrName] = attribute;
              }
            });
            Object.keys(d).forEach((field) => {
              if (field !== "attributes") obj[field] = d[field];
            });
            return obj;
          });
          newValue = list;
        } else {
          newValue.data = formatValue(newValue.data);
        }
      } else if (newValue.data && typeof newValue.data === "object") {
        if ("attributes" in newValue.data) {
          Object.keys(newValue.data.attributes).forEach((attrName) => {
            const attribute = newValue.data.attributes[attrName];
            if (Array.isArray(attribute)) {
              newValue[attrName] = attribute.map((d) => formatValue(d));
            } else if (attribute && typeof attribute === "object") {
              newValue[attrName] = formatValue(attribute);
            } else {
              newValue[attrName] = attribute;
            }
          });
          delete newValue.data.attributes;
          Object.keys(newValue.data).forEach((d) => {
            newValue[d] = newValue.data[d];
          });
          delete newValue.data;
        } else {
          newValue.data = formatValue(newValue.data);
        }
      } else if (!newValue.data && Object.keys(newValue).length === 1) {
        newValue = null;
      }
    }
    return newValue;
  }
  return value;
};

const parseStrapiFormat = (result: string | object) => {
  const parsed = formatValue(result);
  return parsed;
};

export default parseStrapiFormat;
