import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, platform: string) =>
  StyleSheet.create({
    container: {
      height: theme.normalize(40),
      alignItems: "center",
    },
    textInputContainer:
      platform === "web"
        ? {
            width: "100%",
            backgroundColor: theme.colors.blurBackground,
            height: theme.normalize(40),
            outlineStyle: "none",
          }
        : {
            width: "100%",
            backgroundColor: theme.colors.blurBackground,
            height: theme.normalize(40),
          },
    textInput:
      platform === "web"
        ? {
            width: "100%",
            height: theme.normalize(40),
            backgroundColor: theme.colors.blurBackground,
            color: theme.colors.dark,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            outlineStyle: "none",
          }
        : {
            width: "100%",
            height: theme.normalize(40),
            backgroundColor: theme.colors.blurBackground,
            color: theme.colors.dark,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
    poweredContainer: {
      opacity: 0,
    },
    listView: {
      position: "absolute",
      top: theme.normalize(40),
      width: "100%",
    },
    cross: {
      alignSelf: "center",
      marginRight: theme.normalize(4),
    },
    fieldMap: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: theme.colors.blurBackground,
      borderRadius: theme.normalize(theme.radius.small),
      paddingLeft: theme.normalize(12),
      height: theme.normalize(40),
      zIndex: 2,
    },
    inputButton: {
      height: theme.normalize(40),
      width: theme.normalize(40),
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.greyLight,
      borderTopRightRadius: theme.normalize(theme.radius.small),
      borderBottomRightRadius: theme.normalize(theme.radius.small),
    },
    modalCenterContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    modalView: {
      width: "85%",
      margin: theme.normalize(20),
      backgroundColor: "white",
      borderRadius: theme.normalize(20),
      padding: theme.normalize(35),
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalRow: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: theme.normalize(20),
    },
  });
