/* eslint-disable react-native/no-inline-styles */
import CodeEditor, {
  CodeEditorSyntaxStyles,
} from "@rivascva/react-native-code-editor";
import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Button } from "../../components";
import { CatalogHeader, CatalogProps } from "./common";
import styles from "./common/CatalogStyle";

const innerStyles = StyleSheet.create({
  exampleText: {
    fontSize: 18,
  },
  codeEditor: {
    fontSize: 14,
    inputLineHeight: 20,
    highlighterLineHeight: 20,
    height: 400,
    marginTop: 16,
  },
});

const ButtonScreen = () => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <CatalogHeader
        title="Button"
        description="This component renders a button."
      >
        <View>
          <Button onPress={() => {}} label={"Press me"} />
          <Button
            onPress={() => {}}
            label={"Press me"}
            outlined
            style={{ marginTop: 16 }}
          />
          <Button
            onPress={() => {}}
            label={"Like"}
            type={"action"}
            iconType={"AntDesign"}
            iconName={"like1"}
            style={{ marginTop: 16, width: "40%" }}
          />
          <Button
            onPress={() => {}}
            label={"Press me"}
            style={{ marginTop: 16 }}
            type="gradient"
          />
          <Button
            onPress={() => {}}
            label={"Try to press me"}
            disabled
            style={{ marginTop: 16 }}
          />
        </View>
        <CodeEditor
          style={innerStyles.codeEditor}
          initialValue={
            "<View>\n" +
            '  <Button onPress={() => {}} label={"Press me"} />\n' +
            "  <Button\n" +
            "    onPress={() => {}}\n" +
            '    label={"Press me"}\n' +
            "    outlined\n" +
            "    style={{ marginTop: 16 }}\n" +
            "  />\n" +
            "  <Button\n" +
            "    onPress={() => {}}\n" +
            '    label={"Press me"}\n' +
            '    type="gradient"\n' +
            "    style={{ marginTop: 16 }}\n" +
            "  />\n" +
            "  <Button\n" +
            "    onPress={() => {}}\n" +
            '    label={"Try to press me"}\n' +
            "    disabled\n" +
            "    style={{ marginTop: 16 }}\n" +
            "  />\n" +
            "  <Button\n" +
            "    onPress={() => {}}\n" +
            '    label={"Like"}\n' +
            '    type={"action"}\n' +
            '    iconType={"AntDesign"}\n' +
            '    iconName={"like1"}\n' +
            '    style={{ width: "40%", marginTop: 16 }}\n' +
            "  />\n" +
            "</View>"
          }
          language="typescript"
          syntaxStyle={CodeEditorSyntaxStyles.atomOneDark}
          showLineNumbers
          readOnly
        />
      </CatalogHeader>
      <CatalogProps
        propName="label?"
        description="The label of the button."
        type="string"
        topDivider={false}
      />
      <CatalogProps
        propName="labelKey?"
        description="The i18n key for the label of the button."
        type="I18nKey"
      />
      <CatalogProps
        propName="onPress"
        description="The callback called when you press the component."
        type="I18nKey"
      />
      <CatalogProps
        propName="type?"
        description="The type of the button. (default to main)"
        type="main | action | gradient"
      />
      <CatalogProps
        propName="iconType?"
        description="The type of the icon. (Only when type is action)"
        type="MaterialCommunity | AntDesign | Entypo | EvilIcons | Feather | FontAwesome | Fontisto | Foundation | Material | MaterialIcons | IonIcons"
      />
      <CatalogProps
        propName="iconName?"
        description="The name of the icon. (Only when type is action)"
        type="string"
      />
      <CatalogProps
        propName="iconSizeName?"
        description="The size of the icon. (Only when type is action) (default to large)"
        type="FontSizeType"
      />
      <CatalogProps
        propName="colorName?"
        description="The color of the icon. (Only when type is action) (default to primary)"
        type="ColorType"
      />
      <CatalogProps
        propName="style?"
        description="The style of the button."
        type="StyleProp<ViewStyle>"
      />
      <CatalogProps
        propName="textStyle?"
        description="The style of the label of the button."
        type="StyleProp<TextStyle>"
      />
      <CatalogProps
        propName="disabled?"
        description="Whether the button is disabled or not."
        type="StyleProp<TextStyle>"
      />
      <CatalogProps
        propName="gradientColors?"
        description="The colors of the button background. (Only when type is gradient) (default to primary and secondary)"
        type="[ColorType]"
      />
      <CatalogProps
        propName="outlined?"
        description="Whether the button is outlined or not."
        type="boolean"
      />
    </ScrollView>
  );
};

export default ButtonScreen;
