import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (
  theme: Theme,
  width: number,
  isHorizontalMode: boolean,
  navVisible: boolean,
  low: boolean,
  averageLow: boolean
) =>
  StyleSheet.create({
    containerClassic: {
      backgroundColor: theme.colors.transparent,
      zIndex: 10,
      position: "absolute",
      left: isHorizontalMode
        ? "50%"
        : theme.normalize(theme.sizings.medium + theme.sizings.tiny),
      transform: isHorizontalMode
        ? [
            {
              translateX:
                -(width / 4 - theme.normalize(theme.layout.navLeftWidth) / 2) -
                theme.normalize(theme.sizings.medium),
            },
          ]
        : undefined,
      top: theme.normalize(
        theme.sizings.medium +
          (low && !isHorizontalMode
            ? 30
            : averageLow && !isHorizontalMode
            ? 10
            : 0)
      ),
      width: "100%",
      maxWidth: isHorizontalMode
        ? navVisible
          ? width / 2 - theme.layout.navLeftWidth
          : width / 2
        : width -
          theme.normalize(theme.sizings.medium + theme.sizings.tiny) * 2,
    },
    containerCustom: {
      zIndex: 10,
      position: "absolute",
      left: isHorizontalMode
        ? "50%"
        : theme.normalize(theme.sizings.medium + theme.sizings.tiny),
      transform: isHorizontalMode
        ? [
            {
              translateX:
                //-(width / 4 - theme.normalize(theme.layout.navLeftWidth) / 2) -
                -350 - theme.normalize(theme.sizings.medium),
            },
          ]
        : undefined,
      top: isHorizontalMode
        ? theme.normalize(
            theme.sizings.medium +
              (low && !isHorizontalMode
                ? 30
                : averageLow && !isHorizontalMode
                ? 10
                : 0)
          )
        : theme.sizings.medium,
      width: "100%",
      maxWidth: isHorizontalMode
        ? navVisible
          ? 700 //width / 2 - theme.layout.navLeftWidth
          : 700 //width / 2
        : width -
          theme.normalize(theme.sizings.medium + theme.sizings.tiny) * 2,
      backgroundColor: theme.colors.white,
      borderRadius: theme.radius.large,
      padding: theme.sizings.medium,
    },
    boxLayout: {
      justifyContent: "center",
      alignItems: "center",
    },
    main: {
      height: theme.normalize(35),
      width: isHorizontalMode ? undefined : "100%",
      //position: "absolute",
      top: theme.sizings.smallMedium * 0.7,
      borderRadius: theme.normalize(25),
      backgroundColor: theme.colors.white,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.15,
      shadowRadius: 3.0,
      elevation: 3,
      zIndex: 0,
      flexDirection: "row",
      alignItems: "center",
    },
    item: {
      flex: isHorizontalMode ? undefined : 1,
      paddingHorizontal: isHorizontalMode
        ? theme.sizings.mediumLarge
        : undefined,
      backgroundColor: theme.colors.white,
      height: theme.normalize(35),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.normalize(25),
    },
    focusedItem: {
      height: theme.normalize(50),
      backgroundColor: theme.colors.tabActiveBackground,
      borderColor: theme.colors.white,
      borderWidth: theme.normalize(3),
    },
    backButton: {
      gap: theme.sizings.smallMedium,
      flexDirection: "row",
      opacity: 0.7,
    },
  });
