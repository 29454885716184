import { Platform, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    bottomsheet_container: {
      width: "100%",
      backgroundColor: theme.colors.headerBackground,
      position: "absolute",
      borderBottomLeftRadius: theme.normalize(theme.radius.mediumLarge),
      borderBottomRightRadius: theme.normalize(theme.radius.mediumLarge),
      paddingHorizontal: 10,
      paddingTop: Platform.OS === "web" ? 50 : 280,
      justifyContent: "flex-end",
      marginTop: Platform.OS === "web" ? -50 : -245,
    },
    line: {
      width: 75,
      height: 4,
      backgroundColor: "white",
      borderRadius: 20,
      alignSelf: "center",
      marginVertical: 10,
    },
  });
