import { Platform, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "row",
      marginTop: Platform.OS === "web" ? 0 : theme.normalize(-30),
    },
    download: {
      //paddingTop: theme.normalize(75),
      alignItems: "center",
    },
    downloadContent: {
      flexDirection: "row",
      alignItems: "center",
      position: "absolute",
      top: theme.normalize(10),
    },
    downloadText: {
      fontSize: theme.normalizeFont(theme.fontSizes.mediumLarge),
      marginLeft: theme.sizings.medium,
    },
    gradientContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: theme.normalize(50),
      width: "100%",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 2.22,
      elevation: 3,
      paddingHorizontal: theme.normalize(theme.sizings.mediumLarge),
    },
  });
