import {
  Keyboard,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from "react-native";
import { FlexAlign, FlexAlignItems } from "../../../types";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";

type Props = {
  style?: StyleProp<ViewStyle>;
  justify?: FlexAlignItems;
  align?: FlexAlign;
  safe?: boolean;
  keyboardDismiss?: boolean;
  children: React.ReactNode[] | React.ReactNode;
};

export const Column: React.FC<Props> = ({
  style = {},
  children,
  justify,
  safe = false,
  keyboardDismiss = false,
  align,
}: Props) => {
  const styleToApply = StyleSheet.flatten(style) ?? {};
  if (justify) {
    styleToApply.alignItems = justify;
  }
  if (align === "left") {
    styleToApply.justifyContent = "flex-start";
  } else if (align === "right") {
    styleToApply.justifyContent = "flex-end";
  } else if (align === "center") {
    styleToApply.justifyContent = "center";
  }

  const content = safe ? (
    <SafeAreaView
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          flexDirection: "column",
          maxWidth: styleToApply.width || "auto",
        },
        styleToApply,
      ]}
    >
      {children}
    </SafeAreaView>
  ) : (
    <View
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          flexDirection: "column",
          maxWidth: styleToApply.width || "auto",
        },
        styleToApply,
      ]}
    >
      {children}
    </View>
  );

  return keyboardDismiss ? (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      {content}
    </TouchableWithoutFeedback>
  ) : (
    content
  );
};
